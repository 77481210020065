import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { ColumnDef } from "@tanstack/react-table";
import { Actions } from "./actions";
import type { Charger } from "./schema";

export const columns: ColumnDef<Charger>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charge Point Name" />
    ),
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "charge_point_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charge Point ID" />
    ),
    cell: ({ row }) => <div>{row.getValue("charge_point_id")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "charger_model",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charger Model" />
    ),
    cell: ({ row }) => <div>{row.getValue("charger_model")}</div>,
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "charger_status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charger Status" />
    ),
    cell: ({ row }) => <div>{row.getValue("charger_status")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "charger_group",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charger Group ID" />
    ),
    cell: ({ row }) => (
      <div className="uppercase">
        {
          (row.getValue("charger_group") as { charger_group_id?: string })
            ?.charger_group_id
        }
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];
