import { Building, CreditCard, Info, MapPin, Tag, User } from "lucide-react";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import type { Group } from "../group-table/schema";

export const ViewMoreGroup = ({
  data,
  onClose,
}: {
  data?: Group;
  onClose?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(!!data);

  const groupDetails = [
    {
      icon: <Info className="h-4 w-4" />,
      label: "Description",
      value: data?.description,
    },
    {
      icon: <MapPin className="h-4 w-4" />,
      label: "Address",
      value: data?.address,
    },
    {
      icon: <Tag className="h-4 w-4" />,
      label: "Latitude",
      value: data?.latitude,
    },
    {
      icon: <Tag className="h-4 w-4" />,
      label: "Longitude",
      value: data?.longitude,
    },
    {
      icon: <Building className="h-4 w-4" />,
      label: "Organization",
      value: data?.organization,
    },
    {
      icon: <User className="h-4 w-4" />,
      label: "Created By",
      value: data?.created_by,
    },
    {
      icon: <CreditCard className="h-4 w-4" />,
      label: "Tariff Plan",
      value: data?.tarrif_plan,
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <DialogContent className="max-w-4xl border-0  p-0 text-gray-100 shadow-2xl">
        <DialogHeader className=" p-4">
          <DialogTitle className="text-xl font-bold">{data?.name}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh]">
          <div className="space-y-4 p-4">
            <Card className=" bg-transparent text-gray-100">
              <CardHeader>
                <CardTitle className="text-lg font-semibold">
                  Group Information
                </CardTitle>
              </CardHeader>
              <CardContent className="grid gap-4 sm:grid-cols-2">
                {groupDetails.map((detail) => (
                  <div
                    key={detail?.label}
                    className="flex items-start space-x-2"
                  >
                    <div className="mt-0.5 rounded-full bg-gray-700 p-1">
                      {detail.icon}
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-400">
                        {detail.label}
                      </p>
                      <p className="text-sm">{detail.value || "N/A"}</p>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>

            {data?.location_description && (
              <Card className="bg-gray-800 text-gray-100">
                <CardHeader>
                  <CardTitle className="text-lg font-semibold">
                    Location Description
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-sm">{data.location_description}</p>
                </CardContent>
              </Card>
            )}
          </div>
        </ScrollArea>
        <DialogFooter className="\ px-4 py-3">
          <DialogClose asChild>
            <Button
              variant="outline"
              className="w-full bg-gray-700 text-gray-200 hover:bg-gray-600"
            >
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
