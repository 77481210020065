import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAddChargerGroupToTariffMutation } from "@/redux/features/charger-network/charger-network.api";
import { useGetTafiffPlansQuery } from "@/redux/features/tariff/tariff.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { ChartCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export const ApplyTo = ({ id, tariffId }: { id: string; tariffId: string }) => {
  const { orgId } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [tariff, setTariff] = useState<
    { value: string; name: string }[] | null
  >(null);
  const [selectedTariff, setSelectedTariff] = useState<string | null>(null);
  const { data: tariffPlansData, isLoading: tariffPlansLoading } =
    useGetTafiffPlansQuery({
      orgId,
      page,
    });
  const [
    addChargerGroupToTariff,
    { isLoading: addChargerGroupToTariffLoading },
  ] = useAddChargerGroupToTariffMutation();

  const handleGroupChange = (value: string) => {
    setSelectedTariff(value);
    addChargerGroupToTariff({
      charger_group_id: id,
      tariff_plan_id: value,
    }).then((res) => {
      toast.success(res.data.message);
    });
  };

  useEffect(() => {
    if (tariffPlansData) {
      setTariff(
        tariffPlansData.results.map((result: any) => ({
          name: result.name,
          value: result.id,
        })),
      );
    }
  }, [tariffPlansData, tariff]);

  return (
    <Select
      disabled={tariffPlansLoading || addChargerGroupToTariffLoading}
      defaultValue={tariffId ?? undefined}
      onValueChange={handleGroupChange}
    >
      <SelectTrigger className="border-none bg-transparent">
        <SelectValue
          placeholder={
            tariffPlansLoading || addChargerGroupToTariffLoading
              ? "Loading"
              : "Apply Tariff Plan"
          }
        />
      </SelectTrigger>

      <SelectContent>
        {addChargerGroupToTariffLoading ? (
          <div className="flex animate-spin justify-center py-2">
            <ChartCircle size="20" color="#FF8A65" />
          </div>
        ) : tariff ? (
          <div>
            {tariffId && (
              <SelectItem value={null as any} className="text-red-500">
                Remove tariff plan
              </SelectItem>
            )}
            {tariff.map(({ value, name }, index) => (
              <SelectItem value={value} key={index}>
                {name}
              </SelectItem>
            ))}
            {tariffPlansData && tariffPlansData.next && (
              <Button
                variant="ghost"
                className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                onClick={() => setPage(page + 1)}
              >
                Load more
              </Button>
            )}
          </div>
        ) : (
          <div>No Charger groups found</div>
        )}
      </SelectContent>
    </Select>
  );
};
