export const steps = [
  {
    id: "Step 0",
    fields: ["first_name", "last_name", "phone_number", "email", "address"],
  },
  {
    id: "Step 1",
    fields: ["password", "confirm_password", "terms"],
  },
];
