"use client";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Switch } from "@/components/ui/switch";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { cn } from "@/lib/utils/cn";
import {
  useCreateCardMutation,
  useEditCardMutation,
} from "@/redux/features/access-control/access-control.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { CalendarIcon, Plus, UserPlus } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { AccessProfileSelect } from "./access-profile-select";
import type { RFIDCardType } from "./schema";

const formSchema = z.object({
  uid: z.string().min(1, {
    message: "UID is required.",
  }),
  card_type: z.string().min(1, {
    message: "Type is required.",
  }),
  access_profile: z.string().min(1, {
    message: "Profile is required.",
  }),
  hasExpiration: z.boolean().default(false),
  card_status: z.string().min(1, {
    message: "Status is required.",
  }),
  expiry_date: z.date().optional().nullable(),
});

export const CreateRFIDCard = ({
  data,
  onClose,
  privateUserId,
}: {
  data?: RFIDCardType;
  onClose?: () => void;
  privateUserId: string;
}) => {
  const [isOpen, setIsOpen] = useState(!!data);
  const { orgId } = useAppSelector((state) => state.auth);

  const defaultValues = data
    ? {
        uid: data?.uid,
        card_type: data?.card_type,
        card_status: data?.card_status,
        access_profile: data?.access_profile ?? "",
        expiry_date: data?.expiry_date ? new Date(data.expiry_date) : null,
        hasExpiration: !!data?.expiry_date,
      }
    : {
        uid: "",
        card_type: "",
        card_status: "",
        access_profile: "",
        hasExpiration: false,
        expiry_date: null,
      };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  const [createCard, { isLoading: createCardLoading }] =
    useCreateCardMutation();
  const [editCard, { isLoading: editCardLoading }] = useEditCardMutation();

  const handleSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      const { hasExpiration, ...rest } = values;
      const result = {
        ...rest,
        organization_id: orgId,
        private_drive_user: privateUserId,
        expiry_date: values?.expiry_date
          ? formatDateToYYYYMMDD(values?.expiry_date)
          : null,
      };
      if (data) {
        if (isDirty) {
          await editCard({ ...result, id: data.id })
            .unwrap()
            .then((response) => {
              form.reset();
              toast.success(response.message);
              setIsOpen(false);
            })
            .catch((error) => {
              toast.error(error?.data?.error_message);
            });
        } else {
          toast.error("Please edit the card before saving.");
          return;
        }
      } else {
        await createCard(result)
          .unwrap()
          .then((response) => {
            form.reset();
            toast.success(response.message);
            setIsOpen(false);
          })
          .catch((error) => {
            toast.error(error?.data?.error_message);
          });
      }
    },
    [createCard, data, editCard, form, isDirty, orgId, privateUserId]
  );

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        if (!open && onClose) {
          onClose();
        }
        setIsOpen(open);
      }}
    >
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4" />
          Create New RFID Card
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-y-auto sm:max-w-lg">
        <SheetHeader>
          <SheetTitle>Add RFID Card</SheetTitle>
          <SheetDescription>
            Create a new RFID card. Fill in the details and click save when
            you're done.
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="uid"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>UID</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="card_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a card type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="mifare_classic">
                        Mifare Classic (not recommended)
                      </SelectItem>
                      <SelectItem value="mifare_desfire">
                        Mifare DESFire
                      </SelectItem>
                      <SelectItem value="mifare_plus">Mifare Plus</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="card_status"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Card Status</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="bg-gray-800 border-gray-700 text-gray-100">
                        <SelectValue placeholder="Select a card type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="enabled">Enabled</SelectItem>
                      <SelectItem value="disabled">Disabled</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <AccessProfileSelect />
            <FormField
              control={form.control}
              name="hasExpiration"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 bg-gray-800 border-gray-700 text-gray-100">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Card Expiration</FormLabel>
                    <FormDescription>
                      Set whether this card should have an expiration date.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {form.watch("hasExpiration") && (
              <FormField
                control={form.control}
                name="expiry_date"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Expiry Date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              " pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value ?? undefined}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date() || date < new Date("1900-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormDescription>
                      Select an expiry date for the card.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="outline" type="button">
                  Cancel
                </Button>
              </SheetClose>
              <Button
                disabled={createCardLoading || editCardLoading}
                variant="secondary"
                type="submit"
              >
                Save changes
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};
