import { useStorage } from "@/lib/helpers/manage-store";
import { api } from "@/redux/api";
import {
  type Middleware,
  type PayloadAction,
  configureStore,
} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authApi } from "./features/auth/auth.api";
import authSliceReducer from "./features/auth/auth.slice";
import { chargerNetworkApi } from "./features/charger-network/charger-network.api";
import chargerNetworkReducer from "./features/charger-network/charger-network.slice";
import { homeApi } from "./features/home/home.api";
import homeSliceReducer from "./features/home/home.slice";
import { orgApi } from "./features/org/org.api";
import orgReducer from "./features/org/org.slice";
import { revenueApi } from "./features/revenue/revenue.api";
import revenueSliceReducer from "./features/revenue/revenue.slice";
import { tariffApi } from "./features/tariff/tariff.api";
import tariffReducer from "./features/tariff/tariff.slice";

const persistConfig = {
  key: "root",
  storage,
};

type RootAction =
  | PayloadAction<any, "auth/refreshToken", { payload: any }>
  | PayloadAction<void, "user/signout">;

const appReducer = combineReducers({
  auth: authSliceReducer,
  home: homeSliceReducer,
  revenue: revenueSliceReducer,
  chargerNetwork: chargerNetworkReducer,
  tariff: tariffReducer,
  org: orgReducer,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

// Define the RootReducer
const rootReducer = (
  state: RootState | undefined,
  action: RootAction,
): RootState => {
  if (action.type === "auth/refreshToken") {
    const { setAccessToken } = useStorage();
    const token = action.payload.data.access;
    setAccessToken(token, { expires: 24 / 6 });
  }
  if (action.type === "user/signout") {
    const { removeAccessToken, removeRefreshToken } = useStorage();
    storage.removeItem("persist:root");
    state = undefined;
    removeAccessToken();
    removeRefreshToken();
    localStorage.clear();
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }).concat(
      authApi.middleware as Middleware,
      homeApi.middleware as Middleware,
      revenueApi.middleware as Middleware,
      chargerNetworkApi.middleware as Middleware,
      tariffApi.middleware as Middleware,
      orgApi.middleware as Middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
