import { Button } from "@/components/ui/button";
import { BsArrowClockwise } from "react-icons/bs";
import "@/styles/mobile-warnings.css";

function MobileWarning() {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="main space-y-12">
      <div className="text-black">
        <h1 className="my-6 text-center text-3xl font-bold">Oops!</h1>
      </div>
      <div className="flex items-center justify-center">
        <img
          src="/images/--mobile-warning.png"
          className="w-[70%]"
          alt="Mobile Warning"
        />
      </div>

      <p className="text-center font-semibold text-black">
        Looks like our app isn't available for mobile users, please switch to a
        desktop.
      </p>
      <Button
        className="rounded-none border-b bg-transparent"
        onClick={refreshPage}
      >
        <BsArrowClockwise className="mr-2" />
        Refresh page
      </Button>
    </div>
  );
}

export default MobileWarning;
