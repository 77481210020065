import { useGetTotalCardsQuery } from "@/redux/features/access-control/access-control.api";
import { AlertCircle, BatteryCharging, Power, Zap } from "lucide-react";
import { useState } from "react";
import { CardExpirations } from "./card-expirations-table/card-expirations.modal";
import { generateCards } from "./card-expirations-table/data";
import type { CardExpirationstype } from "./card-expirations-table/schema";
import { DetailsCard } from "./details-card";
import { Skeleton } from "@/components/ui/skeleton";

export const AccessControlMetrics = () => {
  const cards = generateCards(30);
  const { data, isLoading } = useGetTotalCardsQuery();

  console.log(data);

  const [showCardExpirations, setShowCardExpirations] = useState(false);

  console.log(cards);
  return (
    <div className="flex gap-3">
      {isLoading ? (
        <div className="flex w-full flex-col items-start gap-3">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[160px]" />
            <Skeleton className="h-10 w-[180px]" />
          </div>
          <div className="grid w-full grid-cols-2 gap-2">
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        <div className="relative flex-1 justify-between space-y-3 rounded-lg bg-gradient-linear p-4 text-white shadow-2xl backdrop-blur-xl">
          <div className="text-lg">
            <span>Total Cards - </span>
            <span className="font-bold">
              {data?.data?.total_number_of_cards}
            </span>
          </div>

          <div className=" grid grid-cols-2 gap-2 ">
            <div className="space-y-3">
              <div className="flex items-center rounded-md border border-[#6BEBA4] bg-[#6BEBA4] bg-opacity-20 p-2 text-[#6BEBA4]">
                <Zap className="mr-3 text-base" />
                <div>
                  <p className="text-xs font-semibold">Active</p>
                  <span className="font-bold">20</span>
                </div>
              </div>
              <div className="flex items-center rounded-md border border-blue-500 bg-blue-500 bg-opacity-20 p-2 text-blue-500">
                <BatteryCharging className="mr-3 text-sm" />
                <div>
                  <p className="text-xs font-semibold">Charging</p>
                  <span className="text-base font-bold">20</span>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center rounded-md border border-red-500 bg-red-500 bg-opacity-20 p-2 text-red-500">
                <AlertCircle className="mr-3 text-2xl" />
                <div>
                  <p className="text-xs font-semibold">Disabled</p>
                  <span className="text-base font-bold">20</span>
                </div>
              </div>
              <div className="flex items-center rounded-md border border-gray-500 bg-gray-500 bg-opacity-20 p-2 text-gray-500">
                <Power className="mr-3 text-2xl" />
                <div>
                  <p className="text-xs font-semibold">Revoked</p>
                  <span className="text-base font-bold">20</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-[.8] flex-col  justify-between gap-4">
        <DetailsCard
          title="Total Card Holders"
          metric={data?.data?.total_card_holders}
        />
        <DetailsCard
          title="Cards Expiring this month"
          metric={47}
          hasModal={true}
          onClick={() => {
            setShowCardExpirations(true);
          }}
        />
      </div>

      {showCardExpirations && (
        <CardExpirations
          data={cards}
          isModalOpen={showCardExpirations}
          onClick={() => {
            setShowCardExpirations(false);
            return undefined;
          }}
        />
      )}
    </div>
  );
};
