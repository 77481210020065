import { createSlice } from "@reduxjs/toolkit";

export type UserType = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  user_type: string;
};

interface InitialType {
  isAuthenticated: boolean;
  user: UserType | null;
  userPrivateOrgId: null;
  userOrgType: string;
  userOrgPhoneNumber: string;
  isLoading: boolean;
  error: null;
  selectedOrg: {
    id: null;
  };
  orgDropdown: {
    page: number;
  };
  orgId: string;
}
const initialState: InitialType = {
  isAuthenticated: false,
  user: null,
  userPrivateOrgId: null,
  userOrgType: "private", // none | private | public
  userOrgPhoneNumber: "",
  isLoading: false,
  error: null,
  selectedOrg: {
    id: null,
  },
  orgDropdown: {
    page: 1,
  },
  orgId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    authenticationSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.isLoading = false;
    },
    setUser: (state, action) => {
      state.user = action.payload.data;
    },
    editUser: (state, action) => {
      state.user = action.payload;
    },
    setUserPrivateOrgId: (state, action) => {
      state.userPrivateOrgId = action.payload.data.id;
    },
    setUserOrgType: (state, action) => {
      state.userOrgType = action.payload.data.org_type;
    },
    setUserOrgPhoneNumber: (state, action) => {
      state.userOrgPhoneNumber = action.payload.data.org_phone_number;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
      state.error = null;
    },
    setSelectedOrg: (state, action) => {
      state.selectedOrg = action.payload;
    },
    incrementOrgDropdownPage: (state) => {
      state.orgDropdown.page += 1;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },

    // use this to reset the page when new org data is fetched
    // refer to addChargeGroup Mutation
    resetOrgDropdownPage: (state) => {
      state.orgDropdown.page = 1;
    },
  },
});

export const {
  startLoading,
  authenticationSuccess,
  setUser,
  setError,
  editUser,
  setUserPrivateOrgId,
  setUserOrgType,
  setSelectedOrg,
  incrementOrgDropdownPage,
  resetOrgDropdownPage,
  setUserOrgPhoneNumber,
  setOrgId,
} = authSlice.actions;

export default authSlice.reducer;
