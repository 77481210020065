import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils/cn";
import { CreateChargerGroup } from "@/pages/(dashboard)/ChargerNetwork/components/group-table/create-charger-group";
import { useGetChargeGroupsQuery } from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { ChartCircle } from "iconsax-react";
import { ArrowUpRight, UserPlus } from "react-feather";
import { Button } from "./ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

interface ChargerGroupType {
  id: string;
  name: string;
}
/**
 * ChargerGroupSelect Component
 *
 * This component provides a dropdown selection for charger groups, with search functionality
 * and a load more button for fetching additional data. It uses a combobox pattern with the
 * ability to search and dynamically load more charger groups as needed.
 *
 * **Important:** When using this component in a form, ensure that your Zod schema includes
 * a field with the name `charger_group`. This field should correspond to the selected charger
 * group's ID.
 *
 * **Zod Validation Example:**
 * ```ts
 * import { z } from "zod";
 *
 * const formSchema = z.object({
 *   charger_group: z.string().nonempty("Charger group is required"),
 * });
 * ```
 *
 * **Default Values Example:**
 * When setting up form default values (e.g., using React Hook Form or similar), make sure to
 * provide a default value for `charger_group` like below:
 *
 * ```ts
 * const defaultValues = {
 *   charger_group: "", // or the default charger group ID if available
 * };
 * ```
 *
 * **Props:**
 * This component does not take any props directly, but it pulls charger group data from
 * the store and updates the form field value accordingly.
 *
 * **Functionality:**
 * - Displays a list of charger groups in a dropdown.
 * - Allows searching through the list by group name.
 * - Displays a "Load more" button if more charger groups are available to fetch.
 * - Handles fetching and rendering new charger groups as the user loads more.
 *
 * **Redux Dependencies:**
 * - `useGetChargeGroupsQuery` is used to fetch the charger groups from the API.
 * - The selected charger's group ID is stored in the form's field named `charger_group`.
 */
export function ChargerGroupSelect() {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const [groupDataResult, setGroupDataResult] = useState<ChargerGroupType[]>(
    []
  );
  const { data, isFetching } = useGetChargeGroupsQuery({
    orgId: orgId,
    page: page,
  });

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data?.results) {
      setGroupDataResult((prevGroups) => [
        ...prevGroups,
        ...data.results.filter(
          (newGroup) => !prevGroups.some((group) => group.id === newGroup.id)
        ),
      ]);
    }
  }, [data]);

  const filteredGroups = groupDataResult?.filter((group: ChargerGroupType) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const loadMoreData = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <FormField
      name="charger_group"
      render={({ field }) => {
        useEffect(() => {
          if (field.value) {
            setValue(field.value);
          }
        }, [field.value]);

        return (
          <FormItem>
            <FormLabel className="whitespace-nowrap">Charger Group</FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <FormControl>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    aria-expanded={open}
                    className="w-full  bg-gray-800 border-gray-700 text-gray-100"
                    disabled={isFetching}
                  >
                    {value
                      ? groupDataResult.find((group) => group.id === value)
                          ?.name
                      : null}
                    <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
              </FormControl>
              <PopoverContent className="w-[450px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search charger group..."
                    value={searchQuery}
                    onInput={(e) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <div className="relative p-2 flex gap-2 ">
                    <CreateChargerGroup />
                    <Button
                      type="button"
                      variant="outline"
                      className=" w-full bg-gray-800 whitespace-nowrap text-gray-200 gap-1 border-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      View Groups
                      <ArrowUpRight className="mr-2 h-4 w-4" />
                    </Button>
                  </div>
                  <CommandList className="w-full">
                    {isFetching ? (
                      <div className="flex justify-center py-2">
                        <ChartCircle
                          className="animate-spin"
                          size={20}
                          color="#FF8A65"
                        />
                      </div>
                    ) : filteredGroups?.length > 0 ? (
                      <>
                        <CommandGroup className="text-white">
                          {filteredGroups.map((group: ChargerGroupType) => (
                            <CommandItem
                              key={group.id}
                              value={group.id}
                              onSelect={(currentValue) => {
                                setValue(
                                  currentValue === value ? "" : currentValue
                                );
                                setOpen(false);
                                field.onChange(currentValue);
                              }}
                            >
                              <Check
                                className={
                                  value === group.id
                                    ? "mr-2 h-4 w-4 opacity-100"
                                    : "mr-2 h-4 w-4 opacity-0"
                                }
                              />
                              {group.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            variant="outline"
                            className="w-full bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <CommandEmpty>No Charger group found.</CommandEmpty>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            variant="outline"
                            className="w-full bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
