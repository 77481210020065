import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useGetChargeGroupGlobalQuery,
  useGetChargeGroupsQuery,
  useGetChargersStateQuery,
} from "@/redux/features/charger-network/charger-network.api";
import {
  incrementGlobalChargerGroupsPage,
  setChargeGroups,
  setChargerStatus,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { ChartCircle } from "iconsax-react";
import type React from "react";
import { useEffect, useState } from "react";
import { AlertCircle, BatteryCharging, Power, Zap } from "react-feather";

interface ChargerGroup {
  id: string;
  name: string;
  onlineCount: number;
  chargingCount: number;
  failureCount: number;
  offlineCount: number;
  [key: string]: number | string;
}

const ChargersStatusCard: React.FC = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const globalChargeGroupsPage = useAppSelector(
    (state) => state.chargerNetwork.globalChargerGroups.page
  );
  const dispatch = useAppDispatch();
  const { chargerGroupStatus, loading } = useAppSelector(
    (state) => state.chargerNetwork
  );

  const {
    data: groupsData,
    isFetching,
    isError,
    isLoading,
  } = useGetChargeGroupsQuery({ orgId: orgId, page: globalChargeGroupsPage });

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const {
    data: chargerStatusData,
    isFetching: chargerStatusFetching,
    isError: chargerStatusError,
    isLoading: chargerStatusIsLoading,
  } = useGetChargersStateQuery({
    org_id: orgId,
    group_id: selectedGroup,
  });
  const globalChargeGroups = useGetChargeGroupGlobalQuery({
    orgId: orgId,
    page: globalChargeGroupsPage,
  });

  const handleGroupChange = (value: string) => {
    setSelectedGroup(value);
  };

  const loadMoreData = (data: any) => {
    if (data.next) {
      dispatch(incrementGlobalChargerGroupsPage());
    }
  };

  useEffect(() => {
    if (chargerStatusIsLoading) {
      dispatch(startLoading());
    } else if (groupsData) {
      dispatch(setChargeGroups(groupsData));
    }
    if (chargerStatusIsLoading) {
      dispatch(startLoading());
    } else if (chargerStatusData) {
      dispatch(setChargerStatus(chargerStatusData.data));
    }
  }, [
    dispatch,
    isFetching,
    chargerStatusFetching,
    chargerStatusData,
    groupsData,
  ]);

  return (
    <div className="relative flex-1 justify-between space-y-3 rounded-lg bg-gradient-linear p-4 text-white shadow-2xl backdrop-blur-xl">
      {loading ? (
        <div className="flex w-full flex-col items-start gap-3">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[160px]" />
            <Skeleton className="h-10 w-[180px]" />
          </div>
          <div className="grid w-full grid-cols-2 gap-2">
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
            <Skeleton className="h-[60px] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        chargerGroupStatus && (
          <>
            <div className="flex items-center justify-between gap-4">
              <h2 className="text-lg">
                Total Chargers -{" "}
                <span className="font-bold">
                  {chargerGroupStatus.charger_count}
                </span>
              </h2>
              <div className="mt-2 flex-[.5]">
                <Select disabled={isLoading} onValueChange={handleGroupChange}>
                  <SelectTrigger className="">
                    <SelectValue
                      placeholder={isLoading ? "Loading Groups" : "All Groups"}
                    />
                  </SelectTrigger>

                  <SelectContent>
                    {globalChargeGroups.isLoading ? (
                      <div className="flex animate-spin justify-center py-2">
                        <ChartCircle size="20" color="#FF8A65" />
                      </div>
                    ) : globalChargeGroups.data &&
                      Object.keys(globalChargeGroups.data).length > 0 ? (
                      <div>
                        {globalChargeGroups.data.results.map((group: any) => (
                          <SelectItem value={group.id} key={group.name}>
                            {group.name}
                          </SelectItem>
                        ))}
                        {globalChargeGroups.data.results.length > 0 &&
                          !globalChargeGroups.isFetching &&
                          globalChargeGroups.data.next && (
                            <Button
                              className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                              onClick={() =>
                                loadMoreData(globalChargeGroups.data)
                              }
                            >
                              Load more
                            </Button>
                          )}

                        {globalChargeGroups.isFetching && (
                          <div className="flex animate-spin justify-center py-2">
                            <ChartCircle size="20" color="#FF8A65" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>No Charger groups found</div>
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="space-y-3">
                <div className="flex items-center rounded-md border border-[#6BEBA4] bg-[#6BEBA4] bg-opacity-20 p-2 text-[#6BEBA4]">
                  <Zap className="mr-3 text-base" />
                  <div>
                    <p className="text-xs font-semibold">Online</p>
                    <span className="font-bold">
                      {chargerGroupStatus.category_counts.online}
                    </span>
                  </div>
                </div>
                <div className="flex items-center rounded-md border border-blue-500 bg-blue-500 bg-opacity-20 p-2 text-blue-500">
                  <BatteryCharging className="mr-3 text-sm" />
                  <div>
                    <p className="text-xs font-semibold">Charging</p>
                    <span className="text-base font-bold">
                      {chargerGroupStatus.category_counts.charging}
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center rounded-md border border-red-500 bg-red-500 bg-opacity-20 p-2 text-red-500">
                  <AlertCircle className="mr-3 text-2xl" />
                  <div>
                    <p className="text-xs font-semibold">Failure</p>
                    <span className="text-base font-bold">
                      {chargerGroupStatus.category_counts.failure}
                    </span>
                  </div>
                </div>
                <div className="flex items-center rounded-md border border-gray-500 bg-gray-500 bg-opacity-20 p-2 text-gray-500">
                  <Power className="mr-3 text-2xl" />
                  <div>
                    <p className="text-xs font-semibold">Offline</p>
                    <span className="text-base font-bold">
                      {chargerGroupStatus.category_counts.offline}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ChargersStatusCard;
