import { useAuth } from "@/lib/hooks/use-auth";
import {
  createNewOrg,
  generateInviteLink,
  getOrgProfile,
} from "@/services/organisation";
import type { CreateNewOrganisationType } from "@/types/organisation";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { OrganisationContext } from "./context";
import {
  CREATE_NEW_ORG_FAIL,
  CREATE_NEW_ORG_LOADING,
  CREATE_NEW_ORG_SUCCESS,
  GET_ORG_PROFILE_FAIL,
  GET_ORG_PROFILE_LOADING,
  GET_ORG_PROFILE_SUCCESS,
  INVITE_LINK_LOADING,
  organisationReducer,
} from "./reducer";
import type { OrganisationContextPropType as OrganisationContextPropertyType } from "./types/organisation";

const OrganisationProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { authenticated } = useAuth();

  const [orgName, setOrgName] = useState("");
  const [inviteLink, setInviteLink] = useReducer(organisationReducer, {
    data: null,
    loading: false,
    error: null,
  });

  const [newOrg, setNewOrg] = useReducer(organisationReducer, {
    data: null,
    loading: false,
    error: null,
  });

  const [orgProfile, setOrgProfile] = useReducer(organisationReducer, {
    data: null,
    loading: false,
    error: null,
  });

  useEffect(() => {
    if (authenticated)
      (async () => {
        const orgId = localStorage.getItem("org_id");
        const storedOrgname = localStorage.getItem("org_name");
        if (orgId && !storedOrgname) {
          const response: any = await getOrgProfile({ org_id: orgId });
          if (response.status == "success" && response.data) {
            setOrgName(response.data.org_name);
            localStorage.setItem("org_name", response.data.org_name);
            localStorage.setItem("org_type", response.data.org_type);
          }
        }
        if (storedOrgname) {
          setOrgName(storedOrgname);
        }
        if (!orgId && !storedOrgname) {
          return <Navigate to="/" />;
        }
      })();
  }, []);

  const handleInviteLink = useCallback(async (values: { org_id: string }) => {
    try {
      setInviteLink({ type: INVITE_LINK_LOADING });

      const response = generateInviteLink(values);
    } catch {}
  }, []);

  const handleCreateNewOrg = useCallback(
    async (values: CreateNewOrganisationType) => {
      try {
        setNewOrg({ type: CREATE_NEW_ORG_LOADING });
        const response = await createNewOrg(values);

        localStorage.setItem("org_id", response.data.id);

        if (response.status == "success") {
          setNewOrg({
            type: CREATE_NEW_ORG_SUCCESS,
            payload: response.data,
          });
          toast.success(response.message);
        } else {
          setNewOrg({
            type: CREATE_NEW_ORG_FAIL,
          });
        }
      } catch {
        setNewOrg({
          type: CREATE_NEW_ORG_FAIL,
        });
        toast.error("Something went wrong");
      }
    },
    [],
  );

  const handleGetOrgProfile = useCallback(async (value: { org_id: string }) => {
    try {
      setOrgProfile({ type: GET_ORG_PROFILE_LOADING });
      const response = await getOrgProfile(value);
      if (response.status == "success" && response.data) {
        setOrgProfile({
          type: GET_ORG_PROFILE_SUCCESS,
          payload: response.data,
        });

        return response.data;
      } else {
        setOrgProfile({
          type: GET_ORG_PROFILE_FAIL,
        });
      }
    } catch {
      setOrgProfile({
        type: GET_ORG_PROFILE_FAIL,
      });
    }
  }, []);

  const values: OrganisationContextPropertyType = {
    handleInviteLink,
    inviteLink,

    handleCreateNewOrg,
    newOrg,

    handleGetOrgProfile,
    orgProfile,

    orgName,
  };
  return (
    <OrganisationContext.Provider value={values}>
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
