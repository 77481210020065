// import { Search } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useStorage } from "@/lib/helpers/manage-store";
import { cn } from "@/lib/utils/cn";
import { CreateOrganisation } from "@/pages/(dashboard)/Settings/components/create-organisation-modal/create-organisation";
import { setOrgId } from "@/redux/features/auth/auth.slice";
import { useGetOrganizationsQuery } from "@/redux/features/org/org.api";
import {
  type OrgType,
  setCurrentOrg,
  setOrganisations,
  startLoading,
} from "@/redux/features/org/org.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { User, X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SidebarMobile from "./sidebar-mobile";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";

interface Properties {
  title: string;
}
export const Navbar: React.FC<Properties> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { removeAccessToken, removeRefreshToken } = useStorage();
  const { orgId, isLoading } = useAppSelector((state) => state.auth);

  const { loading, currentOrg, organisations } = useAppSelector(
    (state) => state.org
  );

  const { data: organizationsData, isLoading: organiszationsLoading } =
    useGetOrganizationsQuery(undefined);

  const [logoutModal, setLogoutModal] = useState(false);
  const [orgModal, setOrgModal] = useState(false);

  const [privateOrg, setPrivateOrg] = useState<OrgType | null | undefined>(
    null
  );
  const [publicOrg, setPublicOrg] = useState<OrgType[] | null | undefined>(
    null
  );

  const handleUserLogout = useCallback(() => {
    removeAccessToken();
    removeRefreshToken();
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }, [removeAccessToken, removeRefreshToken]);

  useEffect(() => {
    if (organiszationsLoading) {
      dispatch(startLoading());
    } else if (organizationsData) {
      dispatch(setOrganisations(organizationsData.data));
    }
  }, [organiszationsLoading, dispatch, organizationsData]);

  const handleOrgChange = (value: string) => {
    dispatch(setOrgId(value));
    navigate("/");
  };

  useEffect(() => {
    if (organisations) {
      dispatch(setCurrentOrg(organisations.find((org) => org.id === orgId)));
      setPrivateOrg(organisations.find((data) => data.org_type === "Private"));
      setPublicOrg(
        organisations.filter((data) => data.org_type === "Multi-user")
      );
    }
  }, [dispatch, organisations, orgId]);

  return (
    <div className="mt-9 mb-5 flex justify-between gap-4 md:items-center">
      <div className="block rounded-xl bg-gradient-radial p-4 backdrop-blur-xl md:hidden">
        <SidebarMobile />
      </div>
      <div className="relative flex items-center justify-center rounded-xl bg-gradient-radial filter backdrop-blur-xl md:block">
        <h3 className="px-4 py-1 text-lg text-white md:text-[28px]">{title}</h3>
      </div>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex gap-4 rounded-full bg-gradient-radial p-2 filter backdrop-blur-xl"
          >
            <User />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="border-[#323232] bg-background text-white">
          {currentOrg && (
            <DropdownMenuLabel className="flex items-center gap-2 bg-transparent">
              <Avatar className="relative flex h-6 w-6 shrink-0 overflow-hidden rounded-full">
                <AvatarImage src={currentOrg.org_profile_picture} />
                <AvatarFallback>
                  {currentOrg?.org_name?.charAt(0)}
                </AvatarFallback>
              </Avatar>
              <span>{currentOrg.org_name}</span>
              <Button
                variant="ghost"
                onClick={() => setOrgModal(true)}
                className="h-auto w-full max-w-[40px] justify-center overflow-auto px-2 py-1 text-xs text-muted-foreground"
              >
                <CaretSortIcon />
              </Button>
            </DropdownMenuLabel>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem disabled>Support</DropdownMenuItem>
          <DropdownMenuItem>
            <Link to="/settings">Settings</Link>
          </DropdownMenuItem>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleUserLogout} className="text-red-400">
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={orgModal} onOpenChange={setOrgModal}>
        <DialogContent className="max-w-sm gap-0 border-[#323232] bg-transparent">
          <Command className="border-[#323232]">
            <div className="relative">
              <CommandInput className="h-9" placeholder="search..." />

              <Button
                variant="secondary"
                onClick={() => setOrgModal(false)}
                className="absolute right-1 top-1 h-auto p-1"
              >
                <X size={20} />
              </Button>
            </div>
            <CommandList>
              <CommandEmpty>No organisation found.</CommandEmpty>{" "}
              <ScrollArea className="max-h-64">
                <CommandGroup
                  className="divide-y-2 overflow-auto"
                  heading="Private"
                >
                  {privateOrg && (
                    <CommandItem
                      value={privateOrg.org_name}
                      onSelect={() => {
                        handleOrgChange(privateOrg.id);
                        setOrgModal(false);
                      }}
                      className="gap-2"
                      disabled={loading}
                    >
                      <Avatar className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                        <AvatarImage src={privateOrg.org_profile_picture} />
                        <AvatarFallback>
                          {privateOrg?.org_name?.charAt(0)}
                        </AvatarFallback>
                      </Avatar>

                      <span className="text-sm font-medium">
                        {privateOrg.org_name}
                      </span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          privateOrg.id === orgId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  )}
                </CommandGroup>
                <CommandGroup
                  className="h-56 divide-y-2 overflow-auto"
                  heading="Public"
                >
                  {publicOrg?.map((org, index) => (
                    <CommandItem
                      key={org?.id}
                      value={org.org_name}
                      onSelect={() => {
                        handleOrgChange(org.id);
                        setOrgModal(false);
                      }}
                      className="gap-2"
                      disabled={loading}
                    >
                      <Avatar className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                        <AvatarImage src={org.org_profile_picture} />
                        <AvatarFallback>
                          {org?.org_name?.charAt(0)}
                        </AvatarFallback>
                      </Avatar>

                      <span className="text-sm font-medium">
                        {org.org_name}
                      </span>
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          org.id === orgId ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </ScrollArea>
            </CommandList>
          </Command>
          <CreateOrganisation />
        </DialogContent>
      </Dialog>
    </div>
  );
};
