import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { faker } from "@faker-js/faker";
import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";

type Properties = {
  index: number;
  onRemove: (index: number) => void;
  onUpdate: (index: number, data: { user_email: string; role: string }) => void;
  form: UseFormReturn<
    {
      role: string;
      user_email: string;
    },
    any,
    undefined
  >;
};

const generateRandomData = () => {
  const canceled = false;
  const expired = faker.datatype.boolean();
  return {
    expired,
    expirationDate: expired ? faker.date.future() : faker.date.past(),
    canceled,
    cancellationDate: canceled ? faker.date.past() : faker.date.future(),
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
    id: faker.string.alpha(10),
  };
};

export const InviteMemberDetails = ({
  index,
  onRemove,
  onUpdate,
  form,
}: Properties) => {
  const [details, setDetails] = useState({
    user_email: "",
    role: "",
  });

  const handleRemove = () => {
    if (index > 0) {
      onRemove(index);
    }
  };

  const handleRoleChange = (selectedRole: string) => {
    setDetails((previousDetails) => ({
      ...previousDetails,
      role: selectedRole,
    }));
    onUpdate(index, {
      user_email: details.user_email,
      role: selectedRole,
      // ...generateRandomData(),
    });
  };

  return (
    <div className="my-4 flex items-center gap-3">
      <FormField
        control={form.control}
        name="user_email"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel htmlFor="user_email" className="w-full">
              Email Address
            </FormLabel>
            <FormControl>
              <Input
                type="email"
                id="user_email"
                placeholder="john.doe@grdflo.com"
                value={details.user_email}
                onChange={(e) => {
                  field.onChange(e);
                  setDetails((previousDetails) => ({
                    ...previousDetails,
                    user_email: e.target.value,
                  }));
                }}
              />
            </FormControl>
            <FormMessage className="text-[10px]" />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="role"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel htmlFor="role" className="w-full">
              Role
            </FormLabel>
            <div className="flex items-center gap-3">
              <Select
                onValueChange={(value) => {
                  field.onChange(value);
                  handleRoleChange(value);
                }}
                defaultValue={details.role}
              >
                <FormControl>
                  <SelectTrigger
                  //disabled={loading}
                  >
                    <SelectValue id="path" placeholder="Select a role." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Member">Member</SelectItem>
                  <SelectItem value="Owner">Owner</SelectItem>
                </SelectContent>
              </Select>

              {index > 0 && (
                <Button
                  variant="secondary"
                  type="button"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              )}
            </div>
            <FormMessage className="text-[10px]" />
          </FormItem>
        )}
      />
    </div>
  );
};
