import { useAppSelector } from "@/redux/hooks";

type Properties = {
  amount: number;
};

export const AmountRow = ({ amount }: Properties) => {
  const { globalCurrency } = useAppSelector((state) => state.home);
  return (
    <div className="text-xs">
      {globalCurrency}
      {amount}
    </div>
  );
};
