import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useStorage } from "@/lib/helpers/manage-store";
import { useLoginMutation } from "@/redux/features/auth/auth.api";
import { authenticationSuccess } from "@/redux/features/auth/auth.slice";
import { useAppDispatch } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOff } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import * as z from "zod";

const loginFormSchema = z.object({
  email: z.string().email({ message: "Invalid email address." }),
  password: z.string(),
});

const Login = () => {
  const { setAccessToken, setRefreshToken } = useStorage();

  const [loginMutation, { isLoading }] = useLoginMutation();
  const [searchParameters, setSearchParameters] = useSearchParams();

  const redirect = searchParameters.get("redirect") || null;
  const inviteToken = searchParameters.get("inviteToken") || null;
  const invitedUserEmail = searchParameters.get("userEmail") || null;
  const orgName = searchParameters.get("orgName") || null;

  const [passwordType, setPasswordType] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loginDetailsForm = useForm<z.infer<typeof loginFormSchema>>({
    defaultValues: {
      email: invitedUserEmail || "",
    },
    resolver: zodResolver(loginFormSchema),
  });

  const EyeComponent = passwordType ? EyeIcon : EyeOff;

  const handleLogin = async (values: { email: string; password: string }) => {
    try {
      const loginResult = await loginMutation(values)
        .unwrap()
        .then((data) => {
          if (data?.response_status == "success" && data?.user_data) {
            setAccessToken(data.access_token, { expires: 24 / 6 });
            setRefreshToken(data.refresh_token, {
              expires: 24 / 6,
            });

            dispatch(authenticationSuccess(data.user_data));

            toast.success(data.message);

            if (redirect) {
              setTimeout(() => {
                navigate(
                  `/acceptInvitation?referrer=${invitedUserEmail}&invite_token=${inviteToken}`,
                );
              }, 1000);
            } else {
              setTimeout(() => {
                navigate("/");
              }, 1000);
            }
          }
        });
    } catch (error: any) {
      if (error?.data?.response_status == "error") {
        toast.error(error?.data?.error_message);
      }
      if (error?.data?.detail) {
        toast.error(error?.data?.detail);
      }
    }
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="m-4 mx-auto w-full max-w-[600px]">
        <div>
          <div className="my-3 flex flex-col items-center justify-center gap-3">
            <img src="/images/logo-text.png" height={50} width={150} />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
            <span className="mx-2 w-max whitespace-nowrap text-center text-sm font-light text-white">
              Sign in with
            </span>
            <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
          </div>
        </div>

        {invitedUserEmail && orgName && inviteToken && (
          <div className="my-4 rounded-md bg-[#eab308] bg-muted-foreground px-3 py-4">
            <p className="text-sm">
              After log in, you will be automatically added to{" "}
              <span className="font-bold underline underline-offset-2">
                {orgName}
              </span>
            </p>
          </div>
        )}
        <Form {...loginDetailsForm}>
          <form
            className="my-8 flex flex-col gap-2"
            onSubmit={loginDetailsForm.handleSubmit(handleLogin)}
          >
            <div className="flex flex-col gap-4">
              <FormField
                control={loginDetailsForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Email Address"
                        className=""
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />

              <FormField
                control={loginDetailsForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <div className="relative transition-all duration-150">
                        <Input
                          type={passwordType ? "password" : "text"}
                          placeholder="Password"
                          className=""
                          {...field}
                        />

                        <EyeComponent
                          className="absolute bottom-3 right-2 z-10 cursor-pointer"
                          size={16}
                          onClick={() => {
                            setPasswordType((previous) => !previous);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />
            </div>
            <div className="my-2 flex w-full items-end justify-end gap-5 px-2">
              <Link
                to="/forgot-password"
                className="text-xs font-bold text-white underline"
              >
                Forgot Password?
              </Link>
            </div>
            <div className="my-6 w-full">
              <div className="flex items-center justify-center">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-[#eab308] text-black hover:bg-[#eab308]/60 md:w-[60%]"
                >
                  Login
                </Button>
              </div>
            </div>
          </form>
        </Form>
        <div>
          <h2 className="text-xs font-light text-white">
            Not a member?{" "}
            <Link to="/signup" className="font-bold underline">
              Sign up now
            </Link>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Login;
