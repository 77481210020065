import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { ColumnDef } from "@tanstack/react-table";

import type { Connector } from "../../../data/schema";

export const columns: ColumnDef<Connector>[] = [
  {
    accessorKey: "connector_id",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="ID"
      />
    ),
    cell: ({ row }) => <div className="">{row.getValue("connector_id")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "connector_format",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="Format"
      />
    ),
    cell: ({ row }) => (
      <div className="">{row.getValue("connector_format")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "connector_type",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title="Type"
      />
    ),
    cell: ({ row }) => <div className="">{row.getValue("connector_type")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "connector_status",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="whitespace-nowrap"
        column={column}
        title=" Status"
      />
    ),
    cell: ({ row }) => (
      <div className="">{row.getValue("connector_status")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
];
