import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useDeleteOrganisationMutation } from "@/redux/features/org/org.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineWarning, AiTwotoneDelete } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

type Properties = {};

const FormSchema = z.object({
  deleteAccount: z.boolean(),
});

export const DeleteOrganisation = (properties: Properties) => {
  const { orgId } = useAppSelector((state) => state.auth);
  const { currentOrg } = useAppSelector((state) => state.org);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const [deleteOrg, { isLoading }] = useDeleteOrganisationMutation();

  function onSubmit(data: z.infer<typeof FormSchema>) {
    deleteOrg(orgId)
      .unwrap()
      .then((res) => {
        toast.success(res.message);
      });
  }
  return (
    <div className="py-10">
      <div className="flex items-center gap-2 text-[#df4545]">
        <AiTwotoneDelete color="#df4545" size={24} />
        <h3 className="text-2xl font-semibold text-[#df4545]">
          Delete Organisation Account
        </h3>
      </div>
      <hr className="my-2 mt-6 border-[.2px] border-slate-600" />
      <p className="flex items-center gap-2 text-gray-400">
        <AiOutlineWarning className="text-[#df4545]" />
        Warning! This action will{" "}
        <span className="font-semibold uppercase text-[#df4545]">
          permanently delete
        </span>
        your account from our servers
      </p>
      <div className="my-4 flex flex-col gap-6">
        <Dialog>
          <DialogTrigger asChild>
            <Button
              type="button"
              variant="destructive"
              className="w-max items-center gap-2 bg-[#df4545]"
              disabled={currentOrg?.org_type == "Private"}
              //   onClick={() => setIsOpen(true)}
            >
              <IoIosWarning size={18} />
              Delete Organisation
            </Button>
          </DialogTrigger>
          <DialogContent className="border border-[#666] px-4 py-8 sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>This Action is not available</DialogTitle>
              <DialogDescription>
                Please contact support to delete your organisation and also
                disconnect your chargers from our servers.
              </DialogDescription>
            </DialogHeader>

            <DialogFooter>
              <DialogClose asChild>
                <Button variant="secondary" type="submit">
                  Continue
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
