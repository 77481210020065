import { OrganisationNavbar } from "@/components/organisation-navbar";

interface Properties {
  children: React.ReactNode;
}
export const Organisation: React.FC<Properties> = ({ children }) => {
  return (
    <div className="h-full w-full">
      <OrganisationNavbar />
      {children}
    </div>
  );
};
