import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { FilterSection } from "./filter-section";
import { PendingInvitations } from "./pending-invitations";
import TeamTable from "./team-table";

export function TeamMembers() {
  return (
    <Tabs defaultValue="teamMembers" className="bg-transparent">
      <TabsList className="flex w-full justify-start rounded-none border-b border-gray-600 bg-transparent p-0">
        <TabsTrigger
          value="teamMembers"
          className="margin-0 -mb-2 rounded-none bg-transparent data-[state=active]:border-b-2 data-[state=active]:bg-transparent"
        >
          Team members
        </TabsTrigger>
        <TabsTrigger
          value="invitations"
          className="margin-0 -mb-2 rounded-none bg-transparent data-[state=active]:border-b-2 data-[state=active]:bg-transparent"
        >
          Pending invitations
        </TabsTrigger>
      </TabsList>
      <FilterSection />
      <TabsContent value="teamMembers">
        <TeamTable />
      </TabsContent>
      <TabsContent value="invitations">
        <PendingInvitations />
      </TabsContent>
    </Tabs>
  );
}
