import { Navbar } from "@/components/navbar";
import { Button } from "@/components/ui/button";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useGetPastPaymentCyclesQuery,
  useGetPaymentCyclesQuery,
} from "@/redux/features/revenue/revenue.api";
import {
  setPaymentSettlementCycle,
  startLoading,
} from "@/redux/features/revenue/revenue.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

import { columns } from "./components/payment-plan/column";
import { PaymentPlanDataTable } from "./components/payment-plan/payment-plan-data-table";

type Properties = {};

const Settlements = (properties: Properties) => {
  const dispatch = useAppDispatch();
  const { orgId } = useAppSelector((state) => state.auth);
  const { paymentSettlementCycles, loading } = useAppSelector(
    (state) => state.revenue,
  );

  const [viewPastPaymentCycle, setViewPastPaymentCycle] = useState(false);

  const [page, setPage] = useState(1);
  const [pastPage, setPastPage] = useState(1);

  const { data, isLoading } = useGetPaymentCyclesQuery(orgId, { skip: !orgId });
  const { data: pastData, isLoading: pastLoading } =
    useGetPastPaymentCyclesQuery(orgId, { skip: !orgId });

  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setPaymentSettlementCycle(data));
    }
  }, [paymentSettlementCycles, dispatch, isLoading, data]);

  return (
    <div className="relative flex flex-col gap-9">
      <div
        style={{
          backgroundImage: "url(/images/--gf-dashboard-cover.png)",
          backgroundSize: "100% 270px",
          backgroundRepeat: "no-repeat",
        }}
        className="mb-10 flex flex-col gap-5 bg-cover px-4 pt-4 md:px-9 md:pt-9 2xl:gap-11"
      >
        <div className="container px-0">
          <Navbar title={"Payment Cycle"} />
        </div>

        <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
          <div className="mb-4">
            <h2 className="text-2xl font-bold">Your Payment current cycle.</h2>
            <p className="text-lg">
              Here's a list of your current settlement cycle plans!
            </p>
          </div>

          <PaymentPlanDataTable
            data={paymentSettlementCycles && paymentSettlementCycles.results}
            columns={columns}
            loading={loading}
          />

          {loading ? (
            <div className="my-2 flex justify-end">
              <div className="flex justify-end gap-2">
                <Skeleton className="h-6 w-12 rounded-sm" />
                <Skeleton className="h-6 w-12 rounded-sm" />
                <Skeleton className="h-6 w-12 rounded-sm" />
              </div>
            </div>
          ) : (
            paymentSettlementCycles && (
              <Pagination className="justify-end pr-6 pt-4">
                <PaginationContent>
                  {paymentSettlementCycles.previous && (
                    <PaginationItem onClick={() => setPage(page - 1)}>
                      <PaginationPrevious href="#" />
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <PaginationLink href="#" isActive>
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                  {paymentSettlementCycles.next && (
                    <PaginationItem onClick={() => setPage(page + 1)}>
                      <PaginationNext href="#" />
                    </PaginationItem>
                  )}
                </PaginationContent>
              </Pagination>
            )
          )}
        </div>

        {pastData && pastData.results.length > 0 && !viewPastPaymentCycle && (
          <Button
            onClick={() => {
              setViewPastPaymentCycle(true);
            }}
            variant="secondary"
            className="mx-auto w-full max-w-[40%]"
          >
            View Past Payment Cycles
          </Button>
        )}

        {viewPastPaymentCycle && (
          <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
            <div className="mb-4">
              <h2 className="text-2xl font-bold">
                Your Past Payment current cycle.
              </h2>
              <p className="text-lg">
                Here's a list of your past settlement cycle plans!
              </p>
            </div>

            <PaymentPlanDataTable
              data={pastData.results}
              columns={columns}
              loading={pastLoading}
            />

            {loading ? (
              <div className="my-2 flex justify-end">
                <div className="flex justify-end gap-2">
                  <Skeleton className="h-6 w-12 rounded-sm" />
                  <Skeleton className="h-6 w-12 rounded-sm" />
                  <Skeleton className="h-6 w-12 rounded-sm" />
                </div>
              </div>
            ) : (
              pastData && (
                <Pagination className="justify-end pr-6 pt-4">
                  <PaginationContent>
                    {pastData.previous && (
                      <PaginationItem onClick={() => setPastPage(page - 1)}>
                        <PaginationPrevious href="#" />
                      </PaginationItem>
                    )}
                    <PaginationItem>
                      <PaginationLink href="#" isActive>
                        {pastPage}
                      </PaginationLink>
                    </PaginationItem>
                    {pastData.next && (
                      <PaginationItem onClick={() => setPastPage(page + 1)}>
                        <PaginationNext href="#" />
                      </PaginationItem>
                    )}
                  </PaginationContent>
                </Pagination>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Settlements;
