import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { ColumnDef } from "@tanstack/react-table";
import type { CardExpirationstype } from "./schema";
import { formatDateShort } from "@/lib/helpers/dates";

export const columns: ColumnDef<CardExpirationstype>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "uid",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="UID" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("uid")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "cardName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Card Name" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("cardName")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "accessProfile",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Access Profile" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("accessProfile")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "expirationDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role Expiration Date" />
    ),
    cell: ({ row }) => (
      <div className="capitalize text-[10px]">
        {formatDateShort(row.getValue("expirationDate"))}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
];
