import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import { formatDateShort } from "@/lib/helpers/dates";
import type { ColumnDef } from "@tanstack/react-table";

import { AmountRow } from "../../../../../components/amount-row";
import { extractFirstId } from "../../../../../lib/helpers/extract-first-id";
import { statuses } from "../../data/data";
import type { Revenue } from "../../data/schema";
import { Actions } from "./actions";

export const columns: ColumnDef<Revenue>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => <AmountRow amount={row.getValue("amount")} />,

    enableSorting: true,
    enableHiding: false,
  },

  {
    accessorKey: "charger_location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charger Location" />
    ),
    cell: ({ row }) => (
      <div className="truncate capitalize">
        {row.getValue("charger_location")}
      </div>
    ),

    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "connector_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Connector ID" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">
        {extractFirstId(row.getValue("connector_id"))}
      </div>
    ),

    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "payment_method",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Payment Method" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("payment_method")}</div>
    ),

    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue("status")
      );

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon
              size={20}
              className={`mr-2 h-4 w-4 ${
                status.value === "Failed"
                  ? "animate-pulse text-red-500/80"
                  : status.value === "Completed"
                  ? "text-green-500/80"
                  : "text-yellow-500/80"
              }`}
            />
          )}
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "transaction_start_time",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Start Time" />
    ),
    cell: ({ row }) => (
      <div className="truncate">
        {formatDateShort(row.getValue("transaction_start_time"))}
      </div>
    ),

    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "transaction_end_time",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="End Time" />
    ),
    cell: ({ row }) => (
      <div className="truncate">
        {formatDateShort(row.getValue("transaction_end_time"))}
      </div>
    ),

    enableSorting: true,
    enableHiding: false,
  },

  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];
