import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithReauth } from "../lib/helpers/redux-base-query";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "UserProfile",
    "ChargePoints",
    "ChargeGroups",
    "organizations",
    "TeamMembers",
    "Invites",
    "payments",
    "TariffPlans",
    "Cards",
    "AccessProfiles",
    "CardHolders",
    "Limits",
    "CardSessions",
  ],
  endpoints: () => ({}),
});
