import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils/cn";
import { type Dispatch, type SetStateAction, useState } from "react";

import { Button } from "./ui/button";

interface Properties {
  id: string;
  onDelete: (setModal: Dispatch<SetStateAction<boolean>>) => void;
  children: React.ReactNode;
  title: string;
  warningText?: string;
  buttonText?: string;
  className?: string;
  buttonDisabled: boolean;
}
export const DeletePrompt: React.FC<Properties> = ({
  id,
  onDelete,
  children,
  title,
  warningText,
  buttonText,
  className,
  buttonDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)} className={cn(className)}>
        {children}
      </div>

      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-w-md p-4">
          <DialogHeader>
            <DialogTitle className="text-center leading-8">{title}</DialogTitle>
            <DialogDescription className="text-center text-red-400">
              {warningText}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={buttonDisabled}
              variant="destructive"
              onClick={() => onDelete(setIsModalOpen)}
            >
              {buttonText ? buttonText : "Continue"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
