import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useSendInvitationsMutation } from "@/redux/features/org/org.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowUpRight, Link, PlusCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

import type { MembersDetailsType } from "../../types";
import { InviteMemberDetails } from "./invite-member-details";
import { InviteLink } from "./modals/invite-link.modal";
import { TeamMembers } from "./team-members";

export const Members = () => {
  const [memberDetails, setMemberDetails] = useState([{}]);
  const [pendingInvitations, setPendingInvitations] = useState<
    MembersDetailsType[]
  >([]);

  const { orgId } = useAppSelector((state) => state.auth);

  const [
    sendInvitationsMutation,
    {
      isLoading: sendInviteLoading,
      error: sendInviteError,
      isSuccess: sendInviteSuccess,
    },
  ] = useSendInvitationsMutation();

  const MembersSchema = z.object({
    user_email: z.string().email({ message: "Invalid email address." }),
    role: z.string().min(2, { message: "Please select a role." }),
  });

  const membersDetailsForm = useForm<z.infer<typeof MembersSchema>>({
    resolver: zodResolver(MembersSchema),
  });

  const handleAddMore = () => {
    setMemberDetails((previousDetails) => [...previousDetails, {}]);
  };

  const handleRemove = (indexToRemove: number) => {
    setMemberDetails((previousDetails) =>
      previousDetails.filter((_, index) => index !== indexToRemove),
    );
  };

  const handleUpdate = (
    index: number,
    data: { user_email: string; role: string },
  ) => {
    setMemberDetails((previousDetails) =>
      previousDetails.map((item, index_) => (index_ === index ? data : item)),
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await sendInvitationsMutation({
        frontend_url: window.location.origin + "/acceptInvitation?referrer=",
        orgId: orgId,
        invites: memberDetails,
      }).unwrap();

      response.messages.map((item: any) => {
        toast.info(item.message);
      });
      setMemberDetails([{}]);
    } catch (error) {
      toast.error("Error inviting members");
      console.error("catch error:", error);
    }
  };

  return (
    <div className="text-white">
      <div>
        <h1 className="my-1 text-lg font-semibold">Members</h1>
        <p className="text-xs font-light text-gray-400">
          Manage and invite your organization Members.
        </p>
      </div>
      <Form {...membersDetailsForm}>
        <form
          onSubmit={membersDetailsForm.handleSubmit(handleSubmit)}
          className="my-8 rounded-lg border-[.3px] border-gray-500 bg-primary"
        >
          <div className="p-4">
            <div className="flex items-center justify-between py-3">
              <p className="text-sm font-normal">
                Invite new members by email address
              </p>
              <InviteLink>
                <Button type="button" variant="outline" className="flex gap-2">
                  <Link size={16} /> Invite Link
                </Button>
              </InviteLink>
            </div>
            <hr className="border-b-0 border-l-0 border-r-0 border-t-[1px] border-gray-500" />
            <div>
              {memberDetails.map((_, index) => (
                <InviteMemberDetails
                  key={index}
                  index={index}
                  onRemove={handleRemove}
                  onUpdate={handleUpdate}
                  form={membersDetailsForm}
                />
              ))}
            </div>
            <div
              onClick={() => {
                if (memberDetails.length < 3) return;
                else
                  toast("Sorry, you cannot invite more than 3 members at once");
              }}
            >
              <Button
                variant="outline"
                type="button"
                className="flex gap-2"
                onClick={handleAddMore}
                disabled={memberDetails.length > 2}
              >
                <PlusCircle />
                Add more
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-between border-t-[.3px] border-gray-500 p-4">
            <span className="flex items-center gap-1 text-blue-400">
              Learn more <ArrowUpRight size={16} />{" "}
            </span>
            <Button disabled={sendInviteLoading} variant="secondary">
              {" "}
              Invite
            </Button>
          </div>
        </form>
      </Form>

      <TeamMembers />
    </div>
  );
};
