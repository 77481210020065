export const lineChartOptions = (currency: string) => ({
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 1,
      blur: 10,
      opacity: 0.5,
      color: ["#FED127", "#FD9A27"],
    },
  },
  colors: ["#FD9A27", "#FED127"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 1,
    strokeOpacity: 0.2,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 1,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
    width: 3,
    colors: ["#FD9A27", "#FED127"],
    butt: "90",
  },
  xaxis: {
    type: "category",
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
      formatter: (value: number) => {
        // Format values as "5k," "6k," "1m," "1b," etc.
        const abbreviations = ["", "k", "m", "b"];
        let newValue = value;
        let suffixIndex = 0;

        while (newValue >= 1000 && suffixIndex < abbreviations.length - 1) {
          newValue /= 1000;
          suffixIndex++;
        }
        return currency + newValue.toFixed(1) + abbreviations[suffixIndex];
      },
    },
  },
  legend: {
    show: true,
  },
  grid: {
    borderColor: "#89898933",
    strokeDashArray: 10,
    show: true,
    row: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.5,
    },
    column: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.2,
    },
  },
  color: ["#FD9A27", "#FED127"],
});

export const chargerGroupsOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    type: "category",
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
      formatter: (value: number) => {
        // Format values as "5k," "6k," "1m," "1b," etc.
        if (value === Number.POSITIVE_INFINITY || value < 1) return 0;
        const abbreviations = ["", "k", "m", "b"];
        let newValue = value;
        let suffixIndex = 0;

        while (newValue >= 1000 && suffixIndex < abbreviations.length - 1) {
          newValue /= 1000;
          suffixIndex++;
        }

        return newValue.toFixed(1) + abbreviations[suffixIndex];
      },
    },
  },
  grid: {
    borderColor: "#89898933",
    strokeDashArray: 10,
    show: true,
    row: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.1,
    },
    column: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.1,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#FED127",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#FD9A27",
            opacity: 0.5,
          },
        ],
      ],
    },
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: "25px",
      margin: "5px",
    },
  },
};
export const barChartOptionsDailyTraffic = (categories: string[]) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: categories,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
        formatter: (value: number) => {
          // Format values as "5k," "6k," "1m," "1b," etc.
          if (value === Number.POSITIVE_INFINITY || value < 1) return 0;
          const abbreviations = ["", "k", "m", "b"];
          let newValue = value;
          let suffixIndex = 0;

          while (newValue >= 1000 && suffixIndex < abbreviations.length - 1) {
            newValue /= 1000;
            suffixIndex++;
          }

          return newValue.toFixed(1) + abbreviations[suffixIndex];
        },
      },
    },
    grid: {
      borderColor: "#89898933",
      strokeDashArray: 10,
      show: true,
      row: {
        color: ["#FD9A27", "#FED127"],
        opacity: 0.1,
      },
      column: {
        color: ["#FD9A27", "#FED127"],
        opacity: 0.1,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#FED127",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#FD9A27",
              opacity: 0.5,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "25px",
        margin: "5px",
      },
    },
  };
};

export const barChartOptionsDailyGroupContribution = (categories: string[]) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: categories,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
        formatter: (value: number) => {
          // Format values as "5k," "6k," "1m," "1b," etc.
          const abbreviations = ["", "k", "m", "b"];
          let newValue = value;
          let suffixIndex = 0;

          while (newValue >= 1000 && suffixIndex < abbreviations.length - 1) {
            newValue /= 1000;
            suffixIndex++;
          }

          return newValue.toFixed(1) + abbreviations[suffixIndex];
        },
      },
    },
    grid: {
      borderColor: "#89898933",
      strokeDashArray: 10,
      show: true,
      row: {
        color: ["#FD9A27", "#FED127"],
        opacity: 0.1,
      },
      column: {
        color: ["#FD9A27", "#FED127"],
        opacity: 0.1,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#FED127",
              opacity: 1,
            },
            {
              offset: 100,
              color: "#FD9A27",
              opacity: 0.5,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "25px",
        margin: "5px",
      },
    },
  };
};

export const donutChartOptions = (labels: string[]) => ({
  // labels: labels,
  colors: ["#FD9A27", "#FED127"],
  chart: {
    width: "500px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true, // Enable expanding on click
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "65%",
        background: "transparent",
        labels: {
          show: false,
          name: {
            show: false,
            fontSize: "92px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
            formatter: (value: any) => value,
          },
          value: {
            show: false,
            fontSize: "96px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: undefined,
            offsetY: 16,
            formatter: (value: any) => value,
          },
          total: {
            show: false,
            showAlways: false,
            label: "Total",
            fontSize: "92px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "#373d3f",
            formatter: (w: any) =>
              w.globals.seriesTotals.reduce((a: number, b: number) => {
                return a + b;
              }, 0),
          },
        },
      },
    },
  },
  fill: {
    type: "gradient", // Use gradient fill
    gradient: {
      shade: "dark", // Darken the gradient
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#FFA500", "#FFD700"], // Gradient color stops
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 100], // Gradient stops at 0%, 50%, 100%
    },
  },
  dropShadow: {
    // Apply a drop shadow
    enabled: true,
    top: 0,
    left: 0,
    blur: 5,
    opacity: 0.2,
  },
  tooltip: {
    enabled: false,
    theme: "light",
  },
});

export const rangeChartOptions = {
  chart: {
    height: 350,
    type: "rangeBar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },

  xaxis: {
    // categories: categories,
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "10px",
        fontWeight: "100",
      },
    },
  },
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "16px",
    fontFamily: "Helvetica, Arial",
    fontWeight: 100,
    formatter: (seriesName: string, options: any) =>
      options.w.config.series[options.seriesIndex].data[0].x +
      ": " +
      options.w.globals.series[options.seriesIndex],
    inverseOrder: false,
    width: undefined,
    height: 20,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: "#fff",
      useSeriesColors: false,
    },
    markers: {
      width: 10,
      height: 10,
      strokeWidth: 0,
      strokeColor: "#FD9A27",
      fillColors: undefined,
      radius: 50,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 5,
      vertical: 0,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
};

export const barChartDataConsumption = [
  {
    name: "PRODUCT A",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT B",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
  {
    name: "PRODUCT C",
    data: [400, 370, 330, 390, 320, 350, 360, 320, 380],
  },
];

export const barChartOptionsConsumption = (categories: string[]) => ({
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: categories,
    show: false,
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "14px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "black",
    labels: {
      show: true,
      style: {
        colors: "#A3AED0",
        fontSize: "10px",
        fontWeight: "100",
      },
      formatter: (value: number) => value + " kw/h",
    },
  },

  grid: {
    borderColor: "rgba(163, 174, 208, 0.3)",
    show: true,
    yaxis: {
      lines: {
        show: false,
        opacity: 0.5,
      },
    },
    row: {
      opacity: 0.5,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#FFA500", "#FFD700", "#E1E9F8"],
  },
  legend: {
    show: false,
  },
  colors: ["#FFA500", "#FFD700", "#E1E9F8"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      columnWidth: "20px",
    },
  },
});

export const smoothChart = (categories: string[]) => ({
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.5,
      color: "#FED127",
    },
  },
  colors: ["#FD9A27", "#FED127"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#FED127",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "numeric",
    categories: categories,
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "8px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "8px",
        fontWeight: "500",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.5,
    },
  },
  color: ["#FD9A27", "#FED127"],
});

export const areaChartOptions = {
  stroke: {
    curve: "smooth",
    type: "line",
    width: 1.5,
    // colors: ["#FD9A27", "#FED127", "#ccc"],
    butt: "90",
  },

  tooltip: {
    theme: "dark",
  },
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  grid: {
    borderColor: "#89898933",
    strokeDashArray: 10,
    show: true,
    row: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.1,
    },
    column: {
      color: ["#FD9A27", "#FED127"],
      opacity: 0.1,
    },
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },

  legend: {
    show: true,
  },
};
export const totalChargersPieChartOptions = () => {
  return {
    labels: ["Online", "Charging", "Offline", "Failure"],
    colors: ["#333ea2", "#6beba4", "#fed127", "#f52525"],
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.5,
      color: "#FED127",
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 0.5,
      dashArray: 50,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "16px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 100,
      formatter: (seriesName: string, options: any) => {
        options.w.config.dataLabels.style.colors = ["#000"];
        return (
          seriesName + ": " + options.w.globals.series[options.seriesIndex]
        );
      },
      inverseOrder: false,
      width: undefined,
      height: 20,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: "#fff",
        useSeriesColors: false,
      },
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: "#FD9A27",
        fillColors: undefined,
        radius: 50,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    // stroke: {
    // 	show: true,
    // 	// curve: "smooth",
    // 	// lineCap: "round",
    // 	colors: ['#ccc', '#ddd'],
    // 	width: 0.5,
    // 	dashArray: 99,
    // },

    dataLabels: {
      textAnchor: "left",
      style: {
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "light",
        colors: ["#333ea2", "#6beba4", "#fed127", "#f52525"],
      },
    },
  };
};
