import type React from "react";
import { useEffect, useState } from "react";

type Properties = {
  children: React.ReactNode;
};

const Client = ({ children }: Properties) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <>{children}</>;
};

export default Client;
