import { Checkbox } from "@/components/ui/checkbox";

import type { MembersDetailsType } from "../../types";
import { PendingMemberCardAction } from "./pending-member-card-action";

type Property = {
  data: MembersDetailsType;
};
export const PendingMemberCard = ({ data }: Property) => {
  return (
    <div
      className={`flex items-center justify-between border-b-[1px] border-gray-600 p-4`}
    >
      <div className="flex items-center gap-3">
        <Checkbox className="border-white" />
        <div className="flex items-center gap-1">
          <img src="/images/user-image.png" />
          <div>
            <h3 className="text-sm">{data.invited_user}</h3>
            <p className="text-xs text-gray-500">{data.invited_user}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-sm">{data.role}</span>

        {data.expired ? (
          <span className="flex rounded-full bg-red-700 px-2 py-1 text-xs italic text-red-200 opacity-70 duration-100">
            Expired
          </span>
        ) : (
          <span className="flex rounded-full bg-[#999871] bg-opacity-20 px-2 py-1 text-xs italic text-[#ccce97] opacity-70 duration-100">
            Pending
          </span>
        )}

        {/* {!data.accepted && (
          <span className=" opacity-70 italic bg-opacity-20 bg-[#999871] text-[#ccce97] flex rounded-full py-1 px-2 duration-100 text-xs">
            Pending
          </span>
        )} */}
        <PendingMemberCardAction data={data} />
      </div>
    </div>
  );
};
