import { Input } from "@/components/ui/input";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import {
  CardHolderResponse,
  useGetSessionsByUidQuery,
} from "@/redux/features/access-control/access-control.api";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import type React from "react";
import { useEffect, useState } from "react";
import { columns } from "./session-history-table/column";
import { SessionHistoryDataTable } from "./session-history-table/data-table";

export const SessionHistoryTableDetail = ({
  userData,
}: {
  userData: CardHolderResponse;
}) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchPage, setSearchPage] = useState(page);

  const { data, isLoading } = useGetSessionsByUidQuery({
    uid: userData?.id,
    page,
  });
  console.log(data);
  useEffect(() => {
    const itemsPerPage = 10;
    const totalPages = Math.ceil(
      data?.data?.results?.length || 0 / itemsPerPage
    );
    setTotalPages(totalPages);
  }, [data]);

  const goToFirstPage = () => {
    setPage(1);
    setSearchPage(1);
  };

  const goToLastPage = () => {
    setPage(totalPages);
    setSearchPage(totalPages);
  };
  const goToNextPage = () => {
    setPage(page + 1);
    setSearchPage(page + 1);
  };
  const goToPreviousPage = () => {
    setPage(page - 1);
    setSearchPage(page - 1);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPage(Number.parseInt(e.target.value));
  };

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const pageNumber = searchPage;
    if (
      !Number.isNaN(pageNumber) &&
      pageNumber >= 1 &&
      pageNumber <= totalPages
    ) {
      setPage(pageNumber);
    }
  };

  return (
    <div className="container bg-black/50 rounded-lg border-[.5px] border-slate-400  p-2 text-white">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold mb-4">Session History</h2>
      </div>
      <SessionHistoryDataTable
        data={data?.data?.results || []}
        loading={isLoading}
        columns={columns}
      />

      {isLoading ? (
        <div className="my-2 flex justify-end">
          <div className="flex justify-end gap-2">
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
          </div>
        </div>
      ) : (
        <Pagination className="justify-end pr-6 pt-4">
          <PaginationContent>
            {data?.data?.previous && (
              <>
                <PaginationItem onClick={goToFirstPage}>
                  <PaginationLink
                    href="#"
                    aria-label="Go to last page"
                    size="default"
                    className="gap-1 pr-2.5"
                  >
                    <ChevronDoubleLeftIcon className="h-4 w-4" />
                    <span>First</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem onClick={goToPreviousPage}>
                  <PaginationPrevious href="#" size="sm" />
                </PaginationItem>
              </>
            )}
            <PaginationItem>
              <form onSubmit={handleSearchSubmit} className="flex items-center">
                <Input
                  type="number"
                  value={searchPage}
                  onChange={handleSearchChange}
                  placeholder="Go to page"
                  className="h-10 w-12"
                />
              </form>
            </PaginationItem>
            {data?.data?.next && (
              <>
                <PaginationItem onClick={goToNextPage}>
                  <PaginationNext href="#" size="sm" />
                </PaginationItem>
                <PaginationItem onClick={goToLastPage}>
                  <PaginationLink
                    href="#"
                    aria-label="Go to last page"
                    size="default"
                    className="gap-1 pr-2.5"
                  >
                    <span>Last</span>
                    <ChevronDoubleRightIcon className="h-4 w-4" />
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
          </PaginationContent>
        </Pagination>
      )}
    </div>
  );
};
