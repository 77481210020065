import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import { formatDateShort } from "@/lib/helpers/dates";
import type { ColumnDef } from "@tanstack/react-table";
import { Actions } from "./actions";
import type { RFIDCardType } from "./schema";

export const columns: ColumnDef<RFIDCardType>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "uid",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="UID" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("uid")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "card_type",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Type" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("card_type")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "card_status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("card_status")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },

  // {
  //   accessorKey: "access_profile",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader column={column} title="Access Profile" />
  //   ),
  //   cell: ({ row }) => (
  //     <div className="capitalize whitespace-nowrap text-[10px]">
  //       {row.getValue("access_profile")}
  //     </div>
  //   ),
  //   enableSorting: false,
  //   enableHiding: false,
  //   filterFn: (row, id, value) => {
  //     return value.includes(row.getValue(id));
  //   },
  // },
  {
    accessorKey: "expiry_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Expiration Date" />
    ),
    cell: ({ row }) => (
      <div className="capitalize text-[10px]">
        {formatDateShort(row.getValue("expiry_date"))}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];
