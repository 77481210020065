import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UserAvatar } from "@/components/user-avatar";
import { useEditUserProfileMutation } from "@/redux/features/auth/auth.api";
import { editUser, startLoading } from "@/redux/features/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import type { UserType } from "@/types/user";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { ChangePassword } from "./change-password";
import DeleteAccount from "./delete-account";

const editProfileFormSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .max(100, { message: "Email address is too long" })
    .optional(),
  first_name: z
    .string()
    .max(50, { message: "First name is too long" })
    .optional(),
  last_name: z
    .string()
    .max(50, { message: "Last name is too long" })
    .optional(),
});

export const Account = () => {
  const { user: data } = useAppSelector(
    (state): { user: UserType | null; isLoading: boolean; error: any } =>
      state.auth,
  );
  const dispatch = useAppDispatch();
  const [
    editUserProfileMutation,
    { isLoading: editUserLoading, error: editUserError },
  ] = useEditUserProfileMutation();
  const [editPersonalProfile, setEditPersonalProfile] =
    useState<boolean>(false);
  const inputReference = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editEmail, setEditEmail] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const editProfileForm = useForm<z.infer<typeof editProfileFormSchema>>({
    resolver: zodResolver(editProfileFormSchema),
  });

  const handleEditPersonalProfile = async (value: {
    email?: string | undefined;
    first_name?: string | undefined;
    last_name?: string | undefined;
  }) => {
    try {
      const response = await editUserProfileMutation(value).unwrap();
      if (editUserLoading) {
        dispatch(startLoading());
      } else if (response) {
        dispatch(
          editUser({
            ...data,
            ...response.updated_user,
          }),
        );
      }
      setEditPersonalProfile(false);
    } catch (error) {
      console.error("catch error:", error);
    }
  };

  const handleEditEmailInput = {
    enableInput: () => {
      if (data?.email !== undefined) {
        setEditEmail(data?.email);
      }
      setIsEditing(true);
      setTimeout(() => {
        inputReference.current?.focus();
        inputReference.current?.setSelectionRange(
          0,
          inputReference.current.value.length,
        );
      }, 0);
    },
    disableInput: () => {
      setIsEditing(false);
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleEditEmailInput.disableInput();
      }
    },
    onChange: (value: string) => {
      setEditEmail(value);
    },
  };

  return (
    <div className="space-y-4">
      <div>
        <h2 className="text-3xl font-bold text-white">Account Settings</h2>
        <p className="text-sm text-white">
          View information about your account, download an archive of your data,
          or learn about account deactivation options.
        </p>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div>
            <UserAvatar
              firstName={data?.first_name}
              lastName={data?.last_name}
            />
          </div>
          <div>
            <div className="text-sm">
              {data?.first_name} {data?.last_name}
            </div>
            <div className="text-xs font-light text-muted">{data?.email}</div>
          </div>
        </div>
        <Button
          onClick={() => setEditPersonalProfile((previous) => !previous)}
          variant="secondary"
        >
          {editPersonalProfile ? "Close" : "Edit personal profile"}
        </Button>
      </div>
      {editPersonalProfile && (
        <Form {...editProfileForm}>
          <form
            onSubmit={editProfileForm.handleSubmit(handleEditPersonalProfile!)}
          >
            <hr className="my-2 mt-6 border-[.2px] border-slate-600" />
            <div className="text-white">
              <div className="flex flex-col gap-3">
                <div className="flex gap-4">
                  <FormField
                    control={editProfileForm.control}
                    name="first_name"
                    render={({ field }) => (
                      <FormItem className="flex-1 space-y-2">
                        <FormLabel>First name</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder={data?.first_name}
                            className="rounded-lg border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                            {...field}
                          />
                        </FormControl>

                        {editUserError?.data?.error_message.first_name && (
                          <FormMessage className="text-[10px]">
                            {editUserError?.data?.error_message.first_name}
                          </FormMessage>
                        )}
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={editProfileForm.control}
                    name="last_name"
                    render={({ field }) => (
                      <FormItem className="flex-1 space-y-2">
                        <FormLabel>Last name</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder={data?.last_name}
                            className="rounded-lg border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                            {...field}
                          />
                        </FormControl>
                        {editUserError?.data?.error_message.last_name && (
                          <FormMessage className="text-[10px]">
                            {editUserError?.data?.error_message.last_name}
                          </FormMessage>
                        )}
                      </FormItem>
                    )}
                  />
                </div>

                {/* {isEditing || !data?.email ? (
									<FormField
										control={editProfileForm.control}
										name="email"
										render={({ field }) => (
											<FormItem className="flex-1 space-y-2">
												<FormLabel>Email</FormLabel>
												<FormControl>
													<Input
														ref={inputRef}
														type="email"
														placeholder={data?.email}
														onClick={handleEditEmailInput.enableInput}
														onBlur={handleEditEmailInput.disableInput}
														onKeyDown={handleEditEmailInput.onKeyDown}
														onChange={field.onChange}
														value={field.value}
														className="outline-0 bg-transparent bg-opacity-40 rounded-lg border-opacity-10 border-[.3px] focus-visible:ring-transparent"
													/>
												</FormControl>
												{editUserError?.data?.error_message.email && (
													<FormMessage className="text-[10px]">
														{editUserError?.data?.error_message.email}
													</FormMessage>
												)}
											</FormItem>
										)}
									/>
								) : (
									<div>
										<Label>Email</Label>
										<Popover>
											<PopoverTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className="font-normal h-auto mt-2 px-3 py-2 w-full items-start justify-start border-[.3px] border-gray-600 "
												>
													{data?.email}
												</Button>
											</PopoverTrigger>
											<PopoverContent className="w-80 ">
												<div className="space-y-4">
													<div>
														You will be logged out and have to confirm your new
														email address
													</div>
													<Button
														onClick={handleEditEmailInput.enableInput}
														variant="secondary"
													>
														Continue
													</Button>
												</div>
											</PopoverContent>
										</Popover>
									</div>
								)} */}

                <Button disabled={editUserLoading} className="w-max bg-black">
                  Update Account
                </Button>
              </div>
            </div>
          </form>
        </Form>
      )}
      <ChangePassword />
      <DeleteAccount />
    </div>
  );
};
