"use client";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import {
  useLazyResendVerificationQuery,
  useVerifyOtpMutation,
} from "@/redux/features/auth/auth.api";
import { useAppDispatch } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

type Properties = {};

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

export const VerifyEmail = (properties: Properties) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const email = queryParameters.get("email") || "";

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: "",
    },
  });

  const [otpVerification, { isLoading: otpVerificationIsLoading }] =
    useVerifyOtpMutation();

  const [resendVerification, { isLoading: resendVerificationIsLoading }] =
    useLazyResendVerificationQuery();

  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    otpVerification({ otp_token: data.pin, email })
      .unwrap()
      .then((data) => {
        if (data.response_status === "success") {
          toast.success(data.message);
          navigate(`/login`);
        }
      })
      .catch((error: any) => {
        toast.error(error?.data?.error_message);
        const serverErrorMessage = error?.data?.error_message;
        const message = serverErrorMessage || "An unknown error occurred";
      });
  }

  function handleResendCode() {
    resendVerification({ email: email }).then(({ data }) => {
      if (data.response_status === "success") {
        setSecondsRemaining(60);
        setIsResendDisabled(true);
        form.reset();
        toast.success(data.message);
      }
    });
  }

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timerId = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsResendDisabled(false);
    }
  }, [secondsRemaining]);

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email, navigate]);

  return (
    <div className="flex h-full items-center justify-center bg-black">
      <div className="relative flex h-full items-center justify-center">
        <div className="flex flex-[.4] flex-col gap-4 p-5">
          <div>
            <div className="my-3 flex flex-col items-center justify-center gap-3">
              <img src="/images/logo-text.png" height={50} width={150} />
            </div>
            <div className="flex items-center gap-2">
              <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
              <span className="mx-4 w-max whitespace-nowrap text-center text-sm font-light text-white">
                Enter OTP
              </span>
              <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
            </div>
          </div>
          <p className="text-center text-xs font-light text-slate-200">
            Check your email for the OTP and enter it below.
          </p>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="pin"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <InputOTP
                        onComplete={form.handleSubmit(onSubmit)}
                        maxLength={6}
                        disabled={
                          resendVerificationIsLoading ||
                          otpVerificationIsLoading
                        }
                        {...field}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={0}
                          />
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={1}
                          />
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={2}
                          />
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={3}
                          />
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={4}
                          />
                          <InputOTPSlot
                            className="h-16 w-16 text-5xl"
                            index={5}
                          />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex w-full flex-col items-center space-y-4">
                <div className="w-full text-center">
                  {secondsRemaining > 0 ? (
                    <span>
                      Resend code in{" "}
                      <span className="font-bold text-yellow-500">
                        {`00:${
                          secondsRemaining < 10 ? "0" : ""
                        }${secondsRemaining}`}
                      </span>
                    </span>
                  ) : (
                    <Button
                      type="button"
                      variant="outline"
                      className="mx-auto w-full max-w-[60%] border-[.1px] border-[#eab308]"
                      onClick={() => handleResendCode()}
                      disabled={
                        isResendDisabled ||
                        resendVerificationIsLoading ||
                        otpVerificationIsLoading
                      }
                    >
                      Resend Code
                    </Button>
                  )}
                </div>

                <Button
                  disabled={
                    resendVerificationIsLoading || otpVerificationIsLoading
                  }
                  className="mx-auto w-full max-w-[60%] bg-[#eab308] text-black hover:bg-[#eab308]/60"
                >
                  Verify
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};
