import { barChartOptionsDailyTraffic } from "@/common/variables/charts";
import { BarChart } from "@/components/ui/bar-chart";
import { DateRangePicker } from "@/components/ui/date-range";
import { Skeleton } from "@/components/ui/skeleton";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { useGetWeeklySessionsQuery } from "@/redux/features/home/home.api";
import {
  setWeeklySessions,
  startLoading,
} from "@/redux/features/home/home.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";

export const Traffic = () => {
  const [date, setDate] = useState<DateRange | any>({
    from: subDays(new Date(), 6),
    to: new Date(),
  });
  const { orgId } = useAppSelector((state) => state.auth);
  const { weeklySessions, loading } = useAppSelector((state) => state.home);

  const dispatch = useAppDispatch();
  const { data: weeklySessionsData, isLoading: weeklySessionsLoading } =
    useGetWeeklySessionsQuery(
      {
        org_id: orgId,
        start_date: formatDateToYYYYMMDD(date.from),
      },
      { skip: !orgId },
    );

  const [data, setData] = useState<number[]>();
  const [dayDatesArray, setDayDatesArray] = useState<string[]>([]);

  const [dataSum, setDataSum] = useState<number>(0);

  function getDayOfWeekInRange(start: any, end: any) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const result = [];
    for (
      let currentDate = startDate;
      currentDate <= endDate;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const dayIndex = currentDate.getDay();
      result.push(dayOfWeek[dayIndex]);
    }

    setDayDatesArray(result);
    return result;
  }

  useEffect(() => {
    if (weeklySessionsLoading) {
      dispatch(startLoading());
    } else if (weeklySessionsData) {
      getDayOfWeekInRange(date.from, date.to);

      dispatch(setWeeklySessions(weeklySessionsData.data));

      const newData: any[] =
        weeklySessions &&
        weeklySessions.charging_sessions_per_day.map((object: any) => {
          return Object.values(object)[0];
        });

      setData(newData);
    }
  }, [date, weeklySessions, weeklySessionsData, dispatch]);

  return (
    <div className="relative h-full overflow-hidden rounded-2xl bg-gradient-linear backdrop-blur-xl">
      {loading ? (
        <div className="flex w-full flex-col items-start gap-3 p-6">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[160px]" />
            <Skeleton className="h-10 w-[180px]" />
          </div>
          <div className="w-full">
            <Skeleton className="h-[230px] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="font-extralight text-[#D1D1D1]">
                Avg. Sessions Last Week
              </h3>
            </div>
            <div>
              <DateRangePicker onDateChange={setDate} />
            </div>
          </div>
          <div className="relative min-h-[260px]">
            <BarChart
              chartData={[
                {
                  name: "Average Weekly Sessions",
                  data: data ?? [],
                },
              ]}
              chartOptions={barChartOptionsDailyTraffic(dayDatesArray)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
