import { Checkbox } from "@/components/ui/checkbox";
import { useAppSelector } from "@/redux/hooks";

import { ManageRole } from "./manage-role";
import { MeMemberCardAction } from "./me-member-card-action";
import { OthersMemberCardAction } from "./others-member-card-action";

export const MembersCard = (properties: {
  userId: string;
  memberId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: any;
}) => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div className="flex items-center justify-between border-b-[1px] border-gray-600 p-4">
      <div className="flex items-center gap-3">
        <Checkbox className="border-white" />
        <div className="flex items-center gap-1">
          <img src="/images/user-image.png" />
          <div>
            <h3 className="text-sm">{`${properties.firstName} ${properties.lastName}`}</h3>
            <p className="text-xs text-gray-500">{properties.email}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-sm">
          {properties.role ? (
            <p>{properties.role.org_role_name}</p>
          ) : (
            <p>Member</p>
          )}
        </span>
        <ManageRole>Manage Role</ManageRole>

        {user?.id === properties.userId ? (
          <MeMemberCardAction />
        ) : (
          <OthersMemberCardAction
            memberId={properties.memberId}
            userId={properties.userId}
          />
        )}
      </div>
    </div>
  );
};
