import { DeltaType } from "@/common/constants/enums";
import { OverviewCard } from "@/components/overview-card";
import { isValidDeltaType } from "@/lib/helpers/delta-type";
import { useAppSelector } from "@/redux/hooks";
import type { TotalRevenueType } from "@/types/home";

type Properties = {
  data: TotalRevenueType;
};

export const TotalRevenueCard = ({ data }: Properties) => {
  const { globalCurrency } = useAppSelector((state) => state.home);

  return (
    <OverviewCard
      data={[1, 0]}
      title="Today’s Revenue"
      metric={globalCurrency + data.today_total_revenue}
      deltaValue={data.percentageChange}
      deltaType={
        isValidDeltaType(data.deltaType) ? DeltaType[data.deltaType] : "default"
      }
      dayTime="yesterday"
      className="!w-full"
    />
  );
};
