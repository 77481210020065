import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Cross2Icon } from "@radix-ui/react-icons";
import type { Table } from "@tanstack/react-table";

interface ChargerTableToolbarProperties<TData> {
  table: Table<TData>;
}

export function ChargerTableToolbar<TData>({
  table,
}: ChargerTableToolbarProperties<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <Input
        placeholder="Filter charger name..."
        value={
          (table.getColumn("charge_point_id")?.getFilterValue() as string) ?? ""
        }
        onChange={(event) =>
          table.getColumn("charge_point_id")?.setFilterValue(event.target.value)
        }
        className="h-auto max-w-sm px-3 py-2 text-xs"
      />

      <div className="flex flex-1 items-center space-x-2">
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
