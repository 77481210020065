import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useResetPasswordMutation } from "@/redux/features/auth/auth.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOff } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import * as z from "zod";

type Properties = {};
const createNewPasswordSchema = z
  .object({
    password: z
      .string()
      .min(1, { message: "Please enter a password" })
      .min(8, { message: "Passwords must be at least 8 characters long" })
      .refine(
        (value) => /[a-z]/.test(value),
        "Password must contain at least one lowercase letter",
      )
      .refine(
        (value) => /[A-Z]/.test(value),
        "Password must contain at least one uppercase letter",
      )
      .refine(
        (value) => /\d/.test(value),
        "Password must contain at least one number",
      )
      .refine(
        (value) => /[!$%&*?@]/.test(value),
        "Password must contain at least one special character",
      ),
    confirmPassword: z.string().min(1, { message: "Please confirm password" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const CreateNewPassword = (properties: Properties) => {
  const [passwordType, setPasswordType] = useState(true);
  const EyeComponent = passwordType ? EyeIcon : EyeOff;

  const [searchParameters, setSearchParameters] = useSearchParams();
  const navigate = useNavigate();

  const resetToken = searchParameters.get("reset_token") as string;
  const email = searchParameters.get("referrer") as string;

  useEffect(() => {
    if (!resetToken || !email) {
      navigate("/login");
    }
  }, []);

  const createNewPasswordForm = useForm<
    z.infer<typeof createNewPasswordSchema>
  >({
    resolver: zodResolver(createNewPasswordSchema),
  });

  const [resetPassword, { isLoading, error, data }] =
    useResetPasswordMutation();

  const handleSubmit = async (values: { password: string }) => {
    const info = {
      email: email,
      reset_token: resetToken,
      new_password: values.password,
    };

    try {
      const response = await resetPassword(info)
        .unwrap()
        .then((data) => {
          if (data.response_status == "success") {
            createNewPasswordForm.reset();
            toast.success(data.message);
            navigate("/login");
          }
        });
    } catch (error: any) {
      if (error) {
        toast.error(error?.data.error_message);
      }
    }
  };

  return (
    <div className="flex h-full items-center justify-center bg-black">
      <div className="w-full max-w-[600px] space-y-6">
        <div className="space-y-2">
          <div className="my-3 flex flex-col items-center justify-center gap-3">
            <img src="/images/logo-text.png" height={50} width={150} />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
            <span className="mx-2 w-max whitespace-nowrap text-center text-sm font-light text-white">
              Create new password
            </span>
            <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
          </div>

          <p className="mx-auto max-w-[350px] text-center text-xs font-light text-slate-200">
            Enter your registered email address below & we will send an email
            with instructions to reset your password.
          </p>
        </div>

        <Form {...createNewPasswordForm}>
          <form
            onSubmit={createNewPasswordForm.handleSubmit(handleSubmit)}
            className="flex w-full flex-col gap-6"
          >
            <div className="space-y-3">
              <FormField
                name="password"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <div className="relative transition-all duration-150">
                        <Input
                          type={passwordType ? "password" : "text"}
                          placeholder="Password"
                          className=""
                          {...field}
                        />
                        <EyeComponent
                          className="absolute bottom-3 right-2 z-10 cursor-pointer"
                          size={16}
                          onClick={() => {
                            setPasswordType((previous) => !previous);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <div className="relative transition-all duration-150">
                        <Input
                          type={passwordType ? "password" : "text"}
                          placeholder="Confirm Password"
                          className=""
                          {...field}
                        />
                        <EyeComponent
                          className="absolute bottom-3 right-2 z-10 cursor-pointer"
                          size={16}
                          onClick={() => {
                            setPasswordType((previous) => !previous);
                          }}
                        />
                      </div>
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex w-full flex-col items-center gap-4">
              <Button
                disabled={isLoading}
                className="w-full bg-[#eab308] text-black hover:bg-[#eab308]/60 md:w-[60%]"
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default CreateNewPassword;
