import { InfiniteMovingCards } from "@/components/ui/infinite-moving-cards";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  useGetChargeGroupGlobalQuery,
  useGetChargeGroupsQuery,
  useGetChargersUtilizationRateQuery,
} from "@/redux/features/charger-network/charger-network.api";
import {
  incrementGlobalChargerGroupsPage,
  setChargeGroups,
  setChargerUtilizationRate,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { ChartCircle } from "iconsax-react";
import type React from "react";
import { useEffect, useState } from "react";

interface ChargerUtilizationData {
  chargerId: string;
  utilization: number;
}

export const ChargerUtilization: React.FC = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const globalChargeGroupsPage = useAppSelector(
    (state) => state.chargerNetwork.globalChargerGroups.page,
  );

  const dispatch = useAppDispatch();
  const {
    data: { chargeGroups: groups, chargerUtilizationRate },
    loading,
  } = useAppSelector((state) => state.chargerNetwork);

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [chargerData, setChargerData] = useState<any>();

  const {
    data: groupsData,
    isFetching,
    isError,
    isLoading,
  } = useGetChargeGroupsQuery({ orgId: orgId, page: globalChargeGroupsPage });

  const globalChargeGroups = useGetChargeGroupGlobalQuery({
    orgId: orgId,
    page: globalChargeGroupsPage,
  });

  const {
    data: utilizationData,
    isFetching: utilizationFetching,
    isError: utilizationError,
    isLoading: utilizationIsLoading,
  } = useGetChargersUtilizationRateQuery({
    org_id: orgId,
    group_id: selectedGroup,
  });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (groupsData) {
      dispatch(setChargeGroups(groupsData));
    }
    if (utilizationFetching) {
      dispatch(startLoading());
    } else if (utilizationData) {
      dispatch(setChargerUtilizationRate(utilizationData));
    }
  }, [dispatch, isFetching, utilizationFetching, utilizationData, groupsData]);

  const handleGroupChange = (value: string) => {
    setSelectedGroup(value);
  };

  const loadMoreData = (data: any) => {
    if (data.next) {
      dispatch(incrementGlobalChargerGroupsPage());
    }
  };

  return (
    <div className="relative mb-6 flex h-full flex-1 flex-col justify-between rounded-lg bg-gradient-linear p-4 text-white shadow-2xl backdrop-blur-xl">
      <div className="flex items-center justify-between gap-4">
        <h2 className="text-lg">Chargers Utilization</h2>
        <div className="flex-[.5]">
          <Select onValueChange={handleGroupChange}>
            <SelectTrigger className="flex-[.2]">
              <SelectValue placeholder="All groups" />
            </SelectTrigger>
            <SelectContent>
              {globalChargeGroups.isLoading ? (
                <div className="flex animate-spin justify-center py-2">
                  <ChartCircle size="20" color="#FF8A65" />
                </div>
              ) : globalChargeGroups.data &&
                Object.keys(globalChargeGroups.data).length > 0 ? (
                <div>
                  {globalChargeGroups.data.results.map((group: any) => (
                    <SelectItem value={group.id} key={group.name}>
                      {group.name}
                    </SelectItem>
                  ))}
                  {globalChargeGroups.data.results.length > 0 &&
                    !globalChargeGroups.isFetching &&
                    globalChargeGroups.data.next && (
                      <button
                        className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                        onClick={() => loadMoreData(globalChargeGroups.data)}
                      >
                        Load more
                      </button>
                    )}

                  {globalChargeGroups.isFetching && (
                    <div className="flex animate-spin justify-center py-2">
                      <ChartCircle size="20" color="#FF8A65" />
                    </div>
                  )}
                </div>
              ) : (
                <div>No Charge groups found</div>
              )}
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="dark:bg-grid-white/[0.05] relative flex flex-col items-center justify-center overflow-hidden rounded-md antialiased">
        {loading ? (
          <p>loading</p>
        ) : chargerData ? (
          <InfiniteMovingCards
            speed="slow"
            items={chargerData}
            direction="right"
          />
        ) : (
          <p>No Data to display</p>
        )}
      </div>
    </div>
  );
};
