import { useAuth } from "@/lib/hooks/use-auth";
import { useNavigate } from "react-router-dom";

export const Auth = ({ children }: { children: React.ReactNode }) => {
  const { authenticated } = useAuth();
  const navigate = useNavigate();

  if (authenticated) {
    navigate("/");
    return;
  }
  return (
    !authenticated && (
      <div className="h-full w-full bg-black bg-gradient-auth-bg">
        {children}
      </div>
    )
  );
};
