import { Navbar } from "@/components/navbar";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetTafiffPlansQuery } from "@/redux/features/tariff/tariff.api";
import {
  setTariffPlans,
  startLoading,
} from "@/redux/features/tariff/tariff.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

import { TariffGroupsTable } from "./components/group-table/group-tables";
import { columns } from "./components/tariff-plan/column";
import { TariffPlanDataTable } from "./components/tariff-plan/tariff-plan-data-table";

export const TariffPlanTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {
    data: { tariffPlans },
    loading,
  } = useAppSelector((state) => state.tariff);

  const { data, isFetching } = useGetTafiffPlansQuery({ orgId, page });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setTariffPlans(data));
    }
  }, [dispatch, isFetching, data]);

  return (
    <div className="relative flex flex-col gap-9">
      <div className=" px-0">
        <Navbar title={"Tariff Management"} />
      </div>
      <div className=" rounded-lg border border-slate-400 bg-black p-6 text-white">
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Your tariff plans.</h2>
          <p className="text-lg">Here's a list of your current Tariff plans!</p>
        </div>

        <TariffPlanDataTable
          data={tariffPlans ? tariffPlans.results : []}
          columns={columns}
          loading={loading}
        />

        {loading ? (
          <div className="my-2 flex justify-end">
            <div className="flex justify-end gap-2">
              <Skeleton className="h-6 w-12 rounded-sm" />
              <Skeleton className="h-6 w-12 rounded-sm" />
              <Skeleton className="h-6 w-12 rounded-sm" />
            </div>
          </div>
        ) : (
          tariffPlans && (
            <Pagination className="justify-end pr-6 pt-4">
              <PaginationContent>
                {tariffPlans.previous && (
                  <PaginationItem onClick={() => setPage(page - 1)}>
                    <PaginationPrevious href="#" size={undefined} />
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink href="#" isActive size={undefined}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
                {tariffPlans.next && (
                  <PaginationItem onClick={() => setPage(page + 1)}>
                    <PaginationNext href="#" size={undefined} />
                  </PaginationItem>
                )}
              </PaginationContent>
            </Pagination>
          )
        )}
      </div>
      <TariffGroupsTable />
    </div>
  );
};

export default TariffPlanTable;
