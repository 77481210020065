import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils/cn";
import type React from "react";

interface Properties {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
  className?: string;
}

export const ZoomButton: React.FC<Properties> = ({
  onClick,
  children,
  className,
}) => (
  <Button
    onClick={onClick}
    // variant="secondary"
    className={cn("relative z-10", className)}
  >
    {children}
  </Button>
);
