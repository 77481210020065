import { useGetTransactionRecordsByIdQuery } from "@/redux/features/revenue/revenue.api";
import { AnimatePresence, motion } from "framer-motion";
import { AlertCircle, Loader2, Printer } from "lucide-react";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { useEffect, useState } from "react";

import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { ChargerDetails } from "./charger-details";
import { CostDetails } from "./cost-details";
import { DriverDetails } from "./driver-details";
import { SessionDetails } from "./session-details-card";

interface TransactionsDataType {
  charging_session_detail: {
    charger_id: string;
    connector_id: number;
    duration: string;
    electricity_usage: number;
    initiated_by: string;
    session_id: string;
    transaction_status: string;
    transaction_timeline: string;
  };
  charger_usage_details: {
    charger_group_name: string;
    charger_id: string;
    charger_name: string;
    firmware_version: string;
    protocol: string;
    vendor_model: string;
  };
  cost_detials: {
    cost: number;
    payment_method: string;
    payment_status: string;
    transaction_reference_number: string;
  };
  vehicle_driver_details: {
    contact_information: string;
    driver_id: string;
    driver_name: string;
    vehicle_description: string;
  };
}

export default function ViewMoreTransactionDetails({
  id,
  onClose,
}: {
  id: string;
  onClose?: (() => void) | undefined;
}) {
  const [isOpen, setIsOpen] = useState(!!id);
  const [details, setDetails] = useState<TransactionsDataType>();

  const { data, isFetching, isError } = useGetTransactionRecordsByIdQuery({
    transaction_id: id,
  });

  useEffect(() => {
    if (data && data.status === "success" && data.data) {
      setDetails(data.data);
    }
  }, [data]);

  async function generateAndPrintPDF(data) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const { width, height } = page.getSize();

    // Colors and fonts
    const blackColor = rgb(0, 0, 0);
    const yellowColor = rgb(1, 0.84, 0);
    const grayColor = rgb(0.3, 0.3, 0.3);
    const whiteColor = rgb(1, 1, 1);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const fontRegular = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Fetch and embed logo
    const logoUrl = `${window.location.origin}/images/logo.png`;
    const logoImageBytes = await fetch(logoUrl).then((res) =>
      res.arrayBuffer()
    );
    const logoImage = await pdfDoc.embedPng(logoImageBytes);
    const logoDims = logoImage.scale(1);

    // Background
    page.drawRectangle({
      x: 0,
      y: 0,
      width,
      height,
      color: blackColor,
    });

    // Header with logo and brand name
    const headerY = height - 90;
    page.drawImage(logoImage, {
      x: 50,
      y: headerY,
      width: logoDims.width,
      height: logoDims.height,
    });
    page.drawText("GridFlow", {
      x: 120,
      y: headerY + 30,
      size: 28,
      font: fontBold,
      color: yellowColor,
    });
    page.drawText("Transaction Report", {
      x: 120,
      y: headerY,
      size: 14,
      font: fontRegular,
      color: whiteColor,
    });

    // Enhanced Section Layout
    let yPosition = height - 150;
    const addSection = (title, content) => {
      // Section Title
      page.drawText(title.toUpperCase(), {
        x: 50,
        y: yPosition,
        size: 16,
        font: fontBold,
        color: yellowColor,
      });
      yPosition -= 8;

      // Section Divider
      page.drawRectangle({
        x: 50,
        y: yPosition,
        width: 500,
        height: 1,
        color: yellowColor,
      });
      yPosition -= 20;

      // Section Content
      for (const [key, value] of Object.entries(content)) {
        const formattedKey = key.replace(/_/g, " ");
        page.drawText(`${formattedKey}:`, {
          x: 60,
          y: yPosition,
          size: 12,
          font: fontBold,
          color: grayColor,
        });
        page.drawText(`${value}`, {
          x: 220,
          y: yPosition,
          size: 12,
          font: fontRegular,
          color: whiteColor,
        });
        yPosition -= 18;
      }
      yPosition -= 25; // Add extra space before next section
    };

    // Add sections
    addSection("Charging Session Details", data.charging_session_detail);
    addSection("Charger Usage Details", data.charger_usage_details);
    addSection("Vehicle Driver Details", data.vehicle_driver_details);
    addSection("Cost Details", data.cost_detials);

    // Footer
    page.drawRectangle({
      x: 0,
      y: 0,
      width,
      height: 40,
      color: yellowColor,
    });
    page.drawText("Generated by GridFlow", {
      x: 50,
      y: 20,
      size: 10,
      font: fontRegular,
      color: blackColor,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    const newWindow = window.open(blobUrl);

    // Print the PDF
    if (newWindow) {
      newWindow.addEventListener("load", () => {
        newWindow.print();
      });
    } else {
      console.error("Failed to open new window for PDF.");
    }
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          modal
          open={isOpen}
          onOpenChange={(open) => {
            setIsOpen(open);
            if (!open && onClose) {
              onClose();
            }
          }}
        >
          <DialogContent className="max-h-[90vh] gap-0 max-w-lg overflow-hidden border-0 bg-background p-0 text-foreground shadow-2xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <DialogHeader className="sticky top-0 z-10 bg-background px-6 py-4 shadow-md">
                <DialogTitle className="flex items-center justify-between">
                  <h2 className="text-2xl font-bold">Transaction Details</h2>

                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => generateAndPrintPDF(details)}
                    className="hidden print:hidden sm:flex"
                  >
                    <Printer className="mr-2 h-4 w-4" />
                    Print
                  </Button>
                </DialogTitle>
              </DialogHeader>
              <div className="max-h-[calc(90vh-80px)] overflow-y-auto p-3">
                {isFetching ? (
                  <div className="flex h-64 items-center justify-center">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  </div>
                ) : isError ? (
                  <AlertDialog>
                    <AlertCircle className="h-4 w-4" />
                    <AlertDialogTitle>Error</AlertDialogTitle>
                    <AlertDialogDescription>
                      Failed to load transaction details. Please try again.
                    </AlertDialogDescription>
                  </AlertDialog>
                ) : details ? (
                  <Tabs
                    defaultValue="session"
                    className=" space-y-2 bg-transparent"
                  >
                    <TabsList className="flex bg-slate-700">
                      <TabsTrigger
                        className="h-full w-full flex-1 font-bold"
                        value="session"
                      >
                        Session
                      </TabsTrigger>
                      <TabsTrigger
                        className="h-full w-full flex-1  font-bold"
                        value="charger"
                      >
                        Charger
                      </TabsTrigger>
                      <TabsTrigger
                        className="h-full w-full flex-1  font-bold"
                        value="cost"
                      >
                        Cost
                      </TabsTrigger>
                      <TabsTrigger
                        className="h-full w-full flex-1 font-bold"
                        value="driver"
                      >
                        Driver
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="session">
                      <SessionDetails data={details.charging_session_detail} />
                    </TabsContent>
                    <TabsContent value="charger">
                      <ChargerDetails data={details.charger_usage_details} />
                    </TabsContent>
                    <TabsContent value="cost">
                      <CostDetails data={details.cost_detials} />
                    </TabsContent>
                    <TabsContent value="driver">
                      <DriverDetails data={details.vehicle_driver_details} />
                    </TabsContent>
                  </Tabs>
                ) : (
                  <AlertDialog>
                    <AlertCircle className="h-4 w-4" />
                    <AlertDialogTitle>No Data</AlertDialogTitle>
                    <AlertDialogDescription>
                      No transaction details available for this ID.
                    </AlertDialogDescription>
                  </AlertDialog>
                )}
              </div>
            </motion.div>
            <DialogFooter className=" p-3">
              <DialogClose asChild>
                <Button
                  variant="outline"
                  className="w-full bg-gray-700 text-gray-200 hover:bg-gray-600"
                >
                  Close
                </Button>
              </DialogClose>{" "}
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </AnimatePresence>
  );
}
