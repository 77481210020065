import { Navbar } from "@/components/navbar";

import { useGetChargerLocationsQuery } from "@/redux/features/home/home.api";
import { setChargerLocations } from "@/redux/features/home/home.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect } from "react";
import { ChargerLocations } from "../Home/components/charger-locations";
import ChargerStatus from "./components/charger-status";
import { ChargePointSetupInstructions } from "./components/charger-table/charge-point-setup-instruction";
import { ChargerTabs } from "./components/charger-tabs";
import { ChargerUtilization } from "./components/charger-utilization";

export default function ChargerNetwork() {
  const dispatch = useAppDispatch();
  const { chargerLocations } = useAppSelector((state) => state.home);
  const { orgId } = useAppSelector((state) => state.auth);
  const {
    data: chargerLocationsData,
    // isLoading,
  } = useGetChargerLocationsQuery(orgId, { skip: !orgId });

  useEffect(() => {
    if (chargerLocationsData) {
      dispatch(setChargerLocations(chargerLocationsData.data));
    }
  }, [chargerLocationsData, dispatch]);

  return (
    <>
      <Navbar title="Charger Network" />
      <div className="flex flex-col gap-5 xl:flex-row">
        <div className="my-2 flex w-full gap-4">
          <ChargerStatus />
          <ChargerUtilization />
        </div>
      </div>

      <ChargerTabs />
      {chargerLocations && <ChargerLocations data={chargerLocations} />}

      <ChargePointSetupInstructions />
    </>
  );
}
