import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const routeTitles = {
      "/": "Home",
      "/revenue": "Revenue",
      "/revenue/settlements": "Revenue Settlements",
      "/settings": "Settings",
      "/acceptInvitation": "Accept Invite",
      "/tariff-management": "Tariff Management",
      "/access-control": "Access Control",
      "/login": "Login",
      "/signup": "Signup",
      "/forgot-password/check-email": "Check Email",
      "/create-new-password": "Create New Password",
      "/organisations": "Organisations",
      "/signup/verify-email": "Verify Email",
      "/charger-network": "Charger Network",
    };
    const currentTitle =
      `| ${routeTitles[location.pathname as keyof typeof routeTitles]}` || "";
    document.title = `Gridflow Core ${currentTitle}`;
  }, [location]);
};

export default usePageTitle;
