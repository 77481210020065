"use client";

import { AddressAutoComplete } from "@/components/address-auto-complete";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Skeleton } from "@/components/ui/skeleton";
import { Switch } from "@/components/ui/switch";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { cn } from "@/lib/utils/cn";
import {
  useCreateCardHolderMutation,
  useEditCardHolderMutation,
} from "@/redux/features/access-control/access-control.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import { CalendarIcon, Plus } from "lucide-react";
import { Suspense, useCallback, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { toast } from "sonner";
import * as z from "zod";
import type { CardHoldersType } from "./schema";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  name: z
    .string({ required_error: "Name cannot be empty" })
    .min(1, { message: "Name cannot be empty" }),
  expiry_date: z.date().optional().nullable(),
  phone_number: z
    .string()
    .regex(/^\+?[1-9]\d{1,14}$/, { message: "Invalid phone number" }),
  address: z
    .string()
    .min(5, { message: "Address must be at least 5 characters" }),
  role: z.string().min(1, { message: "Role is required" }),
  hasExpiration: z.boolean().default(false),
});

export const CreateCardHolders = ({
  data,
  onClose,
}: {
  data?: CardHoldersType;
  onClose?: (() => void) | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(!!data);
  const { orgId } = useAppSelector((state) => state.auth);

  const defaultValues = data
    ? {
        email: data?.email,
        name: data?.name,
        expiry_date: new Date(data?.expiry_date),
        phone_number: data?.phone_number,
        address: data?.address,
        role: data?.role,
        hasExpiration: !!data?.expiry_date,
      }
    : {
        email: "",
        name: "",
        expiry_date: null,
        phone_number: "",
        address: "",
        role: "",
        hasExpiration: false,
      };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  const [createCardHolder, { isLoading: createCardHolderLoading }] =
    useCreateCardHolderMutation();
  const [editCardHolder, { isLoading: editCardHolderLoading }] =
    useEditCardHolderMutation();

  const handleSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      const { hasExpiration, ...rest } = values;
      const result = {
        ...rest,
        organization_id: orgId,
        expiry_date: values?.expiry_date
          ? formatDateToYYYYMMDD(values?.expiry_date)
          : null,
      };

      if (data) {
        if (isDirty) {
          await editCardHolder({ ...result, id: data.id })
            .unwrap()
            .then((response) => {
              form.reset();
              toast.success(response.message);
              setIsOpen(false);
            })
            .catch((error) => {
              toast.error(error?.data?.error_message);
            });
        } else {
          toast.error("Please edit card holder before saving.");
          return;
        }
      } else {
        await createCardHolder(result)
          .unwrap()
          .then((response) => {
            form.reset();
            toast.success(response.message);
            setIsOpen(false);
          })
          .catch((error) => {
            toast.error(error?.data?.error_message);
          });
      }
    },
    [createCardHolder, data, editCardHolder, form, isDirty, orgId]
  );

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4" />
          Add Card Holder
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Add Card Holder</SheetTitle>
          <SheetDescription>
            Enter the details for the new card holder. Click save when you're
            done.
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4 py-4"
          >
            <AnimatePresence>
              {["name", "email", "role"].map((field) => (
                <motion.div
                  key={field}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <FormField
                    control={form.control}
                    name={field as keyof z.infer<typeof formSchema>}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {field.name.charAt(0).toUpperCase() +
                            field.name.slice(1).replace("_", " ")}
                        </FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            type={field.name === "email" ? "email" : "text"}
                            value={field.value ? field.value.toString() : ""}
                            className="bg-gray-800 border-gray-700 text-gray-100"
                          />
                        </FormControl>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />
                </motion.div>
              ))}
              <FormField
                control={form.control}
                name="phone_number"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className="text-base">Phone Number</FormLabel>
                    <FormControl>
                      <PhoneInput
                        defaultCountry="hk"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        inputClassName="p-10 w-full !bg-gray-800 border !border-gray-700 !border-l-0 !rounded-r-md !text-white !font-medium !py-2 !text-sm"
                        countrySelectorStyleProps={{
                          buttonClassName:
                            "!py-2 !px-3 border-gray-700 bg-gray-600 rounded-l-md border-r-0",
                        }}
                      />
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem className="relative w-full">
                    <FormLabel>Address</FormLabel>
                    <FormControl>
                      <div className=" w-full">
                        <Suspense fallback={<Skeleton />}>
                          <AddressAutoComplete
                            onSelectAddress={(address) => {
                              form.setValue("address", address);
                            }}
                            defaultValue={field.value}
                          />
                        </Suspense>
                      </div>
                    </FormControl>

                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="hasExpiration"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4 bg-gray-800 border-gray-700 text-gray-100">
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Card Holder Expiration
                      </FormLabel>
                      <FormDescription>
                        Set whether this card holder should have an expiration
                        date.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {form.watch("hasExpiration") && (
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <FormField
                    control={form.control}
                    name="expiry_date"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel>Expiry Date (Optional)</FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-full pl-3 text-left font-normal bg-gray-800 border-gray-700 text-gray-100",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  format(field.value, "PPP")
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <CalendarIcon className="ml-auto h-4 w-4 opacity-50 text-white" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              mode="single"
                              selected={
                                field.value ? new Date(field.value) : undefined
                              }
                              onSelect={field.onChange}
                              initialFocus
                              disabled={(date) => date < new Date()}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <SheetFooter>
              <SheetClose asChild>
                <Button variant="outline" type="button">
                  Cancel
                </Button>
              </SheetClose>
              <Button
                disabled={createCardHolderLoading || editCardHolderLoading}
                variant="secondary"
                type="submit"
              >
                Save changes
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};
