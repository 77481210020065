import { Toaster } from "@/components/ui/sonner";
import { ToastProvider } from "@/components/ui/toast.tsx";
import { TooltipProvider } from "@/components/ui/tooltip";
import { AuthProvider } from "@/store/auth/providers";
import GlobalProvider from "@/store/global/providers";
import OrganisationProvider from "@/store/organisation/providers";
import RevenueProvider from "@/store/revenue/providers";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { store } from "../redux/store";
import Client from "./client";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <Client>
      <Provider store={store}>
        <Router>
          <TooltipProvider>
            <Toaster className="text-white" />
            <ToastProvider>
              <GlobalProvider>
                <AuthProvider>
                  <OrganisationProvider>
                    <RevenueProvider>{children}</RevenueProvider>
                  </OrganisationProvider>
                </AuthProvider>
              </GlobalProvider>
            </ToastProvider>
          </TooltipProvider>
        </Router>
      </Provider>
    </Client>
  );
};

export default Providers;
