import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils/cn";
import { addDays, format, subDays } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";

// Define a custom prop type that includes onDateChange
interface DateRangePickerProperties
  extends React.HTMLAttributes<HTMLDivElement> {
  onDateChange: (date: string) => void;
}

export function DateRangePicker({
  className,
  onDateChange,
}: DateRangePickerProperties) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [date, setDate] = React.useState<any>({
    from: subDays(new Date(), 6),
    to: new Date(),
  });

  React.useEffect(() => onDateChange(date), [date, onDateChange]);

  const handleSelectDate = (selectedDate: Date | undefined) => {
    // Automatically select the next 7 dates after the selected date
    const endDate = addDays(selectedDate!, 6);

    setDate({ from: selectedDate, to: endDate });
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              cn(
                "w-max justify-start whitespace-nowrap text-left font-normal outline-none",
                !date && "text-muted-foreground",
                "border-none bg-[#29292C] outline-none",
              ),
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            <div className="text-xs">
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "dd LLL")} -{" "}
                    {format(date.to, "dd LLL y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            className={cn("border-none bg-[#29292C] text-white", "border-none")}
            initialFocus
            mode="single"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(e) => handleSelectDate(e)}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
