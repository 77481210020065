import { ArrowUpRight, Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { CreateAccessProfile } from "@/pages/(dashboard)/AccessControl/components/card-holders-and-access-profile-tab/access-profile-table/create-access-profile";
import { useGetAccessProfilesQuery } from "@/redux/features/access-control/access-control.api";
import { ChartCircle } from "iconsax-react";
import { Button } from "../../../../../../../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../../../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../../components/ui/popover";

interface AccessProfileType {
  id: string;
  name: string;
}

export function AccessProfileSelect() {
  const [page, setPage] = useState(1);
  const [accessProfileDataResult, setAccessProfileDataResult] = useState<
    AccessProfileType[]
  >([]);
  const { data, isFetching } = useGetAccessProfilesQuery(page);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data?.results) {
      setAccessProfileDataResult((prevProfile) => [
        ...prevProfile,
        ...data.results.filter(
          (newProfile) =>
            !prevProfile.some((profile) => profile.id === newProfile.id)
        ),
      ]);
    }
  }, [data]);

  const filteredProfile = accessProfileDataResult?.filter(
    (profile: AccessProfileType) =>
      profile.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const loadMoreData = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <FormField
      name="access_profile"
      render={({ field }) => {
        useEffect(() => {
          if (field.value) {
            setValue(field.value);
          }
        }, [field.value]);
        return (
          <FormItem>
            <FormLabel className=" whitespace-nowrap">Access Profile</FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between bg-gray-800 border-gray-700 text-gray-100"
                    disabled={isFetching}
                  >
                    {value
                      ? accessProfileDataResult.find(
                          (profile) => profile.id === value
                        )?.name
                      : "Select Access Profile"}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[450px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search charger access profile..."
                    value={searchQuery}
                    onInput={(e) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <div className="relative p-2 flex gap-2 ">
                    <CreateAccessProfile />
                    <Button
                      type="button"
                      variant="outline"
                      className=" w-full bg-gray-800 whitespace-nowrap text-gray-200 gap-1 border-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      View Profiles
                      <ArrowUpRight className="mr-2 h-4 w-4" />
                    </Button>
                  </div>

                  <CommandList>
                    {isFetching ? (
                      <div className="flex justify-center py-2">
                        <ChartCircle
                          className="animate-spin"
                          size={20}
                          color="#FF8A65"
                        />
                      </div>
                    ) : filteredProfile?.length > 0 ? (
                      <>
                        <CommandGroup className="text-white">
                          {filteredProfile.map((profile: AccessProfileType) => (
                            <CommandItem
                              key={profile.id}
                              value={profile.id}
                              onSelect={(currentValue) => {
                                setValue(
                                  currentValue === value ? "" : currentValue
                                );
                                setOpen(false);
                                field.onChange(currentValue);
                              }}
                            >
                              <Check
                                className={
                                  value === profile.id
                                    ? "mr-2 h-4 w-4 opacity-100"
                                    : "mr-2 h-4 w-4 opacity-0"
                                }
                              />
                              {profile.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <CommandEmpty>
                          No Charger Access Profile found.
                        </CommandEmpty>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage className="text-right text-xs" />
          </FormItem>
        );
      }}
    />
  );
}
