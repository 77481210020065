import { AddressAutoComplete } from "@/components/address-auto-complete";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { getImageSizeFromDataLink } from "@/lib/helpers/get-image-size";
import { getImageType } from "@/lib/helpers/get-image-type";
import { useCreateNewOrganisationMutation } from "@/redux/features/org/org.api";
import { setOrganisations } from "@/redux/features/org/org.slice";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { useState } from "react";
import { Plus } from "react-feather";
import { useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { toast } from "sonner";
import * as z from "zod";
import { ProfileImage } from "./profile-image";
import "react-international-phone/style.css";

const formSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .email({ message: "Invalid email address" })
    .max(100, { message: "Email address is too long" }),
  name: z
    .string({ required_error: "Name cannot be empty" })
    .max(50, { message: "Name is too long" }),
  phone_number: z
    .string({ required_error: "Phone number is required" })
    .min(10, { message: "Please enter a valid phone number" }),
  address: z
    .string()
    .max(250, { message: "Address length is too long." })
    .min(2, { message: "Please enter your organisation address." }),
  lat: z.number(),
  lng: z.number(),
  profile_picture: z.any().optional(),
  profile_picture_preview: z
    .any()
    .refine(
      (files) => {
        const fileSize = getImageSizeFromDataLink(files);
        return fileSize <= 5;
      },
      { message: "Max image size is 5MB." }
    )
    .refine((files) => {
      const allowedTypes = ["jpeg", "jpg", "png", "webp"];
      const extractedType = getImageType(files);
      return allowedTypes.includes(extractedType);
    }, "Only .jpg, .jpeg, .png and .webp formats are supported.")
    .optional(),
});

const steps = [
  {
    id: "Step 0",
    fields: [
      "org_name",
      "org_email",
      "org_phone_number",
      "address",
      "lat",
      "lng",
    ],
  },
  {
    id: "Step 1",
    fields: ["profile_picture", "profile_picture_preview"],
  },
];

export const CreateOrganisation = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>();
  const totalSteps = steps.length;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const [createNewOrganisation, { isLoading, error, data }] =
    useCreateNewOrganisationMutation();

  const stepValidationFields: Array<Array<keyof z.infer<typeof formSchema>>> = [
    ["name", "email", "phone_number", "address", "lat", "lng"],
    ["profile_picture", "profile_picture_preview"],
  ];

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    const form_data = new FormData();

    form_data.append("org_email", values.email);
    form_data.append("org_phone_number", values.phone_number);
    form_data.append("org_name", values.name);
    form_data.append("org_address", values.address);
    form_data.append("org_latitude", String(values.lat));
    form_data.append("org_longitude", String(values.lng));
    if (values.profile_picture)
      form_data.append("org_profile_picture", values.profile_picture);

    createNewOrganisation(form_data)
      .unwrap()
      .then((data) => {
        if (data.response_status === "success" && data.data) {
          setCurrentStep(0);
          form.reset();
          toast.success(data.message);
          setOrganisations(data.data);
          setIsOpen(false);
        }
      })
      .catch((error) => {
        if (error) {
          toast.error(error?.data.error_message);
        }
      });
  };

  const nextStep = async () => {
    const fieldsToValidate = stepValidationFields[currentStep];
    const isValid = await form.trigger(fieldsToValidate);
    if (!isValid) return;

    if (currentStep < totalSteps - 1)
      setCurrentStep(currentStep < totalSteps ? currentStep + 1 : currentStep);
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Dialog modal open={isOpen}>
      <div
        className="relative bg-gray-400 bg-opacity-25 py-4 opacity-40 duration-150 active:bg-gray-900/75"
        onClick={() => setIsOpen(true)}
        onKeyUp={() => setIsOpen(true)}
        onKeyDown={() => setIsOpen(true)}
        onKeyPress={() => setIsOpen(true)}
      >
        <div className="mx-4 flex max-w-[340px] cursor-pointer items-start space-x-3 py-2 duration-200">
          <span className="py-1">
            <Plus />
          </span>
          <div className="flex flex-col gap-1">
            <h3 className="text-sm font-medium text-white">
              Create Organisation
            </h3>
            <p className="text-[10px] font-normal">
              Add and manage members by creating your own organisation
            </p>
          </div>
        </div>
      </div>

      <DialogContent className="border-none p-4 shadow-sm shadow-white/70 sm:max-w-[425px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Create organisation</DialogTitle>
          <Button
            variant="secondary"
            className="h-8 w-8 p-2"
            size="sm"
            onClick={() => setIsOpen(false)}
          >
            <X />
          </Button>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className="grid gap-4 py-4">
              {currentStep === 0 && (
                <>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <Input
                            placeholder="Name"
                            className="border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <Input
                            type="email"
                            placeholder="Email"
                            className="border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />

                  <div className="flex gap-4">
                    <FormField
                      control={form.control}
                      name="phone_number"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormControl>
                            <PhoneInput
                              defaultCountry="hk"
                              value={field.value}
                              onChange={(e) => field.onChange(e)}
                              inputClassName="p-10 w-full !bg-gray-800 border !border-gray-700 !border-l-0 !rounded-r-md !text-white !font-medium !py-2 !text-sm"
                              countrySelectorStyleProps={{
                                buttonClassName:
                                  "!py-2 !px-3 border-gray-700 bg-gray-600 rounded-l-md border-r-0",
                              }}
                            />
                          </FormControl>
                          <FormMessage className="text-[10px]" />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem className="relative w-full">
                        <FormControl>
                          <div className=" w-full">
                            <AddressAutoComplete
                              onSelectAddress={(
                                address,
                                latitude,
                                longitude
                              ) => {
                                form.setValue("address", address);
                                form.setValue("lat", latitude as number);
                                form.setValue("lng", longitude as number);
                              }}
                              defaultValue={field.value}
                            />
                          </div>
                        </FormControl>
                        <FormMessage className="text-[10px]" />
                      </FormItem>
                    )}
                  />
                  <div className="flex items-center justify-end gap-4">
                    <Button
                      disabled={currentStep === 0}
                      variant="outline"
                      onClick={previousStep}
                      type="button"
                      size="sm"
                    >
                      Back
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={nextStep}
                      type="button"
                      size="sm"
                    >
                      Continue
                    </Button>
                  </div>
                </>
              )}
              {currentStep === 1 && (
                <>
                  <ProfileImage form={form} />
                  <div className="flex items-center justify-end gap-4">
                    <Button
                      variant="outline"
                      onClick={previousStep}
                      type="button"
                      size="sm"
                    >
                      Back
                    </Button>

                    <Button
                      disabled={isLoading}
                      variant="secondary"
                      type="submit"
                      size="sm"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
