import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { RevenueContext } from "./context";
import type { RevenueContextPropType as RevenueContextPropertyType } from "./types/revenue";

const RevenueProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const handleGetTransactionRecords = useCallback(() => {}, []);

  const values: RevenueContextPropertyType = {};
  return (
    <RevenueContext.Provider value={values}>{children}</RevenueContext.Provider>
  );
};

export default RevenueProvider;
