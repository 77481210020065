import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useOrg } from "@/lib/hooks/use-organisation";
import { X } from "lucide-react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";

type Properties = { children: React.ReactNode };

export const InviteLink = ({ children }: Properties) => {
  const { handleInviteLink } = useOrg();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputReference = useRef<HTMLInputElement>(null);

  const handleCopyLink = async () => {
    if (inputReference.current) {
      try {
        await navigator.clipboard.writeText(inputReference.current.value);
        inputReference.current.select();
        toast("Link copied to clipboard!");
      } catch {
        toast("Failed to copy link to clipboard!");
      }
    }
  };

  useEffect(() => {
    handleInviteLink({ org_id: "4eb4d051a771428089b53903e334a700" });
  }, []);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)} className="cursor-pointer">
        {children}
      </div>

      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={() => setIsModalOpen(true)}
      >
        <DialogContent className="max-w-lg border bg-primary p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <h2>Invite Link</h2>
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
                type="button"
              >
                <X size={16} color="black" />
              </Button>
            </DialogTitle>
          </DialogHeader>

          <div className="relative">
            <Input
              ref={inputReference}
              className="py-6"
              value="https://core.dashboard.grdflo.com/invite/9J9sErd"
            />
            <Button
              variant="secondary"
              className="absolute bottom-[4.5px] right-1"
              onClick={handleCopyLink}
            >
              Copy link
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
