import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container flex h-full flex-col items-center justify-center gap-4 text-white">
      <div>
        <h2 className="my-6 text-center text-6xl font-bold">OOps!</h2>
        <p className="text-center">You are lost.</p>
      </div>
      <div className="flex w-full items-center justify-center">
        <img src="/images/--not-found.png" />
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <p>Hey Gridflow User! Looks like you are on the wrong page!</p>
        <Link
          to="/"
          className="flex gap-2 rounded-none border-b bg-transparent px-4 py-2"
        >
          <ArrowUturnLeftIcon className="h-4 w-8" />
          Go back home
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
