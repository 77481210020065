import { getGreeting } from "@/lib/helpers/greeting";

import { OrganisationsCard } from "./components/organisations-card";
import { organisations } from "./data/organisations";

function Organisations() {
  const greeting = getGreeting();

  return (
    <div className="container md:my-6">
      <div>
        <h1 className="text-4xl font-semibold text-white">
          👋 {greeting}, Kehlani
        </h1>
      </div>
      <div className="my-9">
        <p className="text-2xl">
          Your <span className="text-[#FD9A27]">Organisations</span>
        </p>

        <div className="xlg:grid-cols-4 my-3 grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {organisations.map((organisation) => (
            <OrganisationsCard key={organisation.id} data={organisation} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Organisations;
