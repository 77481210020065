import { api } from "@/redux/api";
import type { UserType } from "@/types/user";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: `core/users/login/`,
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),
    signup: builder.mutation({
      query: (values) => ({
        url: `core/users/sign-up/`,
        method: "POST",
        body: values,
      }),
    }),
    verifyOtp: builder.mutation({
      query: ({ email, otp_token }) => ({
        url: `core/users/verify-otp/?email=${email}`,
        method: "PATCH",
        body: {
          otp_token: otp_token,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (values) => ({
        url: "core/users/forgot-password/",
        method: "POST",
        body: values,
      }),
    }),
    resetPassword: builder.mutation({
      query: (values) => ({
        url: "core/users/reset-password/",
        method: "POST",
        body: values,
      }),
    }),

    deleteAccount: builder.mutation({
      query: () => ({
        url: "core/users/remove-account/",
        method: "DELETE",
      }),
    }),
    resendVerification: builder.query({
      query: ({ email }) => ({
        url: `core/users/resend-otp/?email=${email}`,
      }),
    }),
    getUserProfile: builder.query<UserType, void>({
      query: () => "/core/users/profile/",
      providesTags: ["UserProfile"],
    }),
    editUserProfile: builder.mutation({
      query: (updatedData) => ({
        url: `core/users/edit-profile/`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    getUserPrivateOrg: builder.query({
      query: () => "core/organization/user-private-org",
    }),

    changePassword: builder.mutation({
      query: ({ old_password, new_password }) => ({
        url: "core/users/change-password/",
        method: "PATCH",
        body: { old_password, new_password },
      }),
    }),
    logout: builder.mutation<void, void>({
      queryFn: () => {
        try {
          // Clear user data and tokens here
          localStorage.removeItem("authToken");
          // Clear any other data stored
          return { data: undefined };
        } catch {
          return { data: undefined, error: undefined };
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useGetUserProfileQuery,
  useEditUserProfileMutation,
  useGetUserPrivateOrgQuery,
  useChangePasswordMutation,
  useVerifyOtpMutation,
  useLazyResendVerificationQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useDeleteAccountMutation,
  useLogoutMutation,
} = authApi;
