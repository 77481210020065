import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useChangePasswordMutation } from "@/redux/features/auth/auth.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { EyeIcon, EyeOff, Lock } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

type Properties = {};

const ChangePasswordFormSchema = z
  .object({
    old_password: z.string().min(1, { message: "Old cannot be empty" }),
    new_password: z
      .string()
      .min(1, { message: "Please enter a password" })
      .min(8, { message: "Passwords must be at least 8 characters long" })
      .refine(
        (value) => /[a-z]/.test(value),
        "Password must contain at least one lowercase letter",
      )
      .refine(
        (value) => /[A-Z]/.test(value),
        "Password must contain at least one uppercase letter",
      )
      .refine(
        (value) => /\d/.test(value),
        "Password must contain at least one number",
      )
      .refine(
        (value) => /[!$%&*?@]/.test(value),
        "Password must contain at least one special character",
      ),
    confirm_new_password: z
      .string()
      .min(1, { message: "Please confirm password" }),
  })
  .refine((data) => data.new_password === data.confirm_new_password, {
    message: "Passwords don't match",
    path: ["confirm_new_password"],
  });

export const ChangePassword = (properties: Properties) => {
  const [changePasswordMutation, { isLoading, isError, isSuccess, error }] =
    useChangePasswordMutation();

  const [passwordType, setPasswordType] = useState(true);
  const EyeComponent = passwordType ? EyeIcon : EyeOff;

  const changePasswordForm = useForm<z.infer<typeof ChangePasswordFormSchema>>({
    resolver: zodResolver(ChangePasswordFormSchema),
  });

  const handleSubmit = async (values: {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
  }) => {
    const result = {
      old_password: values.old_password,
      new_password: values.new_password,
    };

    try {
      const changePasswordResult =
        await changePasswordMutation(result).unwrap();
      toast.success(changePasswordResult.message);
    } catch (error: any) {
      toast.error(error.data.error_message);
    }
  };

  return (
    <div className="py-10">
      <div className="flex items-center gap-4">
        <Lock color="white" size={24} />
        <h3 className="text-2xl font-semibold text-white">Change Password</h3>
      </div>
      <hr className="my-2 mt-6 border-[.2px] border-slate-600" />
      <Form {...changePasswordForm}>
        <form
          className="my-4 flex flex-col gap-6"
          onSubmit={changePasswordForm.handleSubmit(handleSubmit)}
        >
          <FormField
            control={changePasswordForm.control}
            name="old_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Old password</FormLabel>
                <FormControl>
                  <div className="group relative transition-all duration-150">
                    <Input
                      type={passwordType ? "password" : "text"}
                      placeholder="Old password"
                      className="rounded-lg border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                      {...field}
                    />

                    <EyeComponent
                      className="absolute bottom-3 right-2 z-10 hidden cursor-pointer group-hover:block"
                      size={16}
                      onClick={() => {
                        setPasswordType((previous) => !previous);
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage className="text-[10px]" />
              </FormItem>
            )}
          />

          <FormField
            control={changePasswordForm.control}
            name="new_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>New password</FormLabel>
                <FormControl>
                  <div className="group relative transition-all duration-150">
                    <Input
                      type={passwordType ? "password" : "text"}
                      placeholder="Old password"
                      className="rounded-lg border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                      {...field}
                    />

                    <EyeComponent
                      className="absolute bottom-3 right-2 z-10 hidden cursor-pointer group-hover:block"
                      size={16}
                      onClick={() => {
                        setPasswordType((previous) => !previous);
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage className="text-[10px]" />
              </FormItem>
            )}
          />

          <FormField
            control={changePasswordForm.control}
            name="confirm_new_password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Confirm New password</FormLabel>
                <FormControl>
                  <div className="group relative transition-all duration-150">
                    <Input
                      type={passwordType ? "password" : "text"}
                      placeholder="Old password"
                      className="rounded-lg border-[.3px] border-opacity-10 bg-transparent bg-opacity-40 outline-0"
                      {...field}
                    />

                    <EyeComponent
                      className="absolute bottom-3 right-2 z-10 hidden cursor-pointer group-hover:block"
                      size={16}
                      onClick={() => {
                        setPasswordType((previous) => !previous);
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage className="text-[10px]" />
              </FormItem>
            )}
          />

          <Button disabled={isLoading} className="w-max bg-black">
            Update Account
          </Button>
        </form>
      </Form>
    </div>
  );
};
