import axios, {
  type AxiosError,
  type AxiosInstance,
  type RawAxiosRequestHeaders,
} from "axios";
import { toast } from "sonner";

import cookieService from "../helpers/cookie-service";

const request: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CORE_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  } as RawAxiosRequestHeaders,
});

// Interceptor for request
request.interceptors.request.use(
  (config: any) => {
    const accessToken = cookieService.getCookie("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// Interceptor for response
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    // Handle errors globally
    if (error.response && error.response.status === 401) {
      toast("Not authorized ");
    } else if (error.message.includes("Network Error")) {
      toast("Please check your network connection");
    }
    return Promise.reject(error);
  },
);

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}

export default request;
