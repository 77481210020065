import { Navbar } from "@/components/navbar";
import { AccessControlMetrics } from "./components/access-control-metrics/access-control-metrics";
import { CardHoldersAndAccessProfileTab } from "./components/card-holders-and-access-profile-tab/card-holders-and-access-profile-tab";

export const AccessControl = () => {
  return (
    <>
      <Navbar title="Access Control" />
      <AccessControlMetrics />
      <CardHoldersAndAccessProfileTab />
    </>
  );
};
