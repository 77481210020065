import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AccessProfileResponse,
  useDisableCardMutation,
  useRevokeCardMutation,
} from "@/redux/features/access-control/access-control.api";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { CreateRFIDCard } from "./create-rfid-card";
import type { RFIDCardType } from "./schema";

interface Props<TData> {
  row: Row<TData>;
}

export function Actions({ row }: Props<RFIDCardType>) {
  const [isRevokedOpen, setIsRevokedOpen] = useState(false);
  const [data, setData] = useState<RFIDCardType | null>(null);

  const [disableCard, { isLoading: disableCardLoading }] =
    useDisableCardMutation();
  const [revokeCard, { isLoading: revokeCardLoading }] =
    useRevokeCardMutation();

  const handleDisableCard = useCallback(async () => {
    await disableCard(row?.original?.id)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
      })
      .catch((error) => {
        toast.error(error?.data?.error_message);
      });
  }, [disableCard, row?.original?.id]);

  const handleRevokeCard = useCallback(async () => {
    await revokeCard(row?.original?.id)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        setIsRevokedOpen(false);
      })
      .catch((error) => {
        toast.error(error?.data?.error_message);
      });
  }, [revokeCard, row?.original?.id]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          <DropdownMenuItem
            onClick={() => setData(row.original)}
            className="flex items-center justify-between gap-2"
          >
            Edit
          </DropdownMenuItem>

          {row.original.card_status === "active" ? (
            <DropdownMenuItem
              onClick={handleDisableCard}
              disabled={disableCardLoading}
              className="flex items-center justify-between gap-2"
            >
              Disable
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem className="flex items-center justify-between gap-2">
              Enable
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            onClick={() => setIsRevokedOpen(true)}
            className="flex items-center justify-between gap-2"
          >
            Revoke
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isRevokedOpen} onOpenChange={setIsRevokedOpen}>
        <DialogContent className="max-w-sm p-2 bg-background border border-[#525252]">
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently revoke this
              this card making it unable to access any of the chargers assigned
              to it.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" onClick={() => setIsRevokedOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
            <Button
              disabled={revokeCardLoading}
              variant="secondary"
              onClick={handleRevokeCard}
            >
              Revoke
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {data && (
        <CreateRFIDCard
          privateUserId={data?.private_drive_user?.id}
          data={data}
          onClose={() => setData(null)}
        />
      )}
    </>
  );
}
