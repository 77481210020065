import { totalChargersPieChartOptions } from "@/common/variables/charts";
import PieChart from "@/components/ui/pie-chart";
import type { TotalChargersType } from "@/types/home";

type ChargerData = {
  chargerCategoryName: string;
  value: number;
};

type Properties = {
  data: TotalChargersType;
};

export const TotalChargers = ({ data }: Properties) => {
  const totalChargers: ChargerData[] = [
    {
      chargerCategoryName: "Online",
      value: data.category_counts.online,
    },
    {
      chargerCategoryName: "Charging",
      value: data.category_counts.charging,
    },
    {
      chargerCategoryName: "Offline",
      value: data.category_counts.offline,
    },
    {
      chargerCategoryName: "Failure",
      value: data.category_counts.failure,
    },
  ];

  return (
    <div className="relative h-full overflow-hidden rounded-xl bg-gradient-linear pb-0 backdrop-blur-xl">
      {totalChargers && (
        <div className="flex flex-col items-start gap-7 p-6">
          <div className="font-extralight text-[#D1D1D1]">
            <h2>Total chargers </h2>
            <span className="text-2xl font-bold text-white">
              {data.charger_count}
            </span>
          </div>
          <div className="relative min-h-[350px] w-full flex-1 md:min-w-[300px]">
            <PieChart
              type="pie"
              chartData={totalChargers.map((charger) => charger.value)}
              chartOptions={totalChargersPieChartOptions}
            />
          </div>
        </div>
      )}
    </div>
  );
};
