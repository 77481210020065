"use client";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Slider } from "@/components/ui/slider";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { formatEnergy, formatTime } from "@/lib/helpers/dates";
import {
  useCreateRestrictionLimitMutation,
  useEditRestrictionLimitMutation,
} from "@/redux/features/access-control/access-control.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { Battery, ChevronDown, ChevronUp, Clock, Plus } from "lucide-react";
import { useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import type { RestrictionLimitType } from "./schema";

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const timeStringSchema = z
  .string()
  .regex(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format. Use HH:MM");

const formSchema = z.object({
  name: z
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be less than 50 characters"),
  description: z
    .string()
    .max(200, "Description must be less than 200 characters"),
  weekdays_allowed: z
    .object({
      monday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      tuesday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      wednesday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      thursday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      friday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      saturday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
      sunday: z
        .object({
          start_time: timeStringSchema,
          end_time: timeStringSchema,
        })
        .optional(),
    })
    .refine((data) => Object.keys(data).length > 0, {
      message: "At least one weekday must be selected",
    }),
  daily_max_time_limit_hr: z
    .number()
    .min(0, "Daily time limit must be positive")
    .max(24, "Daily time limit cannot exceed 24 hours"),
  weekly_max_time_limit_hr: z
    .number()
    .min(0, "Weekly time limit must be positive")
    .max(168, "Weekly time limit cannot exceed 168 hours"),
  monthly_max_time_limit_hr: z
    .number()
    .min(0, "Monthly time limit must be positive")
    .max(744, "Monthly time limit cannot exceed 744 hours"),
  all_time_max_time_limit_hr: z
    .number()
    .min(0, "All-time time limit must be positive"),
  daily_max_energy_limit_kwh: z
    .number()
    .min(0, "Daily energy limit must be positive"),
  weekly_max_energy_limit_kwh: z
    .number()
    .min(0, "Weekly energy limit must be positive"),
  monthly_max_energy_limit_kwh: z
    .number()
    .min(0, "Monthly energy limit must be positive"),
  all_time_max_energy_limit_kwh: z
    .number()
    .min(0, "All-time energy limit must be positive"),
});

export const CreateRestrictionLimit = ({
  data,
  onClose,
}: {
  data?: RestrictionLimitType;
  onClose?: (() => void) | undefined;
}) => {
  const { orgId } = useAppSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(!!data);

  const [createRestrictionLimit, { isLoading: createRestrictionLimitLoading }] =
    useCreateRestrictionLimitMutation();
  const [editRestrictionLimit, { isLoading: editRestrictionLimitLoading }] =
    useEditRestrictionLimitMutation();

  const defaultValues = data
    ? {
        name: data?.name,
        description: data?.description,
        weekdays_allowed: {
          ...data?.weekdays_allowed,
        },
        daily_max_time_limit_hr: data?.daily_max_time_limit_hr,
        weekly_max_time_limit_hr: data?.weekly_max_time_limit_hr,
        monthly_max_time_limit_hr: data?.monthly_max_time_limit_hr,
        all_time_max_time_limit_hr: data?.all_time_max_time_limit_hr,
        daily_max_energy_limit_kwh: data?.daily_max_energy_limit_kwh,
        weekly_max_energy_limit_kwh: data?.weekly_max_energy_limit_kwh,
        monthly_max_energy_limit_kwh: data?.monthly_max_energy_limit_kwh,
        all_time_max_energy_limit_kwh: data?.all_time_max_energy_limit_kwh,
      }
    : {
        name: "",
        description: "",
        weekdays_allowed: {},
        daily_max_time_limit_hr: 0,
        weekly_max_time_limit_hr: 0,
        monthly_max_time_limit_hr: 0,
        all_time_max_time_limit_hr: 0,
        daily_max_energy_limit_kwh: 0,
        weekly_max_energy_limit_kwh: 0,
        monthly_max_energy_limit_kwh: 0,
        all_time_max_energy_limit_kwh: 0,
      };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    if (data) {
      if (isDirty) {
        editRestrictionLimit({ ...values, id: data.id, organization_id: orgId })
          .unwrap()
          .then((response) => {
            form.reset();
            toast.success(response.message);
            setIsOpen(false);
          })
          .catch((error) => {
            toast.error(error?.data?.error_message);
          });
      } else {
        toast.error("Please edit restriction limit before saving.");
        return;
      }
    } else {
      createRestrictionLimit({ ...values, organization_id: orgId })
        .unwrap()
        .then((response) => {
          form.reset();
          toast.success(response.message);
          setIsOpen(false);
        })
        .catch((error) => {
          toast.error(error?.data?.error_message);
        });
    }
  }

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 whitespace-nowrap text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4 shrink-0" />
          Create New Restriction Limit
        </Button>
      </SheetTrigger>
      <SheetContent className="max-w-2xl p-6 bg-gray-900 bg-background text-gray-100 border-gray-700 overflow-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <SheetHeader>
              <SheetTitle>Add Restriction Limit</SheetTitle>
              <SheetDescription>
                Create a new restriction limit for your access profile. Be
                mindful of the limits you set.
              </SheetDescription>
            </SheetHeader>

            <div className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-gray-200">Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-gray-800 border-gray-700 text-gray-100"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="weekdays_allowed"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-gray-200">
                      Allowed Weekdays
                    </FormLabel>
                    <FormDescription className="text-gray-400">
                      Select weekdays and set allowed time ranges for each day.
                    </FormDescription>
                    <div className="space-y-2">
                      {weekdays.map((day) => (
                        <Collapsible key={day}>
                          <CollapsibleTrigger className="flex items-center justify-between w-full p-2 bg-gray-800 rounded-md hover:bg-gray-700">
                            <div className="flex items-center space-x-2">
                              <Checkbox
                                checked={
                                  !!form.watch(`weekdays_allowed.${day}.${day}`)
                                }
                                onCheckedChange={(checked) => {
                                  if (checked) {
                                    form.setValue(
                                      `weekdays_allowed.${day}.${day}`,
                                      {
                                        start_time: "09:00",
                                        end_time: "17:00",
                                      }
                                    );
                                  } else {
                                    const { [day]: _, ...rest } =
                                      form.getValues("weekdays_allowed");
                                    form.setValue("weekdays_allowed", rest);
                                  }
                                }}
                                className="bg-gray-700 border-gray-600"
                              />
                              <span className="text-gray-200 capitalize">
                                {day}
                              </span>
                            </div>
                            {form.watch(`weekdays_allowed.${day}`) ? (
                              <ChevronUp className="h-4 w-4" />
                            ) : (
                              <ChevronDown className="h-4 w-4" />
                            )}
                          </CollapsibleTrigger>
                          <CollapsibleContent className="p-2 space-y-2">
                            {form.watch(`weekdays_allowed.${day}`) && (
                              <div className="flex space-x-4">
                                <FormField
                                  control={form.control}
                                  name={
                                    `weekdays_allowed.${day}.start_time` as const
                                  }
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel className="text-gray-300">
                                        Start Time
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          {...field}
                                          type="time"
                                          className="bg-gray-800 border-gray-700 text-gray-100"
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={form.control}
                                  name={
                                    `weekdays_allowed.${day}.end_time` as const
                                  }
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel className="text-gray-300">
                                        End Time
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          {...field}
                                          value={
                                            typeof field.value === "object"
                                              ? JSON.stringify(field.value)
                                              : field.value
                                          }
                                          type="time"
                                          className="bg-gray-800 border-gray-700 text-gray-100"
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>
                            )}
                          </CollapsibleContent>
                        </Collapsible>
                      ))}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Tabs defaultValue="time" className="w-full">
                <TabsList className="grid w-full grid-cols-2 bg-gray-800">
                  <TabsTrigger
                    value="time"
                    className="data-[state=active]:bg-gray-700"
                  >
                    <Clock className="w-4 h-4 mr-2" />
                    Time Limits
                  </TabsTrigger>
                  <TabsTrigger
                    value="energy"
                    className="data-[state=active]:bg-gray-700"
                  >
                    <Battery className="w-4 h-4 mr-2" />
                    Energy Limits
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="time" className="mt-4 space-y-4">
                  {["daily", "weekly", "monthly", "all_time"].map((period) => (
                    <FormField
                      key={period}
                      control={form.control}
                      name={
                        `${period}_max_time_limit_hr` as keyof z.infer<
                          typeof formSchema
                        >
                      }
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-200 capitalize">
                            {period.replace("_", " ")} Max Time Limit (hours)
                          </FormLabel>
                          <FormControl>
                            <div className="flex items-center space-x-4">
                              <Slider
                                min={0}
                                max={
                                  period === "daily"
                                    ? 24
                                    : period === "weekly"
                                    ? 168
                                    : period === "monthly"
                                    ? 744
                                    : 10000
                                }
                                step={0.5}
                                value={[Number(field.value)]}
                                onValueChange={(value) =>
                                  field.onChange(Number(value[0]))
                                }
                                className="flex-grow"
                              />
                              <Input
                                type="number"
                                {...field}
                                value={
                                  typeof field.value === "number"
                                    ? field.value
                                    : 0
                                }
                                onChange={(e) =>
                                  field.onChange(
                                    Number.parseFloat(e.target.value)
                                  )
                                }
                                className="w-20 bg-gray-800 border-gray-700 text-gray-100"
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  ))}
                </TabsContent>
                <TabsContent value="energy" className="mt-4 space-y-4">
                  {["daily", "weekly", "monthly", "all_time"].map((period) => (
                    <FormField
                      key={period}
                      control={form.control}
                      name={
                        `${period}_max_energy_limit_kwh` as keyof z.infer<
                          typeof formSchema
                        >
                      }
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-200 capitalize">
                            {period.replace("_", " ")} Max Energy Limit (kWh)
                          </FormLabel>
                          <FormControl>
                            <div className="flex items-center space-x-4">
                              <Slider
                                min={0}
                                max={
                                  period === "daily"
                                    ? 100
                                    : period === "weekly"
                                    ? 500
                                    : period === "monthly"
                                    ? 2000
                                    : 10000
                                }
                                step={0.5}
                                value={[Number(field.value)]}
                                onValueChange={(value) =>
                                  field.onChange(value[0])
                                }
                                className="flex-grow"
                              />
                              <Input
                                type="number"
                                {...field}
                                value={
                                  typeof field.value === "number"
                                    ? field.value
                                    : 0
                                }
                                onChange={(e) =>
                                  field.onChange(
                                    Number.parseFloat(e.target.value)
                                  )
                                }
                                className="w-20 bg-gray-800 border-gray-700 text-gray-100"
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  ))}
                </TabsContent>
              </Tabs>

              <Card className="bg-gray-800 border-gray-700">
                <CardHeader>
                  <CardTitle className="text-gray-100">Summary</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-semibold text-gray-200">
                        Allowed Weekdays:
                      </h4>
                      <ul className="list-disc list-inside text-gray-300">
                        {Object.entries(form.watch("weekdays_allowed")).map(
                          ([day, times]) => (
                            <li key={day} className="capitalize">
                              {day}: {times.start_time} - {times.end_time}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-200">
                        Time Limits:
                      </h4>
                      <ul className="list-disc list-inside text-gray-300">
                        <li>
                          Daily:{" "}
                          {formatTime(form.watch("daily_max_time_limit_hr"))}
                        </li>
                        <li>
                          Weekly:{" "}
                          {formatTime(form.watch("weekly_max_time_limit_hr"))}
                        </li>
                        <li>
                          Monthly:{" "}
                          {formatTime(form.watch("monthly_max_time_limit_hr"))}
                        </li>
                        <li>
                          All-time:{" "}
                          {formatTime(form.watch("all_time_max_time_limit_hr"))}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-200">
                        Energy Limits:
                      </h4>
                      <ul className="list-disc list-inside text-gray-300">
                        <li>
                          Daily:{" "}
                          {formatEnergy(
                            form.watch("daily_max_energy_limit_kwh")
                          )}
                        </li>
                        <li>
                          Weekly:{" "}
                          {formatEnergy(
                            form.watch("weekly_max_energy_limit_kwh")
                          )}
                        </li>
                        <li>
                          Monthly:{" "}
                          {formatEnergy(
                            form.watch("monthly_max_energy_limit_kwh")
                          )}
                        </li>
                        <li>
                          All-time:{" "}
                          {formatEnergy(
                            form.watch("all_time_max_energy_limit_kwh")
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-200">Description</FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      className="bg-gray-800 border-gray-700 text-gray-100"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <SheetFooter>
              <SheetClose asChild>
                <Button type="button" variant="outline">
                  Cancel
                </Button>
              </SheetClose>
              <Button
                disabled={
                  createRestrictionLimitLoading || editRestrictionLimitLoading
                }
                type="submit"
                variant="secondary"
              >
                Save Changes
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};
