// src/SmoothSparkline.tsx

import type React from "react";
import { Sparklines, SparklinesLine } from "react-sparklines";

interface SmoothSparklineProperties {
  data: number[];
  width: number;
  height: number;
  color?: string;
  smoothness: number;
  lineThickness: number;
}

const SparkLine: React.FC<SmoothSparklineProperties> = ({
  data,
  width,
  height,
  color,
  smoothness,
  lineThickness,
}) => {
  function splineInterpolate(points: number[], tension: number) {
    const size = points.length;
    const interpolated = [];

    for (let index = 0; index < size - 1; index++) {
      const p0 = points[index - 1] || points[index];
      const p1 = points[index];
      const p2 = points[index + 1];
      const p3 = points[index + 2] || points[index + 1];

      for (let t = 0; t < 1; t += 0.1) {
        const t2 = t * t;
        const t3 = t2 * t;

        const c1 = tension * (p2 - p0);
        const c2 = tension * (p3 - p1);

        const a = 2 * t3 - 3 * t2 + 1;
        const b = t3 - 2 * t2 + t;
        const c = t3 - t2;
        const d = -2 * t3 + 3 * t2;

        const value = a * p1 + b * c1 + c * c2 + d * p2;
        interpolated.push(value);
      }
    }

    return interpolated;
  }

  const smoothedData = splineInterpolate(data, smoothness);

  return (
    <div className="relative">
      <Sparklines data={smoothedData} width={width} height={height}>
        <SparklinesLine
          color={color ? color : "url(#grad1)"}
          style={{ strokeWidth: lineThickness, fill: "none" }}
        />
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop stopColor="#FED217" />
            <stop offset="1" stopColor="#FD9A27" />
          </linearGradient>
        </defs>
      </Sparklines>

      {/*The blurred line*/}
      <div className="absolute left-0 top-0 w-full blur-md">
        <Sparklines data={smoothedData} width={width} height={height}>
          <SparklinesLine
            color={color ? color : "url(#grad1)"}
            style={{ strokeWidth: lineThickness, fill: "none" }}
          />
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop stopColor="#FED217" />
              <stop offset="1" stopColor="#FD9A27" />
            </linearGradient>
          </defs>
        </Sparklines>
      </div>
    </div>
  );
};

export default SparkLine;
