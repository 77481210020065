import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetTransactionRecordsQuery } from "@/redux/features/revenue/revenue.api";
import { useAppSelector } from "@/redux/hooks";
import { useState } from "react";

import { columns } from "./column";
import { TransactionDataTable } from "./transaction-data-table";

const TransactionsTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);

  const { data, isLoading } = useGetTransactionRecordsQuery({
    orgId,
    page,
  });

  return (
    <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
      <div className="mb-4">
        <h2 className="text-2xl font-bold">Recent Transaction records.</h2>
        <p className="text-lg">Manage and analyze your recent transactions.</p>
      </div>

      <TransactionDataTable
        data={data ? data.results : []}
        columns={columns}
        loading={isLoading}
      />
      {isLoading ? (
        <div className="my-2 flex justify-end">
          <div className="flex justify-end gap-2">
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
            <Skeleton className="h-6 w-12 rounded-sm" />
          </div>
        </div>
      ) : (
        data && (
          <Pagination className="justify-end pr-6 pt-4">
            <PaginationContent>
              {data.previous && (
                <PaginationItem onClick={() => setPage(page - 1)}>
                  <PaginationPrevious href="#" />
                </PaginationItem>
              )}
              <PaginationItem>
                <PaginationLink href="#" isActive>
                  {page}
                </PaginationLink>
              </PaginationItem>
              {data.next && (
                <PaginationItem onClick={() => setPage(page + 1)}>
                  <PaginationNext href="#" />
                </PaginationItem>
              )}
            </PaginationContent>
          </Pagination>
        )
      )}
    </div>
  );
};

export default TransactionsTable;
