import type React from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface PieChartProperties {
  chartData: number[];
  chartOptions: any;
  type:
    | "line"
    | "area"
    | "bar"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "candlestick"
    | "boxPlot"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | "rangeArea"
    | "treemap"
    | undefined;
}

const PieChart: React.FC<PieChartProperties> = (properties) => {
  const [chartData, setChartData] = useState(properties.chartData);
  const [chartOptions, setChartOptions] = useState(properties.chartOptions);

  useEffect(() => {
    setChartData(properties.chartData);
    setChartOptions(properties.chartOptions);
  }, [properties.chartData, properties.chartOptions]);

  return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type={properties.type}
      width="100%"
      height="100%"
    />
  );
};

export default PieChart;
