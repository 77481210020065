import { DeletePrompt } from "@/components/delete-prompt";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  useDeleteInvitationMutation,
  useResendInvitationMutation,
} from "@/redux/features/org/org.api";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Dispatch, SetStateAction } from "react";
import { toast } from "sonner";

import type { MembersDetailsType } from "../../types";

type Property = {
  data: MembersDetailsType;
};
export function PendingMemberCardAction({ data }: Property) {
  const [deleteInvitation, { isLoading: deleteInvitationLoading }] =
    useDeleteInvitationMutation();
  const [resendInvitation, { isLoading: resendInvitationLoading }] =
    useResendInvitationMutation();

  const onDelete = async (setModal: Dispatch<SetStateAction<boolean>>) => {
    try {
      await deleteInvitation(data.id).unwrap();
      toast.success("Invitation deleted successfully");
      setModal(false);
    } catch (error) {
      console.error("error", error);
    }
  };

  const onResend = async () => {
    try {
      await resendInvitation({
        id: data.id,
        frontend_url: window.location.origin + "/acceptInvitation?referrer=",
      }).unwrap();
      toast.success("Invitation resent successfully");
    } catch (error) {
      console.error("error", error);
      toast.error("Failed to resend invitation");
    }
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <Button
          disabled={resendInvitationLoading}
          onClick={onResend}
          className="flex w-full justify-between gap-2 bg-transparent text-primary/90 hover:text-white"
        >
          Resend invitation
        </Button>

        <DeletePrompt
          buttonDisabled={deleteInvitationLoading}
          id={data.id}
          onDelete={onDelete}
          title="Are you sure you want to delete this invitation?"
          warningText="This action cannot be undone."
          buttonText="Delete"
        >
          <Button className="flex w-full justify-between gap-2 bg-transparent text-red-500">
            Delete invitation
          </Button>
        </DeletePrompt>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
