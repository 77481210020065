import { ChargerGroupSelect } from "@/components/charger-group-select";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { cn } from "@/lib/utils/cn";
import {
  useAddChargePointMutation,
  useGetChargeGroupsQuery,
  useUpdateChargePointMutation,
} from "@/redux/features/charger-network/charger-network.api";
import {
  setChargeGroups,
  setShowInstructions,
  startLoading,
} from "@/redux/features/charger-network/charger-network.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { CalendarIcon, Plus } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import type { Charger } from "./schema";

const formSchema = z.object({
  name: z
    .string()
    .min(2, { message: "Charge Point name is too short" })
    .max(100, { message: "Charge Point name is too long" }),
  charge_point_id: z.string(),
  charger_make: z.string(),
  charger_model: z.string(),
  charger_type: z.string(),
  max_voltage: z.coerce.number(),
  max_amperage: z.coerce.number(),
  max_charging_power: z.coerce.number(),
  charger_group: z.string(),
  warranty_expiry_date: z.string(),
  serial_number: z.string(),
});

export function CreateChargePoint({
  data: editData,
  onClose,
}: {
  data?: Charger;
  onClose?: (() => void) | undefined;
}) {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const globalChargeGroupsPage = useAppSelector(
    (state) => state.chargerNetwork.globalChargerGroups.page
  );

  const { data, isFetching } = useGetChargeGroupsQuery({
    orgId,
    page: globalChargeGroupsPage,
  });
  const [createChargePointMutation, { isLoading: createChargePointLoading }] =
    useAddChargePointMutation();
  const [editChargePointMutation, { isLoading: editChargePointLoading }] =
    useUpdateChargePointMutation();

  const defaultValues = data
    ? {
        name: editData?.name ?? "",
        charge_point_id: editData?.charge_point_id ?? "",
        charger_make: editData?.charger_make ?? "",
        charger_model: editData?.charger_model ?? "",
        charger_type: editData?.charger_type ?? "",
        max_voltage: editData?.max_voltage ?? 0,
        max_amperage: editData?.max_amperage ?? 0,
        max_charging_power: editData?.max_charging_power ?? 0,
        charger_group: editData?.charger_group?.id ?? "",
        warranty_expiry_date: editData?.warranty_expiry_date ?? "",
        serial_number: editData?.serial_number ?? "",
      }
    : {
        name: "",
        charge_point_id: "",
        charger_make: "",
        charger_model: "",
        charger_type: "",
        max_voltage: 0,
        max_amperage: 0,
        max_charging_power: 0,
        charger_group: "",
        warranty_expiry_date: "",
        serial_number: "",
      };

  const [isOpen, setIsOpen] = useState(!!editData);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setChargeGroups(data));
    }
  }, [isFetching, data, dispatch]);

  const handleSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      const formattedExpiryDate = formatDateToYYYYMMDD(
        new Date(values.warranty_expiry_date)
      );
      const result = { ...values, warranty_expiry_date: formattedExpiryDate };
      if (data) {
        if (isDirty) {
          await editChargePointMutation({ ...result, id: editData?.id })
            .unwrap()
            .then((response) => {
              form.reset();
              toast.success(response.message);
              setIsOpen(false);
            })
            .catch((error) => {
              toast.error(error?.data?.error_message);
            });
        } else {
          toast.error("Please edit charge point before saving.");
          return;
        }
      } else {
        await createChargePointMutation(result)
          .unwrap()
          .then((response) => {
            form.reset();
            toast.success(response.message);
            dispatch(setShowInstructions(true));
            setIsOpen(false);
          })
          .catch((error) => {
            const errorMessage = error?.data?.error_message;
            if (typeof errorMessage === "string") {
              toast.error(errorMessage);
            } else if (
              typeof errorMessage === "object" &&
              errorMessage !== null
            ) {
              const formattedError = Object.keys(errorMessage)
                .map((key) => `Error ${key}: ${errorMessage[key].join(", ")}`)
                .join("\n");
              toast.error(formattedError);
            } else {
              toast.error("An unknown error occurred");
            }
          });
      }
    },
    [
      createChargePointMutation,
      data,
      dispatch,
      editChargePointMutation,
      editData,
      form,
      isDirty,
    ]
  );

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <SheetTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 whitespace-nowrap text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4 shrink-0" /> Create New Charge Point
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-scroll sm:max-w-lg">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4"
          >
            <SheetHeader>
              <SheetTitle>Add Charge Point</SheetTitle>
              <SheetDescription>
                Add Charge points to your charger groups
              </SheetDescription>
            </SheetHeader>

            {[
              { name: "name", label: "Name", placeholder: "Name" },
              {
                name: "charge_point_id",
                label: "ID",
                placeholder: "Charge Point ID",
              },
              {
                name: "charger_make",
                label: "Charger Make",
                placeholder: "Sample Make",
              },
              {
                name: "charger_model",
                label: "Charger Model",
                placeholder: "Sample Model",
              },
              {
                name: "charger_type",
                label: "Charger Type",
                placeholder: "Sample Type",
              },
              {
                name: "max_voltage",
                label: "Max Voltage",
                placeholder: "Voltage",
                type: "number",
              },
              {
                name: "max_amperage",
                label: "Max Amperage",
                placeholder: "Amperage",
                type: "number",
              },
              {
                name: "max_charging_power",
                label: "Max Charging Power",
                placeholder: "Charging Power",
                type: "number",
              },
              {
                name: "serial_number",
                label: "Serial Number",
                placeholder: "Serial Number",
                type: "number",
              },
            ].map(({ name, label, placeholder, type = "text" }) => (
              <FormField
                key={name}
                control={form.control}
                name={name as keyof z.infer<typeof formSchema>}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                      <Input
                        type={type}
                        className="bg-gray-800 border-gray-700 text-gray-100"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
            <ChargerGroupSelect />
            <FormField
              control={form.control}
              name="warranty_expiry_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Warranty Expiry Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            "bg-gray-800 border-gray-700 text-gray-100",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value
                            ? format(new Date(field.value), "PPP")
                            : null}
                          <CalendarIcon className="ml-auto h-4 w-4 text-white opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="end">
                      <Calendar
                        mode="single"
                        selected={
                          field.value ? new Date(field.value) : undefined
                        }
                        onSelect={(date) => field.onChange(date?.toISOString())}
                        // disabled={(date) => date < new Date()}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <SheetFooter>
              <SheetClose asChild>
                <Button
                  variant="outline"
                  onClick={() => form.reset()}
                  type="button"
                >
                  Cancel
                </Button>
              </SheetClose>
              <Button
                disabled={createChargePointLoading || editChargePointLoading}
                variant="secondary"
                type="submit"
              >
                Save changes
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
