import { currencyList } from "@/common/constants/currency-list";
import PhoneNumber from "libphonenumber-js";

export const extractCurrencySymbol = (countryCode: string) => {
  try {
    // Find the currency object in the currencyList array that matches the given country code
    const currencyObject = currencyList.find(
      (currency) => currency.country === countryCode,
    );

    // Return the currency symbol if found
    if (currencyObject) {
      return currencyObject.currency;
    } else {
      console.error("Currency symbol not found for country code:", countryCode);
      return null;
    }
  } catch (error) {
    console.error("Error extracting currency symbol:", error);
    return null;
  }
};

export const extractCountryCode = (phoneNumber: string) => {
  try {
    // Parse the phone number
    const parsedPhoneNumber = PhoneNumber(phoneNumber);

    // Get the country code
    if (parsedPhoneNumber) {
      const countryCode = parsedPhoneNumber.country;
      return countryCode;
    }
  } catch (error) {
    console.error("Error parsing phone number:", error);
    return null;
  }
};
