/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface LineChartProperties {
  chartData: { name: string; data: string[] }[];
  chartOptions: any;
}

const LineChart: React.FC<LineChartProperties> = ({
  chartData,
  chartOptions,
}) => {
  const [lineChartData, setChartData] = useState<
    { name: string; data: string[] }[]
  >([]);
  const [lineChartOptions, setChartOptions] = useState<Partial<any>>({});

  useEffect(() => {
    setChartData(chartData);
    setChartOptions(chartOptions);
  }, [chartData, chartOptions]);

  return (
    <ReactApexChart
      options={lineChartOptions}
      //@ts-ignore
      series={lineChartData}
      type="line"
      width="100%"
      height="100%"
    />
  );
};

export default LineChart;
