import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDeleteChargerGroupMutation } from "@/redux/features/charger-network/charger-network.api";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { ViewMoreGroup } from "../group-details/view-more-group";
import { CreateChargerGroup as EditChargerGroup } from "./create-charger-group";
import type { Group } from "./schema";

interface Prop<TData> {
  row: Row<TData>;
}

export function Actions({ row }: Prop<Group>) {
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState<Group | null>(null);
  const [viewMoreData, setViewMoreData] = useState<Group | null>(null);

  const [deleteCharerGroup, { isLoading }] = useDeleteChargerGroupMutation();

  const handleDelete = useCallback(async () => {
    await deleteCharerGroup(row.original.id)
      .unwrap()
      .then(() => {
        toast.success("Charger group deleted successfully");
        setTimeout(() => {
          setIsOpen(false);
        }, 500);
      })
      .catch((error) => {
        toast.error(
          `Failed to delete charger group: ${error?.data?.error_message}`
        );
      });
  }, [deleteCharerGroup, row]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem
            onClick={() => setViewMoreData(row.original)}
            className="flex items-center justify-between gap-2"
          >
            View more
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setEditData(row.original)}
            className="flex items-center justify-between gap-2"
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-red-500"
            onClick={() => setIsOpen(true)}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-sm p-2 bg-background border border-[#525252]">
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete this
              Charger Group from our servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
            <Button
              variant="destructive"
              disabled={isLoading}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {editData && (
        <EditChargerGroup data={editData} onClose={() => setEditData(null)} />
      )}
      {viewMoreData && (
        <ViewMoreGroup
          data={viewMoreData}
          onClose={() => setViewMoreData(null)}
        />
      )}
    </>
  );
}
