import * as z from "zod";

export const signupFormSchema = z
  .object({
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .max(100, { message: "Email address is too long" }),
    first_name: z
      .string()
      .min(1, { message: "First name cannot be empty" })
      .min(3, { message: "First name is too short" })
      .max(50, { message: "First name is too long" }),
    last_name: z
      .string()
      .min(1, { message: "Last name cannot be empty" })
      .min(3, { message: "Last name is too short" })
      .max(50, { message: "Last name is too long" }),
    phone_number: z
      .string()
      .min(1, { message: "Phone number is required" })
      .min(6, { message: "Please enter a valid phone number" }),

    address: z
      .string()
      .max(250, { message: "Address length is too long." })
      .min(2, { message: "Please enter your address." }),
    lat: z.number(),
    lng: z.number(),
    password: z
      .string()
      .min(1, { message: "Please enter a password" })
      .min(8, { message: "Passwords must be at least 8 characters long" })
      .refine(
        (value) => /[a-z]/.test(value),
        "Password must contain at least one lowercase letter",
      )
      .refine(
        (value) => /[A-Z]/.test(value),
        "Password must contain at least one uppercase letter",
      )
      .refine(
        (value) => /\d/.test(value),
        "Password must contain at least one number",
      )
      .refine(
        (value) => /[!$%&*?@]/.test(value),
        "Password must contain at least one special character",
      ),
    confirm_password: z.string().min(1, { message: "Please confirm password" }),
    terms: z
      .boolean()
      .default(false)
      .refine((data) => data === true, {
        message: "Please agree to the terms and conditions",
      }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
