import { lineChartOptions } from "@/common/variables/charts";
import LineChart from "@/components/ui/line-chart";
import { Skeleton } from "@/components/ui/skeleton";
import {
  useGetDailySalesAnalyticsQuery,
  useGetMonthlySalesAnalyticsQuery,
  useGetWeeklySalesAnalyticsQuery,
  useGetYearlySalesAnalyticsQuery,
} from "@/redux/features/revenue/revenue.api";
import {
  setDailySalesData,
  setMonthlySalesData,
  setWeeklySalesData,
  setYearlySalesData,
  startLoading,
} from "@/redux/features/revenue/revenue.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

type Properties = {};
interface SalesData {
  name: string;
  data: number[];
}
interface IntervalData {
  [key: string]: SalesData[];
}

export const intervalToCategories: Record<string, string[]> = {
  daily: Array.from({ length: 24 }, (_, index) => `${index}`),
  weekly: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
  monthly: Array.from({ length: 31 }, (_, index) => (index + 1).toString()),
  yearly: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};

export const SalesChart = (properties: Properties) => {
  const dispatch = useAppDispatch();
  const { orgId } = useAppSelector((state) => state.auth);
  const {
    data: {
      dailySalesAnalytics,
      monthlySalesAnalytics,
      weeklySalesAnalytics,
      yearlySalesAnalytics,
    },
    loading,
  } = useAppSelector((state) => state.revenue);
  const { globalCurrency } = useAppSelector((state) => state.home);

  const [chartData, setChartData] = useState("yearly");
  const [chartDataValues, setChartDataValues] = useState<{
    name: string;
    data: string[];
  }>({ name: "", data: [] });

  const {
    data: dailySalesAnalyticsData,
    isFetching: dailySalesAnalyticsFetching,
    isError: dailySalesAnalyticsError,
  } = useGetDailySalesAnalyticsQuery(orgId, { skip: !orgId });
  const {
    data: weeklySalesAnalyticsData,
    isFetching: weeklySalesAnalyticsFetching,
    isError: weeklySalesAnalyticsError,
  } = useGetWeeklySalesAnalyticsQuery(orgId, { skip: !orgId });
  const {
    data: monthlySalesAnalyticsData,
    isFetching: monthlySalesAnalyticsFetching,
    isError: monthlySalesAnalyticsError,
  } = useGetMonthlySalesAnalyticsQuery(orgId, { skip: !orgId });
  const {
    data: yearlySalesAnalyticsData,
    isFetching: yearlySalesAnalyticsFetching,
    isError: yearlySalesAnalyticsError,
  } = useGetYearlySalesAnalyticsQuery(orgId, { skip: !orgId });

  const getIntervalToData = () => {
    if (
      dailySalesAnalytics &&
      weeklySalesAnalytics &&
      monthlySalesAnalytics &&
      yearlySalesAnalytics
    ) {
      return {
        daily: [
          {
            name: "Daily Revenue",
            data: dailySalesAnalytics.map((item) => ({
              x: String(item.hour),
              y: item.total_sales,
            })),
          },
        ],
        weekly: [
          {
            name: "Weekly Revenue",
            data: weeklySalesAnalytics.map((item) => ({
              x: item.day,
              y: item.total_sales,
            })),
          },
        ],
        monthly: [
          {
            name: "Monthly Revenue",
            data: monthlySalesAnalytics.map((item) => ({
              x: item.day,
              y: item.total_sales,
            })),
          },
        ],
        yearly: [
          {
            name: "Yearly Revenue",
            data: yearlySalesAnalytics.map((item) => ({
              x: item.month,
              y: item.total_sales,
            })),
          },
        ],
      };
    }
  };

  const handleIntervalClick = async (interval = "yearly") => {
    setChartData(interval);
    if (
      dailySalesAnalyticsData &&
      weeklySalesAnalyticsData &&
      monthlySalesAnalyticsData &&
      yearlySalesAnalyticsData
    ) {
      dispatch(setDailySalesData(dailySalesAnalyticsData));
      dispatch(setWeeklySalesData(weeklySalesAnalyticsData));
      dispatch(setMonthlySalesData(monthlySalesAnalyticsData));
      dispatch(setYearlySalesData(yearlySalesAnalyticsData));
    }
    const dataForInterval: any = getIntervalToData();

    setChartDataValues(
      dataForInterval[interval]?.[0] || dataForInterval["yearly"][0] || [],
    );
  };

  useEffect(() => {
    if (
      dailySalesAnalyticsFetching &&
      weeklySalesAnalyticsFetching &&
      monthlySalesAnalyticsFetching &&
      yearlySalesAnalyticsFetching
    ) {
      dispatch(startLoading());
    } else {
      handleIntervalClick();
    }
  }, [
    dailySalesAnalyticsFetching,
    weeklySalesAnalyticsFetching,
    monthlySalesAnalyticsFetching,
    yearlySalesAnalyticsFetching,
    dispatch,
    dailySalesAnalytics,
    monthlySalesAnalytics,
    weeklySalesAnalytics,
    yearlySalesAnalytics,
  ]);

  return (
    <div className="relative w-full rounded-2xl bg-gradient-linear p-6 backdrop-blur-xl">
      {loading ? (
        <div className="flex w-full flex-col items-start gap-3">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[160px]" />
            <div className="flex items-center gap-4">
              <Skeleton className="h-10 w-[80px]" />
              <Skeleton className="h-10 w-[80px]" />
              <Skeleton className="h-10 w-[80px]" />
              <Skeleton className="h-10 w-[80px]" />
            </div>
          </div>
          <div className="w-full px-3">
            <Skeleton className="h-[230px] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <div>
              <h3 className="font-extralight text-[#D1D1D1]">
                Sales Analytics
              </h3>
            </div>
            <div className="flex items-center gap-4">
              {Object.keys(intervalToCategories).map((interval) => (
                <span
                  key={interval}
                  className={`inline-block cursor-pointer px-4 py-2 text-sm capitalize transition hover:text-white ${
                    chartData === interval
                      ? "rounded bg-[#29292C] text-white hover:scale-[1.1]"
                      : ""
                  }`}
                  onClick={() => handleIntervalClick(interval)}
                >
                  {interval}
                </span>
              ))}
            </div>
          </div>
          <div className="relative min-h-[260px]">
            <LineChart
              chartData={[chartDataValues]}
              chartOptions={lineChartOptions(globalCurrency)}
            />
          </div>
        </>
      )}
    </div>
  );
};
