import { DeletePrompt } from "@/components/delete-prompt";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useRemoveUserFromOrgMutation } from "@/redux/features/org/org.api";
import { useAppSelector } from "@/redux/hooks";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Dispatch, SetStateAction } from "react";
import { toast } from "sonner";

type Property = {
  userId: string;
  memberId: string;
};
export function OthersMemberCardAction({ userId, memberId }: Property) {
  const orgId = useAppSelector((state) => state.auth.orgId);
  const [removeUserFromOrgMutation, { isLoading }] =
    useRemoveUserFromOrgMutation();

  const onDelete = async (setModal: Dispatch<SetStateAction<boolean>>) => {
    try {
      await removeUserFromOrgMutation({
        orgId: orgId,
        userId: memberId,
      }).unwrap();
      toast.success("User removed from team");
      setModal(false);
    } catch (error) {
      toast.error(error.data.error_message);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DeletePrompt
          buttonDisabled={isLoading}
          id=""
          onDelete={onDelete}
          title="Are you absolutely sure you want to remove this invitation?"
          warningText="This action cannot be undone."
          buttonText="Continue"
        >
          <Button className="flex w-full justify-between gap-2 bg-transparent text-red-500">
            Remove from team
          </Button>
        </DeletePrompt>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
