import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import cookieService from "@/lib/helpers/cookie-service";
import {
  changePassword,
  editUserProfile,
  forgotPassword,
  getOrgId,
  getUserProfile,
  loginUserService,
  resendOTP,
  resetPassword,
  signupUserService,
  verifyOTP,
} from "@/services/auth";
import type { SignupType } from "@/types/auth";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { AuthContext } from "./context";
import {
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_LOADING,
  GET_USER_PROFILE_SUCCESS,
  LOGIN_FAIL,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  RESEND_OTP_FAIL,
  RESEND_OTP_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_LOADING,
  SIGNUP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_LOADING,
  VERIFY_OTP_SUCCESS,
  authReducer,
} from "./reducers";
import type { AuthContextPropType as AuthContextPropertyType } from "./types/auth";

const initialValue = {
  authenticated: false,
  setAuthenticated: () => {},
};
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    initialValue.authenticated,
  );
  const [appLoading, setAppLoading] = useState(true);

  const { toast: toastAction } = useToast();
  const [loginState, loginDispatch] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [signupState, signupDispatch] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [userProfile, setUserProfile] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [OTPToken, setOTPToken] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [resendOTPToken, setResendOTP] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [forgotPasswordState, setForgotPassword] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [resetPasswordState, setResetPassword] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });
  const [newPassword, setNewPassword] = useReducer(authReducer, {
    data: null,
    loading: false,
    signedIn: false,
    error: null,
  });

  useEffect(() => {
    (async () => {
      if (!localStorage.getItem("org_id") && authenticated) {
        const privateOrg = await getOrgId();
        if (privateOrg.status_code == 200 && privateOrg.data) {
          localStorage.setItem("org_id", privateOrg.data.id);
        }
      }
    })();
  }, []);

  const handleUserLogin = useCallback(
    async (values: { email: string; password: string }) => {
      loginDispatch({ type: LOGIN_LOADING });
      try {
        const response = await loginUserService(values);

        if (response?.status == "success" && response?.user_data) {
          cookieService.setCookie("refresh_token", response.refresh_token, {
            expires: 1,
          });
          cookieService.setCookie("access_token", response.access_token, {
            // httpOnly: true,
            // secure: true,
            expires: 24 / 6,
          });
          setAuthenticated(true);
          loginDispatch({ payload: response.user_data, type: LOGIN_SUCCESS });
          toast.success(response.message);
          handleGetUserProfile();
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (response.message === "Please verify your account to login") {
          signupDispatch({
            type: LOGIN_FAIL,
            payload: "Please verify your account to login.",
          });

          toast.error(response.message, {
            action: {
              label: "Resend OTP to you mail?",
              onClick: () => handleResendOTP({ email: values.email }),
            },
          });
        } else if (response.status === "error") {
          loginDispatch({
            type: LOGIN_FAIL,
            payload: response.message,
          });

          toast.error(response.message);
        } else if (response?.response?.status === 401) {
          loginDispatch({ type: LOGIN_FAIL, payload: response.message });
          toast("The email or password you entered is incorrect.");
        } else {
          loginDispatch({
            type: LOGIN_FAIL,
            payload: "Invalid response from the server.",
          });
          toastAction({
            variant: "destructive",
            title: "Uh oh! Something went wrong.",
            description: "Invalid response from the server.",
            action: (
              <ToastAction
                altText="Try again"
                onClick={() => handleUserLogin(values)}
              >
                Try again
              </ToastAction>
            ),
          });
        }
      } catch {
        loginDispatch({
          payload: "Oh No! An error occurred",
          type: LOGIN_FAIL,
        });
        toastAction({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
          action: (
            <ToastAction
              altText="Try again"
              onClick={() => handleUserLogin(values)}
            >
              Try again
            </ToastAction>
          ),
        });
      }
    },
    [navigate, toast],
  );

  const handleUserLogout = useCallback(() => {
    loginDispatch({ type: LOGOUT_LOADING });
    cookieService.removeCookie("access_token");
    cookieService.removeCookie("refresh_token");
    loginDispatch({ type: LOGOUT_SUCCESS });
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }, []);

  const handleUserSignup = useCallback(
    async (values: SignupType) => {
      try {
        signupDispatch({ type: SIGNUP_LOADING });

        const response = await signupUserService(values);

        if (response.status == "success" && response.data) {
          signupDispatch({
            payload: response.data,
            type: SIGNUP_SUCCESS,
          });

          toast.success(response.message);

          setTimeout(() => {
            navigate(`/signup/verify-email?email=${response.data.email}`);
          }, 2000);
        } else if (
          response.status == "error" &&
          response.message === "User with this email already exists."
        ) {
          signupDispatch({
            type: SIGNUP_FAIL,
            payload: response.message,
          });
          toast.error(response.message, {
            description: "Returning user, Login?",
            action: {
              label: "Login",
              onClick: () => navigate("/login"),
            },
          });
        } else if (
          response.status == "error" &&
          response.data.phone_number[0] ==
            "user with this phone number already exists."
        ) {
          signupDispatch({
            type: SIGNUP_FAIL,
            payload: response.message,
          });
          toast.error(response.data.phone_number[0], {
            description: "Returning user, Login?",
            action: {
              label: "Login",
              onClick: () => navigate("/login"),
            },
          });
        } else {
          signupDispatch({
            type: SIGNUP_FAIL,
            payload: "Invalid response from the server.",
          });
          toast.error("Uh oh! Something went wrong.", {
            description: "Invalid response from the server.",
            action: {
              label: "Try again",
              onClick: () => handleUserSignup(values),
            },
          });
        }
      } catch {
        signupDispatch({
          payload: "Oh No! An error occurred",
          type: SIGNUP_FAIL,
        });
        toast.error("Uh oh! Something went wrong.", {
          description: "There was a problem with your request.",
          action: {
            label: "Try again",
            onClick: () => handleUserSignup(values),
          },
        });
      }
    },
    [navigate, toast],
  );

  const handleGetUserProfile = useCallback(async () => {
    try {
      setUserProfile({ type: GET_USER_PROFILE_LOADING });
      const response = await getUserProfile();
      const privateOrg = await getOrgId();
      if (privateOrg.status_code == 200 && privateOrg.data) {
        localStorage.setItem("org_id", privateOrg.data.id);
      }
      if (response.status == "success" && response.data) {
        setUserProfile({
          type: GET_USER_PROFILE_SUCCESS,
          payload: response.data,
        });
        return response.data;
      } else {
        setUserProfile({
          type: GET_USER_PROFILE_FAIL,
        });
      }
    } catch {
      setUserProfile({
        type: GET_USER_PROFILE_FAIL,
      });
    }
  }, []);

  const handleEditUserProfile = useCallback(
    async (value: {
      email?: string | undefined;
      first_name?: string | undefined;
      last_name?: string | undefined;
    }) => {
      try {
        const response = await editUserProfile(value);

        if (response.status == "success" && response.updated_user) {
          handleGetUserProfile();
          toast.success(response.message);
          if (value?.email ?? false) {
            setTimeout(() => {
              handleUserLogout();
              navigate("/login");
            }, 1000);
          }
          return response.data;
        }
      } catch {
        toast.error("Something went wrong.");
      }
    },
    [],
  );

  const handleVerifyOTP = useCallback(
    async (values: {
      email?: string | undefined;
      otp_token?: string | undefined;
    }) => {
      try {
        setOTPToken({ type: VERIFY_OTP_LOADING });
        const response = await verifyOTP(values);

        if (response.status == "success") {
          setOTPToken({
            type: VERIFY_OTP_SUCCESS,
            payload: response.data,
          });
          toast.success(response.message);
          setTimeout(() => {
            navigate(`/login`);
          }, 2000);
        } else {
          setOTPToken({
            type: VERIFY_OTP_FAIL,
          });
        }
      } catch {
        setOTPToken({
          type: VERIFY_OTP_FAIL,
        });
        toast.error("Something went wrong");
      }
    },
    [],
  );

  const handleResendOTP = useCallback(async (values: { email: string }) => {
    try {
      // setResendOTP({ type: RESEND_OTP_LOADING });
      const response = await resendOTP(values);

      if (response.status == "success") {
        setResendOTP({
          type: RESEND_OTP_SUCCESS,
          payload: response.data,
        });
        toast.success(response.message);
        setTimeout(() => {
          navigate(`/signup/verify-email?email=${values.email}`);
        }, 2000);
      } else {
        setResendOTP({
          type: RESEND_OTP_FAIL,
        });
      }
    } catch {
      setResendOTP({
        type: RESEND_OTP_FAIL,
      });
      toast.error("Something went wrong");
    }
  }, []);

  const handleChangePassword = useCallback(
    async (value: { old_password: string; new_password: string }) => {
      try {
        setNewPassword({ type: CHANGE_PASSWORD_LOADING });
        const response = await changePassword(value);
        if (response.status === "success") {
          setNewPassword({ type: CHANGE_PASSWORD_SUCCESS });
          toast.success(response.message);
          setTimeout(() => {
            handleUserLogout();
            navigate("/login");
          }, 1000);
        } else if (response.status == "error") {
          setNewPassword({ type: CHANGE_PASSWORD_FAIL });
          toast.error(response.message);
        }
      } catch {
        setNewPassword({ type: CHANGE_PASSWORD_FAIL });
        toast.error("Something went wrong.");
      }
    },
    [],
  );

  const handleForgotPassword = useCallback(async (value: { email: string }) => {
    try {
      setForgotPassword({ type: FORGOT_PASSWORD_LOADING });
      const response = await forgotPassword(value);
      if (response.status === "success") {
        setForgotPassword({ type: FORGOT_PASSWORD_SUCCESS });
        toast.success(response.message);
        setTimeout(() => {
          handleUserLogout();
          navigate("/forgot-password/check-email");
        }, 1000);
      } else if (response.status == "error") {
        setForgotPassword({ type: FORGOT_PASSWORD_FAIL });
        toast.error(response.message);
      }
    } catch {
      setForgotPassword({ type: FORGOT_PASSWORD_FAIL });
      toast.error("Something went wrong.");
    }
  }, []);

  const handleResetPassword = useCallback(
    async (value: {
      new_password: string;
      email?: string;
      reset_token?: string;
    }) => {
      try {
        setResetPassword({ type: RESET_PASSWORD_LOADING });

        const response = await resetPassword(value);
        if (response.status === "success") {
          setResetPassword({ type: RESET_PASSWORD_SUCCESS });
          toast.success(response.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else if (response.status == "error") {
          setResetPassword({ type: RESET_PASSWORD_FAIL });
          toast.error(response.message);
        }
      } catch {
        setResetPassword({ type: RESET_PASSWORD_FAIL });
        toast.error("Something went wrong.");
      }
    },
    [],
  );

  const values: AuthContextPropertyType = {
    profile: null,
    handleUserLogin,
    loginState,

    handleUserSignup,
    signupState,

    handleGetUserProfile,
    userProfile,

    handleEditUserProfile,

    handleUserLogout,

    handleVerifyOTP,
    OTPToken,

    handleChangePassword,
    newPassword,

    handleForgotPassword,
    forgotPasswordState,

    handleResetPassword,
    resetPasswordState,

    handleResendOTP,
    resendOTPToken,

    authenticated,
    appLoading,
    setAppLoading,
    setAuthenticated,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
