import { Copy } from "lucide-react";
import {
  Activity,
  Battery,
  Calendar,
  Clock,
  Cpu,
  Gauge,
  Hash,
  Plug,
  Zap,
} from "lucide-react";
import { useState } from "react";
import { MdConnectWithoutContact } from "react-icons/md";
import { toast } from "sonner";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { formatDate } from "@/lib/helpers/dates";

import type { Charger } from "../charger-table/schema";
import { ConnectorTable } from "./connector-table/connector-table";
import GenerateQRCode from "./generate-qr-code";

export const ViewMoreCharger = ({
  data,
  onClose,
}: {
  data?: Charger;
  onClose?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(!!data);

  const chargerDetails = [
    {
      icon: <Plug className="h-4 w-4" />,
      label: "Charger Make",
      value: data?.charger_make,
    },
    {
      icon: <Cpu className="h-4 w-4" />,
      label: "Charger Model",
      value: data?.charger_model,
    },
    {
      icon: <Activity className="h-4 w-4" />,
      label: "Charger Status",
      value: data?.charger_status,
    },
    {
      icon: <Zap className="h-4 w-4" />,
      label: "Charger Type",
      value: data?.charger_type,
    },
    {
      icon: <Calendar className="h-4 w-4" />,
      label: "Installation Date",
      value: data?.installation_date,
    },
    {
      icon: <Gauge className="h-4 w-4" />,
      label: "Max Amperage",
      value: data?.max_amperage,
    },
    {
      icon: <Battery className="h-4 w-4" />,
      label: "Max Charging Power",
      value: data?.max_amperage,
    },
    {
      icon: <Zap className="h-4 w-4" />,
      label: "Max Voltage",
      value: data?.max_voltage,
    },
    {
      icon: <Hash className="h-4 w-4" />,
      label: "Serial Number",
      value: data?.serial_number,
    },
    {
      icon: <Clock className="h-4 w-4" />,
      label: "Warranty Expiry Date",
      value: data?.warranty_expiry_date
        ? formatDate(data?.warranty_expiry_date)
        : "No warranty expiry date",
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <DialogContent className="max-w-4xl border-0  p-0 text-gray-100 shadow-2xl">
        <DialogTitle className="flex items-center justify-between p-4 pb-0">
          <span className="text-xl font-bold">
            Chargepoint Details for {data?.charge_point_id}
          </span>
          <GenerateQRCode chargePointId={data?.charge_point_id} />
        </DialogTitle>
        <ScrollArea className="max-h-[80vh]">
          <div className="space-y-4 p-4">
            <Card className=" bg-transparent text-gray-100 ">
              <CardHeader className="">
                <CardTitle className="text-sm font-medium">
                  WebSocket URL
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center justify-between rounded-md bg-gray-700 px-3 py-2 text-xs">
                  <span className="text-green-400">
                    wss://core.grdflo.com/ocpp/1.6/central_system/
                    {data?.charge_point_id}
                  </span>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `wss://core.grdflo.com/ocpp/1.6/central_system/${data?.charge_point_id}`
                      );
                      toast.success("WebSocket URL copied to clipboard");
                    }}
                    size="sm"
                    variant="ghost"
                    className="h-6 w-6 p-0 hover:bg-gray-600"
                  >
                    <Copy size={14} />
                  </Button>
                </div>
              </CardContent>
            </Card>

            <div className="grid gap-4 md:grid-cols-2">
              <Card className=" bg-transparent text-gray-100 overflow-hidden">
                <CardHeader className="bg-gray-600 pb-2">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <MdConnectWithoutContact size={24} />
                    <div>
                      <p className="font-bold uppercase">Test Charger</p>
                      <p className="text-xs font-normal">
                        {data?.charge_point_id}
                      </p>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardContent className="grid grid-cols-2 gap-4 pt-4">
                  {chargerDetails.map((detail) => (
                    <div
                      key={detail?.label}
                      className="flex items-start space-x-2"
                    >
                      <div className="mt-0.5 rounded-full bg-gray-700 p-1">
                        {detail.icon}
                      </div>
                      <div>
                        <p className="text-xs font-medium text-gray-400">
                          {detail.label}
                        </p>
                        <p className="text-sm">{detail.value}</p>
                      </div>
                    </div>
                  ))}
                </CardContent>
              </Card>

              <ConnectorTable chargePointId={data?.id} />
            </div>
          </div>
        </ScrollArea>
        <DialogFooter className=" px-4 py-3">
          <DialogClose asChild>
            <Button
              variant="outline"
              className="w-full bg-gray-700 text-gray-200 hover:bg-gray-600"
            >
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
