import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import { formatDateShort } from "@/lib/helpers/dates";
import type { ColumnDef } from "@tanstack/react-table";
import { Actions } from "./actions";
import type { CardHoldersType } from "./schema";

export const columns: ColumnDef<CardHoldersType>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap">{row.getValue("name")}</div>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "role",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap">{row.getValue("role")}</div>
    ),
    enableSorting: true,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "phone_number",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("phone_number")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "total_sessions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total Charging Sessions" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("total_sessions")}</div>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "total_Cards",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Total RFID Card" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue("total_Cards")}</div>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "expiry_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Role Expiration Date" />
    ),
    cell: ({ row }) => (
      <div className="capitalize">
        {row.getValue("expiry_date")
          ? formatDateShort(row.getValue("expiry_date"))
          : "Does not expire"}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];
