import { api } from "@/redux/api";
import type {
  APIResponse,
  PaginatedRequest,
  PaginatedResponse,
} from "@/types/global";

interface CardBase {
  card_type: string; // "mifare_classic" | "mifare_desfire" | "mifare_plus";
  uid: string;
  expiry_date: string | null;
  card_status: string; // "active" | "disabled" | "revoked" | "charging";
  access_profile: string;
  organization_id: string;
}

interface CreateCardRequest extends CardBase {
  private_drive_user: string;
}

interface EditCardRequest extends CardBase {
  id: string;
  private_drive_user: string;
}

interface CardResponse extends CardBase {
  id: string;
  created_at: string;
  updated_at: string;
  card_in_use: boolean;
  created_by: string;
  private_drive_user: {
    address: string;
    created_at: string;
    created_by: string;
    email: string;
    expiry_date: string;
    id: string;
    name: string;
    organization_id: string;
    phone_number: string;
    updated_at: string;
  };
}

interface AccessProfileBase {
  access_to_charger_groups: Record<string, string>;
  access_to_chargers: Record<string, string>;
  access_to_organization: string;
  description?: string | null;
  name: string;
  organization_id: string;
  restriction_limit_id: string;
}
interface CreateAccessProfileRequest extends AccessProfileBase {}

interface EditAccessProfileRequest
  extends Omit<AccessProfileBase, "organization_id"> {
  id: string;
}
export interface AccessProfileResponse extends AccessProfileBase {
  id: string;
  created_at: string;
  created_by: string;
  updated_at: string;
}

interface CardHolderBase {
  name: string;
  email: string | null;
  expiry_date: string | null;
  phone_number: string;
  address: string;
  organization_id: string;
  role: string;
}
interface CreateCardHolderRequest extends CardHolderBase {}
interface EditCardHolderRequest extends CardHolderBase {
  id: string;
}
export interface CardHolderResponse extends CardHolderBase {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
}

interface LimitBase {
  name: string;
  description: string;
  weekdays_allowed: Record<
    string,
    {
      end_time: string;
      start_time: string;
    }
  >;
  daily_max_time_limit_hr: number;
  weekly_max_time_limit_hr: number;
  monthly_max_time_limit_hr: number;
  all_time_max_time_limit_hr: number;
  daily_max_energy_limit_kwh: number;
  weekly_max_energy_limit_kwh: number;
  monthly_max_energy_limit_kwh: number;
  all_time_max_energy_limit_kwh: number;
  organization_id: string;
}
interface CreateLimitRequest extends LimitBase {}
interface EditLimitRequest extends LimitBase {
  id: string;
}

interface LimitResponse extends LimitBase {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
}

interface TotalCardsResponse {
  total_number_of_cards: number;
  total_card_holders: number;
}

interface SessionHistoryType {
  id: string;
  charging_session_id: string;
  charge_point: string;
  start_time: string;
  end_time: string | null;
  initial_meter_value: string;
  final_meter_value: string | null;
  charging_rate: number | null;
  total_price: string;
  energy_consumed: string | null;
  duration: string;
  charging_session_status: string;
  user: string;
  id_tag: string;
}

export const accessControlApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalCards: builder.query<APIResponse<TotalCardsResponse>, void>({
      query: () => "private-drive/cards/total-number-of-cards/",
      providesTags: ["Cards"],
    }),
    getCards: builder.query<PaginatedResponse<CardResponse>, number>({
      query: (page) => `private-drive/cards/?page=${page}`,
      providesTags: ["Cards"],
    }),
    getRestrictionLimits: builder.query<
      PaginatedResponse<LimitResponse>,
      number
    >({
      query: (page) => `private-drive/access-profiles/limits/?page=${page}`,
      providesTags: ["Limits"],
    }),
    createCard: builder.mutation<APIResponse<CardResponse>, CreateCardRequest>({
      query: (values) => ({
        url: "private-drive/cards/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: (result) => [{ type: "Cards", id: result?.data?.id }],
    }),
    editCard: builder.mutation<APIResponse<CardResponse>, EditCardRequest>({
      query: (values) => ({
        url: `private-drive/cards/${values.id}`,
        method: "PUT",
        body: values,
      }),
      invalidatesTags: (result) => [{ type: "Cards", id: result?.data?.id }],
    }),
    getCardsByUserId: builder.query<
      PaginatedResponse<CardResponse>,
      PaginatedRequest & { id: string }
    >({
      query: (values) =>
        `private-drive/cards/private-user-cards/?private_drive_user_id=${values.id}&page=${values.page}`,
      providesTags: (_, __, id) => [{ type: "Cards", id: id.id }],
    }),
    getAccessProfiles: builder.query<
      PaginatedResponse<AccessProfileResponse>,
      number
    >({
      query: (page) => `private-drive/access-profiles/?page=${page}`,
      providesTags: ["AccessProfiles"],
    }),
    deleteAccessProfile: builder.mutation<void, string>({
      query: (id) => ({
        url: `private-drive/access-profiles/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["AccessProfiles"],
    }),
    getCardHolders: builder.query<
      PaginatedResponse<CardHolderResponse>,
      number
    >({
      query: (page = 1) => `private-drive/cards/users/?page=${page}`,
      providesTags: ["CardHolders"],
    }),
    createCardHolder: builder.mutation<
      APIResponse<CardHolderResponse>,
      CreateCardHolderRequest
    >({
      query: (values) => ({
        url: "private-drive/cards/users/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["CardHolders"],
    }),

    createRestrictionLimit: builder.mutation<
      APIResponse<LimitResponse>,
      CreateLimitRequest
    >({
      query: (values) => ({
        url: "private-drive/access-profiles/limits/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Limits"],
    }),
    editRestrictionLimit: builder.mutation<
      APIResponse<LimitResponse>,
      EditLimitRequest
    >({
      query: ({ id, ...values }) => ({
        url: `private-drive/access-profiles/${id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["AccessProfiles"],
    }),

    deleteRestrictionLimit: builder.mutation<void, string>({
      query: (id) => ({
        url: `private-drive/access-profiles/limits/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Limits"],
    }),
    deleteCardHolder: builder.mutation<void, string>({
      query: (id) => ({
        url: `private-drive/cards/users/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["CardHolders"],
    }),

    editCardHolder: builder.mutation<
      APIResponse<CardHolderResponse>,
      EditCardHolderRequest
    >({
      query: ({ id, ...values }) => ({
        url: `private-drive/cards/users/${id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["CardHolders"],
    }),

    createAccessProfile: builder.mutation<
      APIResponse<AccessProfileResponse>,
      CreateAccessProfileRequest
    >({
      query: (values) => ({
        url: "private-drive/access-profiles/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["AccessProfiles"],
    }),

    editAccessProfile: builder.mutation<
      APIResponse<AccessProfileResponse>,
      EditAccessProfileRequest
    >({
      query: ({ id, ...values }) => ({
        url: `private-drive/access-profiles/${id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["AccessProfiles"],
    }),

    disableCard: builder.mutation<APIResponse<CardResponse>, string>({
      query: (id) => ({
        url: `private-drive/cards/disable/${id}/`,
        method: "PATCH",
      }),
      invalidatesTags: ["Cards"],
    }),
    revokeCard: builder.mutation<APIResponse<CardResponse>, string>({
      query: (id) => ({
        url: `private-drive/cards/revoke/${id}/`,
        method: "PATCH",
      }),
      invalidatesTags: ["Cards"],
    }),
    getSessionsByUid: builder.query<
      APIResponse<PaginatedResponse<SessionHistoryType>>,
      PaginatedRequest & { uid: string }
    >({
      query: ({ uid, page = 1 }) =>
        `private-drive/charging-session/charging-sessions-by-user/${uid}?page=${page}`,
      providesTags: ["CardSessions"],
    }),
  }),
});

export const {
  useGetTotalCardsQuery,

  useGetCardsQuery,
  useCreateCardMutation,
  useEditCardMutation,
  useGetAccessProfilesQuery,
  useDeleteAccessProfileMutation,
  useGetCardHoldersQuery,
  useGetCardsByUserIdQuery,
  useCreateCardHolderMutation,
  useDisableCardMutation,
  useRevokeCardMutation,
  useGetRestrictionLimitsQuery,
  useCreateRestrictionLimitMutation,
  useCreateAccessProfileMutation,
  useEditAccessProfileMutation,
  useDeleteRestrictionLimitMutation,
  useEditRestrictionLimitMutation,
  useGetSessionsByUidQuery,
  useDeleteCardHolderMutation,
  useEditCardHolderMutation,
} = accessControlApi;
