import { useGetChargepointConnectorsQuery } from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

import { columns } from "./column";
import { ConnectorDataTable } from "./connector-data-table";

interface ConnectorTable {
  results: {
    connector_id: string;
    connector_format: string;
    connector_type: string;
    connector_status: string;
  }[];
}

interface ConnectorTableProperties {
  chargePointId?: string;
}
export const ConnectorTable: React.FC<ConnectorTableProperties> = ({
  chargePointId,
}) => {
  const { orgId } = useAppSelector((state) => state.auth);
  const {
    isLoading,
    data: connectorsData,
    isError,
    error,
  } = useGetChargepointConnectorsQuery(
    {
      orgId: orgId,
      page: 1,
      chargepointId: chargePointId,
    },
    { skip: !orgId }
  );

  const [loading, setLoading] = useState(false);
  const [connectors, setConnectors] = useState<ConnectorTable>();

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else if (connectorsData) {
      setConnectors(connectorsData.data);
      setLoading(false);
    }
  }, []);

  return (
    <div className="container w-full rounded-lg  border border-slate-400 p-6 text-white">
      <div className="mb-4">
        <h2 className="text-2xl font-bold">Connectors</h2>
      </div>
      <ConnectorDataTable
        data={connectors ? connectors.results : []}
        columns={columns}
        loading={isLoading}
      />
    </div>
  );
};
