import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { X } from "lucide-react";
import type { CardHoldersType } from "../schema";
import { DetailsCard } from "./details-card";
import { RFIDCardTableDetail } from "./rfid-card-table-detail";
import { SessionHistoryTableDetail } from "./session-history-table-detail";

type Props = {
  data: CardHoldersType;
  onClose: () => void;
};

export const ViewMoreCardHolder = ({ data, onClose }: Props) => {
  return (
    <>
      <Dialog open onOpenChange={onClose}>
        <DialogContent className="max-h-xl scrollbar-thin scrollbar-track-gray-600 max-h-[90%] max-w-[90%] border-gray-700 border overflow-scroll bg-background p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <h2>Driver Details</h2>
              <DialogClose asChild>
                <Button
                  onClick={() => {
                    onClose();
                  }}
                  variant="secondary"
                  type="button"
                >
                  <X size={16} color="black" />
                </Button>
              </DialogClose>
            </DialogTitle>
          </DialogHeader>

          <div className="flex gap-4">
            <Tabs
              defaultValue="session"
              className=" w-full space-y-2 bg-transparent"
            >
              <TabsList className="flex bg-slate-700">
                <TabsTrigger
                  className="h-full w-full flex-1 font-bold"
                  value="cardHolder"
                >
                  Card Holder
                </TabsTrigger>
                <TabsTrigger
                  className="h-full w-full flex-1 font-bold"
                  value="session"
                >
                  Session History
                </TabsTrigger>
              </TabsList>

              <TabsContent value="cardHolder">
                <DetailsCard data={data} />
              </TabsContent>
              <TabsContent value="session">
                <SessionHistoryTableDetail data={data} />
              </TabsContent>
            </Tabs>

            <RFIDCardTableDetail id={data?.id} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
