import {
  calculatePercentageChange,
  calculateRevenueChange,
} from "@/lib/helpers/calculate-revenue-change";
import type {
  ChargerLocationType,
  ChargingSessionType,
  KwhTodayType,
  TotalChargersType,
  TotalRevenueType,
  TotalUsageTodayType,
} from "@/types/home";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface HomeState {
  loading: boolean;
  error: string | null;
  data: any;
  globalCurrency: string;
  timeframe: {
    charging_hours: number;
    utilization_rate: number;
    max_charging_hours: number;
  } | null;
  weeklySessions: any | null;
  weeklyConsumption: any | null;
  chargingSession: ChargingSessionType | null;
  kwhToday: KwhTodayType | null;
  totalUsageToday: TotalUsageTodayType | null;
  totalChargers: TotalChargersType | null;
  totalRevenue: TotalRevenueType | null;
  chargerLocations: ChargerLocationType[] | null;
}

const initialState: HomeState = {
  data: {},
  loading: true,
  error: null,
  globalCurrency: "",
  timeframe: null,
  weeklySessions: null,
  weeklyConsumption: null,
  chargingSession: null,
  kwhToday: null,
  totalUsageToday: null,
  totalChargers: null,
  chargerLocations: null,
  totalRevenue: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    stopLoading: (state) => {
      state.loading = false;
      state.error = null;
    },
    setChargingSession: (state, action: PayloadAction<ChargingSessionType>) => {
      const chargingSessionChange = calculateRevenueChange(
        action.payload?.total_charging_sessions_today,
        action.payload?.total_charging_sessions_yesterday
      );

      state.chargingSession = { ...action.payload, ...chargingSessionChange };
      state.loading = false;
    },
    setKwhToday: (state, action: PayloadAction<KwhTodayType>) => {
      const kwhChange = calculateRevenueChange(
        action.payload?.today_total_energy_consumed_day,
        action.payload?.yesterday_total_energy_consumed
      );
      state.kwhToday = { ...action.payload, ...kwhChange };
      state.loading = false;
    },
    setTotalUsageToday: (state, action: PayloadAction<TotalUsageTodayType>) => {
      const totalUsageChange = calculatePercentageChange(
        action.payload?.usage_rate_change
      );
      state.totalUsageToday = { ...action.payload, ...totalUsageChange };
      state.loading = false;
    },
    setTotalChargers: (state, action: PayloadAction<TotalChargersType>) => {
      state.totalChargers = action.payload;
      state.loading = false;
    },
    setChargerLocations: (
      state,
      action: PayloadAction<ChargerLocationType[]>
    ) => {
      state.chargerLocations = action.payload?.map((charger: any) => ({
        ...charger,
        lat: charger.latitude,
        lng: charger.longitude,
      }));
      state.loading = false;
    },
    setTotalRevenue: (state, action: PayloadAction<TotalRevenueType>) => {
      const revenueChange = calculateRevenueChange(
        action.payload?.today_total_revenue,
        action.payload?.yesterday_total_revenue
      );
      state.totalRevenue = { ...action.payload, ...revenueChange };
      state.loading = false;
    },
    setWeeklySessions: (state, action) => {
      state.weeklySessions = action.payload;
      state.loading = false;
    },
    setTimeframe: (state, action) => {
      state.timeframe = action.payload.data;
      state.loading = false;
    },
    setWeeklyConsumptions: (state, action) => {
      state.weeklyConsumption = action.payload;
      state.loading = false;
    },
    setGlobalCurrency: (state, action) => {
      state.globalCurrency = action.payload;
      state.loading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setError,
  setWeeklySessions,
  setWeeklyConsumptions,
  setChargingSession,
  setKwhToday,
  setTotalUsageToday,
  setTotalChargers,
  setChargerLocations,
  setTotalRevenue,
  setTimeframe,
  setGlobalCurrency,
} = homeSlice.actions;

export default homeSlice.reducer;
