import { ChargerGroupSelect } from "@/components/charger-group-select";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { formatDateToYYYYMMDD } from "@/lib/helpers/dates";
import { cn } from "@/lib/utils/cn";
import { useLazyGetExportTransactionsQuery } from "@/redux/features/revenue/revenue.api";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Table } from "@tanstack/react-table";
import { addDays, format } from "date-fns";
import { Export } from "iconsax-react";
import { Calendar as CalendarIcon } from "lucide-react";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  charger_group: z.string({ required_error: "Charger group is required." }),
  time_frame: z
    .object({
      from: z.date({
        required_error: "Start date is required.",
        invalid_type_error: "Start date must be a valid date.",
      }),
      to: z.date({
        required_error: "End date is required.",
        invalid_type_error: "End date must be a valid date.",
      }),
    })
    .refine((data) => data.to >= data.from, {
      message: "End date must be after or the same as start date.",
      path: ["to"],
    }),
  format: z.enum(["csv", "pdf"], {
    required_error: "You need to select a file type.",
    invalid_type_error:
      "Notification type must be one of 'all', 'mentions', or 'none'.",
  }),
});

export function ExportTransactions() {
  const { orgId } = useAppSelector((state) => state.auth);

  const [exportTransactions] = useLazyGetExportTransactionsQuery();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const [open, setOpen] = useState(false);

  const hanleDateChange = (dateResult: DateRange) => {
    if (dateResult) {
      const initialFromDate = dateResult.from
        ? new Date(dateResult.from)
        : new Date();
      const fromDate = formatDateToYYYYMMDD(initialFromDate);
      const initialToDate = dateResult.to
        ? new Date(dateResult.to)
        : new Date();
      const toDate = formatDateToYYYYMMDD(initialToDate);

      return { fromDate, toDate };
    }
    return { fromDate: "", toDate: "" };
  };

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    const { fromDate, toDate } = hanleDateChange(values.time_frame);

    const result = {
      orgId,
      startDate: fromDate,
      endDate: toDate,
      format: values.format,
    };
    exportTransactions(result)
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        form.reset();
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error?.data?.error_message);
      });
  };

  return (
    <div className="flex justify-evenly gap-2">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <Button
            variant="outline"
            className="bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
          >
            <Export className="mr-2 h-4 w-4" />
            Export Transactions
          </Button>
        </DialogTrigger>
        <DialogContent className="max-h-xl p-4 max-w-md border-0 bg-background text-white shadow-2xl">
          <DialogHeader className=" ">
            <DialogTitle className="flex items-center justify-between">
              Export Transactions
            </DialogTitle>
            <DialogDescription>
              Export your transactions in PDF or CSV format.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              className=" space-y-3"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <FormField
                control={form.control}
                name="time_frame"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Time frame</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full gap-2 justify-start text-left font-normal bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon size={16} />
                          {field.value?.from ? (
                            field.value.to ? (
                              <>
                                {format(field.value.from, "LLL dd, y")} -{" "}
                                {format(field.value.to, "LLL dd, y")}
                              </>
                            ) : (
                              format(field.value.from, "LLL dd, y")
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
                        <Select
                          onValueChange={(value) =>
                            field.onChange({
                              from: addDays(new Date(), 0),
                              to: addDays(new Date(), Number.parseInt(value)),
                            })
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent position="popper">
                            <SelectItem value="30">One month</SelectItem>
                            <SelectItem value="60">Two months</SelectItem>
                            <SelectItem value="90">Three months</SelectItem>
                          </SelectContent>
                        </Select>
                        <div className="rounded-md border">
                          <Calendar
                            initialFocus
                            mode="range"
                            defaultMonth={field.value?.from}
                            selected={field.value}
                            onSelect={field.onChange}
                            numberOfMonths={2}
                          />
                        </div>
                      </PopoverContent>
                    </Popover>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />

              <ChargerGroupSelect />
              <FormField
                control={form.control}
                name="format"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Format</FormLabel>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="pdf" />
                          </FormControl>
                          <FormLabel className="font-normal">PDF </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="csv" />
                          </FormControl>
                          <FormLabel className="font-normal">CSV</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter>
                <DialogClose>
                  <Button type="button" variant="outline">
                    Cancel
                  </Button>
                </DialogClose>
                <Button type="submit" variant="secondary">
                  Export
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
