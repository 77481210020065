import { BsCircleFill } from "react-icons/bs";

export const statuses = [
  {
    value: "Completed",
    label: "Completed",
    icon: BsCircleFill,
  },
  {
    value: "Ongoing",
    label: "Ongoing",
    icon: BsCircleFill,
  },
  {
    value: "Failed",
    label: "Failed",
    icon: BsCircleFill,
  },
];

export const contributions = [
  {
    category: "HK1",
    value: 30.53,
    chargers: 13,
    revenue: 10_000,
  },
  {
    category: "RK2",
    value: 40.53,
    chargers: 13,
    revenue: 20_000,
  },
  {
    category: "HK1",
    value: 50.53,
    chargers: 13,
    revenue: 30_000,
  },
  {
    category: "HK1",
    value: 60.53,
    chargers: 13,
    revenue: 50_000,
  },
  {
    category: "HK1",
    value: 70.53,
    chargers: 13,
    revenue: 10_000,
  },
  {
    category: "HK1",
    value: 80.53,
    chargers: 13,
    revenue: 15_000,
  },
  {
    category: "HK1",
    value: 90.53,
    chargers: 13,
    revenue: 60_000,
  },
];
