export const organisations = [
  {
    id: 1,
    companyName: "Trjan & CO. manufacturing",
    companyLogo: "/images/organisation-card-1.png",
    devices: 220,
    modules: 30,
    users: 7000,
  },
  {
    id: 2,
    companyName: "ViVr. International Limited Company",
    companyLogo: "/images/organisation-card-1.png",
    devices: 780,
    modules: 90,
    users: 88_000,
  },
  {
    id: 3,
    companyName: "Somichan Stations. Ltd",
    companyLogo: "/images/organisation-card-1.png",
    devices: 122,
    modules: 15,
    users: 5500,
  },
  {
    id: 4,
    companyName: "T.E Electronics PLC",
    companyLogo: "/images/organisation-card-1.png",
    devices: 354,
    modules: 44,
    users: 12_000,
  },
];
