import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AccessProfileTab } from "./access-profile-tab";
import { CardHoldersTab } from "./card-holders-tab";

export const CardHoldersAndAccessProfileTab = () => {
  return (
    <div className="relative my-8 rounded-lg bg-gradient-linear py-4 text-white backdrop-blur-xl">
      <Tabs
        defaultValue="drivers"
        className="container space-y-6 bg-transparent"
      >
        <TabsList className="flex h-14 bg-slate-700">
          <TabsTrigger
            value="drivers"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Card Holders
          </TabsTrigger>
          <TabsTrigger
            value="roles"
            className="h-full w-full flex-1 text-xl font-bold"
          >
            Access Profiles
          </TabsTrigger>
        </TabsList>

        <TabsContent value="drivers">
          <CardHoldersTab />
        </TabsContent>
        <TabsContent value="roles" asChild>
          <AccessProfileTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};
