import { formatDate } from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";
import type { CardExpirationstype } from "./schema";

export const generateCards = (count: number): CardExpirationstype[] => {
  const cards: CardExpirationstype[] = [];

  for (let index = 0; index < count; index++) {
    const hasExpiration = faker.datatype.boolean();
    const expiryDate = hasExpiration ? faker.date.future() : null;

    cards.push({
      id: `CRD-${faker.number.int({ min: 10, max: 100 })}`,
      uid: faker.string.uuid(),
      cardName: faker.helpers.arrayElement([
        "Sales Manager",
        "Developer",
        "Driver",
        "Guest",
      ]),
      type: faker.helpers.arrayElement([
        "mifare_classic",
        "mifare_desfire",
        "mifare_plus",
      ]),
      accessProfile: faker.name.fullName(),
      hasExpiration: hasExpiration,
      expirationDate: expiryDate,
    });
  }

  return cards;
};
