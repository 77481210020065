import { donutChartOptions } from "@/common/variables/charts";
import PieChart from "@/components/ui/pie-chart";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useGetTimeFrameUtilizationQuery } from "@/redux/features/home/home.api";
import { setTimeframe, startLoading } from "@/redux/features/home/home.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect, useState } from "react";

export const UtilizationRatio = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const { timeframe, loading } = useAppSelector((state) => state.home);
  const dispatch = useAppDispatch();

  const [date, setDate] = useState(30);

  const { data, isLoading } = useGetTimeFrameUtilizationQuery(
    {
      org_id: orgId,
      timeframe: date,
    },
    {
      skip: !orgId,
    },
  );

  const handleChangeTimeframe = (newValue: any) => {
    setDate(newValue);
  };
  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setTimeframe(data));
    }
  }, [timeframe, data, isLoading]);

  return (
    <div className="relative flex w-full flex-col items-center gap-7 rounded-xl bg-gradient-linear p-6 backdrop-blur-xl">
      {loading ? (
        <div className="flex w-full flex-col items-center justify-center gap-3">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[100px]" />
            <Skeleton className="h-10 w-[150px]" />
          </div>
          <div>
            <Skeleton className="h-[325px] w-[325px] rounded-full" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full items-center justify-between">
            <div className="font-extralight text-[#D1D1D1]">
              Utilization Ratio
            </div>
            <Select onValueChange={handleChangeTimeframe} defaultValue="30">
              <SelectTrigger className="w-[180px]">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="30">Monthly</SelectItem>
                  <SelectItem value="7">Weekly</SelectItem>
                  <SelectItem value="1">Daily</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="min-h-[325px]">
            <PieChart
              type="donut"
              chartData={
                timeframe
                  ? [
                      timeframe.utilization_rate * 100,
                      100 - timeframe.utilization_rate * 100,
                    ]
                  : []
              }
              chartOptions={donutChartOptions(["active", "idle"])}
            />
          </div>
        </>
      )}
    </div>
  );
};
