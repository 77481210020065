import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export const UserAvatar = ({
  firstName,
  lastName,
  image,
  className,
}: {
  firstName?: string | undefined;
  lastName?: string | undefined;
  image?: string | undefined;
  className?: string;
}) => {
  return (
    <Avatar className={className}>
      <AvatarImage src={image} />
      <AvatarFallback>
        {firstName?.charAt(0)}
        {lastName?.charAt(0)}
      </AvatarFallback>
    </Avatar>
  );
};
