import { formatDate } from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";

import { FaCar, FaCode, FaUser, FaUserTie } from "react-icons/fa";
import type { AccessProfileType } from "./schema";

export const rolesName = [
  {
    value: "sales manager",
    label: "Sales Manager",
    icon: FaUserTie,
  },
  {
    value: "developer",
    label: "Developer",
    icon: FaCode,
  },
  {
    value: "driver",
    label: "Driver",
    icon: FaCar,
  },
  {
    value: "guest",
    label: "Guest",
    icon: FaUser,
  },
];

export const accessLevel = [
  {
    value: "administrator",
    label: "Administrator",
    icon: FaUserTie,
  },
  {
    value: "manager",
    label: "Manager",
    icon: FaCode,
  },
  {
    value: "analyst",
    label: "Analyst",
    icon: FaCar,
  },
  {
    value: "developer",
    label: "Developer",
    icon: FaUser,
  },
  {
    value: "guest",
    label: "Guest",
    icon: FaUser,
  },
];

export const generateAccessProfiles = (count: number) => {
  const accessProfiles: AccessProfileType[] = [];
  for (let index = 0; index < count; index++) {
    const accessProfile = {
      id: `RLE-${faker.number.int({ min: 10, max: 100 })}`,
      profileName: faker.helpers.arrayElement([
        "sales manager",
        "developer",
        "driver",
        "guest",
      ]),
      accessLevel: faker.helpers.arrayElement([
        "administrator",
        "manager",
        "analyst",
        "developer",
        "guest",
      ]),
      profileExpirationDate: formatDate(faker.date.recent().toString()),
      totalActiveRFIDCards: faker.number.int({ min: 50, max: 100 }),
    };
    accessProfiles.push(accessProfile);
  }

  return accessProfiles;
};
