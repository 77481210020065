import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils/cn";
import { CreateChargePoint } from "@/pages/(dashboard)/ChargerNetwork/components/charger-table/create-charge-point";
import { useGetChargePointsQuery } from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { ChartCircle, CloseCircle } from "iconsax-react";
import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { ArrowUpRight, UserPlus } from "react-feather";

interface ChargePointType {
  id: string;
  name: string;
}

export function ChargePointMultiSelect({
  defaultValue,
}: {
  defaultValue?: string;
}) {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const [chargersDataResult, setChargersDataResult] = useState<
    ChargePointType[]
  >([]);
  const [selectedChargers, setSelectedChargers] = useState<ChargePointType[]>(
    []
  );
  const { data, isFetching } = useGetChargePointsQuery({
    orgId: orgId,
    page: page,
  });

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data?.data?.results) {
      setChargersDataResult((prevChargers) => [
        ...prevChargers,
        ...data.data.results
          .filter(
            (newCharger) =>
              !prevChargers.some((charger) => charger.id === newCharger.id)
          )
          .map((charger) => ({
            id: charger.id,
            name: charger.name,
          })),
      ]);
    }
  }, [data]);

  const filteredChargers = chargersDataResult?.filter(
    (charger: ChargePointType) =>
      charger.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const loadMoreData = () => {
    if (data?.data?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const toggleChargerSelection = (
    charger: ChargePointType,
    fieldOnChange: (value: { [key: string]: string }) => void
  ) => {
    setSelectedChargers((prevSelected: ChargePointType[]) => {
      let updatedChargers: ChargePointType[];
      if (prevSelected.includes(charger)) {
        updatedChargers = prevSelected.filter((prev) => prev.id !== charger.id);
      } else {
        updatedChargers = [...prevSelected, charger];
      }

      const result = updatedChargers.reduce(
        (acc: { [key: string]: string }, charger) => {
          acc[charger.name] = charger.id;
          return acc;
        },
        {}
      );

      fieldOnChange(result);

      return updatedChargers;
    });
  };

  return (
    <FormField
      name="access_to_chargers"
      render={({ field }) => {
        useEffect(() => {
          if (field.value) {
            const defaultSelectedCharger = Object.entries(field.value).map(
              ([name, id]) => ({ id: id as string, name })
            );
            setSelectedChargers((prev) => {
              const newChargers = defaultSelectedCharger.filter(
                (newCharger) =>
                  !prev.some((charger) => charger.id === newCharger.id)
              );
              return [...prev, ...newChargers];
            });
          }
        }, [field.value]);
        return (
          <FormItem>
            <FormLabel className=" whitespace-nowrap">
              Access to Charge Points
            </FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <FormControl>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-end bg-gray-800 border-gray-700 text-gray-100"
                    disabled={isFetching}
                  >
                    {selectedChargers.length > 0 ? (
                      <TooltipProvider delayDuration={300}>
                        <div className="flex flex-1 overflow-x-hidden flex-wrap items-center gap-1.5">
                          {selectedChargers.slice(0, 2).map((value) => (
                            <Tooltip key={value.id}>
                              <TooltipTrigger className="inline-flex">
                                <div className="py-1 px-2 rounded-lg bg-[#000000] flex items-center gap-1 text-xs">
                                  <span>
                                    {value.name.length > 6
                                      ? `${value.name.slice(0, 6)}...`
                                      : value.name}
                                  </span>
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleChargerSelection(
                                        value,
                                        field.onChange
                                      );
                                    }}
                                    className={cn(
                                      "py-1 px-0 h-full hover:bg-transparent rounded-full "
                                    )}
                                  >
                                    <CloseCircle size="16" variant="Bulk" />
                                  </Button>
                                </div>
                              </TooltipTrigger>
                              <TooltipContent
                                side="bottom"
                                align="start"
                                className="z-[51]"
                              >
                                {value.name}
                              </TooltipContent>
                            </Tooltip>
                          ))}

                          {selectedChargers.length > 2 && (
                            <div className="py-1 px-2 rounded-lg bg-[#000000] flex items-center gap-1 text-xs">
                              <span>{`+${
                                selectedChargers.length - 2
                              } more`}</span>
                            </div>
                          )}
                        </div>
                      </TooltipProvider>
                    ) : null}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
              </FormControl>
              <PopoverContent className="w-[450px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search charge points..."
                    value={searchQuery}
                    onInput={(e) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <div className="relative p-2  flex gap-2">
                    <CreateChargePoint />
                    <Button
                      type="button"
                      variant="outline"
                      className=" w-full bg-gray-800 whitespace-nowrap text-gray-200 gap-1 border-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      View Chargers
                      <ArrowUpRight className="mr-2 h-4 w-4" />
                    </Button>
                  </div>

                  <CommandList>
                    {isFetching ? (
                      <div className="flex justify-center py-2">
                        <ChartCircle
                          className="animate-spin"
                          size={20}
                          color="#FF8A65"
                        />
                      </div>
                    ) : filteredChargers?.length > 0 ? (
                      <>
                        <ScrollArea className="h-[300px] overflow-auto">
                          <CommandGroup className="text-white h-[300px] ">
                            {filteredChargers.map(
                              (charger: ChargePointType) => (
                                <CommandItem
                                  key={charger.id}
                                  value={charger.id}
                                  onSelect={() => {
                                    toggleChargerSelection(
                                      charger,
                                      field.onChange
                                    );
                                  }}
                                >
                                  <Check
                                    className={
                                      selectedChargers.includes(charger)
                                        ? "mr-2 h-4 w-4 opacity-100"
                                        : "mr-2 h-4 w-4 opacity-0"
                                    }
                                  />
                                  {charger.name}
                                </CommandItem>
                              )
                            )}
                          </CommandGroup>
                        </ScrollArea>
                        {data?.data?.next && !isFetching && (
                          <div className="relative p-2 flex">
                            <Button
                              type="button"
                              variant="outline"
                              className="w-full bg-gray-800 m-2 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                              onClick={loadMoreData}
                            >
                              Load more
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <CommandEmpty>No Charge Point found.</CommandEmpty>
                        {data?.data?.next && !isFetching && (
                          <div className="relative p-2 ">
                            <Button
                              type="button"
                              variant="outline"
                              className="w-full bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                              onClick={loadMoreData}
                            >
                              Load more
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
