import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { CameraIcon } from "lucide-react";
import { useCallback } from "react";
import { type DropzoneOptions, useDropzone } from "react-dropzone";

type Properties = { form: any };

export const ProfileImage = ({ form }: Properties) => {
  const [profile_picture] = form.watch(["profile_picture_preview"]);
  const onDrop = useCallback<NonNullable<DropzoneOptions["onDrop"]>>(
    (acceptedFiles) => {
      for (const file of acceptedFiles) {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            const imageAsBase64 = event.target.result.toString();
            const image = new Image();
            image.src = imageAsBase64;
            image.addEventListener("load", () => {
              const canvas = document.createElement("canvas");
              canvas.width = 128;
              canvas.height = 128;
              const context = canvas.getContext("2d", { alpha: false });
              if (context) {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                const resizedImageAsBase64 = canvas.toDataURL("image/webp");
                form.setValue("profile_picture", acceptedFiles[0]);
                form.setValue("profile_picture_preview", resizedImageAsBase64);
              }
            });
          }
        });
        reader.readAsDataURL(file);
      }
    },
    [form],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/jpeg": [".jpeg", ".jpg"] },
    maxFiles: 1,
  });

  return (
    <FormField
      control={form.control}
      name="profile_picture"
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="text-right text-sm font-light text-gray-400">
            Organisation Profile Picture/Logo
          </FormLabel>
          <FormControl>
            <div>
              <div
                {...getRootProps()}
                className="transition-background relative h-52 w-52 cursor-pointer overflow-hidden rounded-full bg-zinc-800 duration-150 hover:bg-accent"
              >
                <input {...getInputProps()} />
                <div className="absolute inset-0 flex items-center justify-center">
                  <CameraIcon
                    className={`h-6 w-6 text-muted-foreground ${
                      profile_picture && "hidden"
                    }`}
                  />
                </div>
                {profile_picture ? (
                  <img
                    className="h-full w-full"
                    alt="icon"
                    src={profile_picture}
                    height={200}
                    width={200}
                  />
                ) : null}
              </div>
            </div>
          </FormControl>
          <FormMessage className="text-[10px]" />
        </FormItem>
      )}
    />
  );
};
