import { PageLoader } from "@/components/page-loader";
import { Sidebar } from "@/components/sidebar";
import {
  extractCountryCode,
  extractCurrencySymbol,
} from "@/lib/helpers/country-code-currency";
import { useGetUserProfileQuery } from "@/redux/features/auth/auth.api";
import {
  setOrgId,
  setUser,
  startLoading,
} from "@/redux/features/auth/auth.slice";
import { setGlobalCurrency } from "@/redux/features/home/home.slice";
import { useGetOrganizationsQuery } from "@/redux/features/org/org.api";
import { OrgType, setOrganisations } from "@/redux/features/org/org.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import type React from "react";
import { Suspense, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export const Main = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => !!state.auth.user);
  const { currentOrg } = useAppSelector((state) => state.org);
  const { loading } = useAppSelector((state) => state.home);
  const { orgId, user } = useAppSelector((state) => state.auth);
  const [dataState, setDataState] = useState("close");

  const { data: organizationsData, isLoading: organiszationsLoading } =
    useGetOrganizationsQuery(undefined, { skip: !!orgId });
  const { data, isLoading } = useGetUserProfileQuery(undefined, {
    skip: !user,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(setUser(data));
    }

    if (organiszationsLoading) {
      dispatch(startLoading());
    } else if (organizationsData) {
      dispatch(
        setOrgId(
          organizationsData.data.find(
            (data: OrgType) => data.org_type === "Private"
          )?.id
        )
      );
      dispatch(setOrganisations(organizationsData.data));
    }
  }, [data, dispatch, isLoading, organizationsData, organiszationsLoading]);

  useEffect(() => {
    if (currentOrg) {
      const countryCode = extractCountryCode(currentOrg.org_phone_number);
      if (countryCode) {
        const currencySymbol = extractCurrencySymbol(countryCode);
        dispatch(setGlobalCurrency(currencySymbol));
      } else {
        dispatch(setGlobalCurrency("$"));
      }
    }
  }, [currentOrg, dispatch]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <div
        data-state={dataState}
        className="flex h-full w-full flex-col pb-[90px] transition-all data-[state=close]:pl-14 md:pb-0 md:pl-[260px]"
      >
        <Sidebar
          className="overflow-y-scroll"
          dataState={dataState}
          setDataState={setDataState}
        />

        <div className="scrollbar-thin scrollbar-thumb-gray-900 flex-1 overflow-y-scroll">
          <div className="space-y-6">
            <div
              style={{
                backgroundImage: "url(/images/--gf-dashboard-cover.png)",
                backgroundSize: "100% 270px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container space-y-6 -mb-10 pt-4 md:pt-9">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};
