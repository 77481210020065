import { Separator } from "@/components/ui/separator";
import { BsFillClockFill, BsPerson, BsPlug } from "react-icons/bs";

interface Properties {
  data: {
    cost: number;
    payment_method: string;
    payment_status: string;
    transaction_reference_number: string;
  };
}

export const CostDetails = ({ data }: Properties) => {
  return (
    <div className="h-full flex-1 transform space-y-4 rounded-lg bg-black/50 p-6 text-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between">
        <h2 className="flex items-center text-lg font-semibold">
          <BsFillClockFill className="mr-4 text-yellow-500" />
          Cost Details
        </h2>
        {/* <Button className="bg-blue-500 hover:bg-blue-600">
					<BsDownload className="mr-2" />
					Download Receipt
				</Button> */}
      </div>
      <Separator className="h-[1px] bg-gray-500" />
      <div className="">
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-bold">
                Cost breakdown of this session:
              </span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                ${data.cost}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPerson className="mr-4 text-green-500" />
            <div>
              <span className="text-sm font-semibold">Payment method:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.payment_method}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">Payment Status:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.payment_status}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">
                transaction reference number:
              </span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.transaction_reference_number}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

// Cost Details Section:
//  Display a cost breakdown of the session,
//  payment method,
//  payment status,
//  transaction reference number with an option to download the receipt.
