import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  useCreateAccessProfileMutation,
  useEditAccessProfileMutation,
} from "@/redux/features/access-control/access-control.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { Plus } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { ChargePointMultiSelect } from "./charge-point-multi-select";
import { ChargerGroupMultiSelect } from "./charger-group-multi-select";
import { RestrictionLimitSelect } from "./restriction-limit-select";
import type { EditAccessProfileType } from "./schema";

export const formSchema = z.object({
  name: z
    .string()
    .min(1, "Name is required")
    .max(255, "Name cannot exceed 255 characters"),
  description: z
    .string()
    .max(500, "Description cannot exceed 500 characters")
    .nullable(),
  access_to_charger_groups: z.record(
    z
      .string()
      .min(1, { message: "At least one charger group must be selected." }),
    z.string()
  ),
  access_to_chargers: z.record(
    z
      .string()
      .min(1, { message: "At least one charge point must be selected." }),
    z.string()
  ),
  restriction_limit_id: z.string().min(1, "Rescriction Limit is required"),
  access_to_organization: z.string(),
});

export const CreateAccessProfile = ({
  data,
  onClose,
}: {
  data?: EditAccessProfileType;
  onClose?: (() => void) | undefined;
}) => {
  const { orgId } = useAppSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(!!data);

  const defaultValues = data
    ? {
        name: data?.name ?? "",
        description: data?.description ?? "",
        access_to_charger_groups: data?.access_to_charger_groups ?? "",
        access_to_chargers: data?.access_to_chargers ?? "",
        restriction_limit_id: data?.restriction_limit_id ?? "",
        access_to_organization: orgId,
      }
    : {
        name: "",
        description: "",
        access_to_charger_groups: {},
        access_to_chargers: {},
        restriction_limit_id: "",
        organization_id: orgId,
        access_to_organization: orgId,
      };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  const [createProfile, { isLoading: createProfileLoading }] =
    useCreateAccessProfileMutation();
  const [editProfile, { isLoading: editProfileLoading }] =
    useEditAccessProfileMutation();

  const handleSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      if (data) {
        if (isDirty) {
          await editProfile({ ...values, id: data.id })
            .unwrap()
            .then((response) => {
              form.reset();
              toast.success(response.message);
              setIsOpen(false);
            })
            .catch((error) => {
              toast.error(error?.data?.error_message);
            });
        } else {
          toast.error("Please edit access profile before saving.");
          return;
        }
      } else {
        await createProfile({ ...values, organization_id: orgId })
          .unwrap()
          .then((response) => {
            form.reset();
            toast.success(response.message);
            setIsOpen(false);
          })
          .catch((error) => {
            toast.error(error?.data?.error_message);
          });
      }
    },
    [createProfile, data, editProfile, form, isDirty, orgId]
  );

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 whitespace-nowrap text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Plus className="mr-2 h-4 w-4 shrink-0" />
          Create New Access Profile
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-lg p-2 bg-gray-900 bg-background text-gray-100 border-gray-700">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold ">
            Create Access Profile
          </DialogTitle>
          <DialogDescription className="text-gray-400">
            Set optional maximum charging session durations and energy
            consumption for RFID cards
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="bg-gray-800 border-gray-700 text-gray-100"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ChargerGroupMultiSelect />
            <ChargePointMultiSelect />
            <RestrictionLimitSelect />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        className="bg-gray-800 border-gray-700 text-gray-100 resize-none"
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                  </FormItem>
                  <FormMessage className="text-right text-[10px]" />
                </>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="outline"
                  className="w-full bg-gray-800 hover:bg-gray-700 text-gray-200"
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button
                disabled={editProfileLoading || createProfileLoading}
                type="submit"
                variant="secondary"
                className="w-full "
              >
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
