import { DeletePrompt } from "@/components/delete-prompt";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

type Property = {};
export function MeMemberCardAction({}: Property) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[200px]">
        <DeletePrompt
          id=""
          onDelete={() => {}}
          title="Are you absolutely sure you want to remove this invitation?"
          warningText="This action cannot be undone."
          buttonText="Continue"
        >
          <Button className="flex w-full justify-between gap-2 bg-transparent text-red-500">
            Leave team
          </Button>
        </DeletePrompt>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
