import { api } from "@/redux/api";

interface GetOrgResponse {
  message: string;
  status: string;
  status_code: number;
  data: {
    id: string;
    org_id: string;
    org_name: string;
    org_type: "Multi-user" | "Private";
    org_profile_picture: string | undefined;
    org_latitude: number;
    org_longitude: number;
    org_phone_number: string;
    org_address: string;
  }[];
}


export const orgApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createNewOrganisation: builder.mutation({
      query: (values) => ({
        url: "core/organization/new-org/",
        method: "POST",
        body: values,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    editOrganisation: builder.mutation({
      query: ({ values, orgId }) => ({
        url: `core/organization/org/${orgId}`,
        method: "PATCH",
        body: values,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    deleteOrganisation: builder.mutation({
      query: (orgId) => ({
        url: `core/organization/org/${orgId}`,
        method: "DELETE",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(orgApi.util.invalidateTags(["organizations"]));
        }, 1000);
      },
    }),
    getOrganizations: builder.query<GetOrgResponse, void>({
      query: () => "core/organization/list-user-orgs",
      providesTags: ["organizations"],
    }),
    getTeamMembers: builder.query({
      query: (arguments_: { orgId: string; page: number }) =>
        `core/organization/members/${arguments_.orgId}?page=${arguments_.page}`,
      providesTags: ["TeamMembers"],
    }),
    getInvitations: builder.query({
      query: (arguments_: { orgId: string; page: number }) =>
        `core/organization/invites/${arguments_.orgId}?page=${arguments_.page}&accepted=false`,
      providesTags: ["Invites"],
    }),
    sendInvitations: builder.mutation({
      query: (arguments_: {
        orgId: string | null;
        frontend_url: string;
        invites: any;
      }) => ({
        url: `core/organization/generateInvites/${arguments_.orgId}`,
        method: "POST",
        body: {
          frontend_url: arguments_.frontend_url,
          invites: arguments_.invites,
        },
      }),
      invalidatesTags: ["Invites"],
    }),
    removeUserFromOrg: builder.mutation({
      query: ({ orgId, userId }) => ({
        url: `core/organization/members/remove/${orgId}?member_id=${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TeamMembers"],
    }),
    deleteInvitation: builder.mutation({
      query: (id) => ({
        url: `core/organization/invites/delete/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invites"],
    }),
    resendInvitation: builder.mutation({
      query: (arguments_: { id: string; frontend_url: string }) => ({
        url: `core/organization/invites/resend/${arguments_.id}/`,
        method: "PATCH",
        body: { frontend_url: arguments_.frontend_url },
      }),
      invalidatesTags: ["Invites"],
    }),
  }),
});

export const {
  useCreateNewOrganisationMutation,
  useGetOrganizationsQuery,
  useGetInvitationsQuery,
  useGetTeamMembersQuery,
  useSendInvitationsMutation,
  useDeleteInvitationMutation,
  useResendInvitationMutation,
  useRemoveUserFromOrgMutation,
  useDeleteOrganisationMutation,
  useEditOrganisationMutation,
} = orgApi;
