type Properties = {};

export const PageLoader = (properties: Properties) => {
  return (
    <div className="absolute inset-0 z-50 h-screen w-full bg-black bg-opacity-30 backdrop-blur-sm">
      <div
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
        aria-label="Pocket Lawyers"
      >
        <img
          className="animate-bounce"
          src="/images/logo.png"
          alt="logo"
          height={128}
          width={122}
        />
      </div>
    </div>
  );
};
