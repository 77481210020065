import { Auth, Main, Organisation } from "@/layout";
import {
  AcceptInvite,
  AccessControl,
  ChargerNetwork,
  CheckEmail,
  CreateNewPasssword,
  ForgotPassword,
  Home,
  Login,
  Organisations,
  Revenue,
  Settings,
  Settlements,
  Signup,
  TariffManagement,
  VerifyEmail,
} from "@/pages";

export const appRoutes = [
  {
    path: "/revenue",
    element: (
      <Main>
        <Revenue />
      </Main>
    ),
  },
  {
    path: "/revenue/settlements",
    element: (
      <Main>
        <Settlements />
      </Main>
    ),
  },
  {
    path: "/charger-network",
    element: (
      <Main>
        <ChargerNetwork />
      </Main>
    ),
  },
  {
    path: "/settings",
    element: (
      <Main>
        <Settings />
      </Main>
    ),
  },
  {
    path: "/",
    element: (
      <Main>
        <Home />
      </Main>
    ),
  },
  {
    path: "/acceptInvitation",
    element: <AcceptInvite />,
  },
  {
    path: "/tariff-management",
    element: (
      <Main>
        <TariffManagement />
      </Main>
    ),
  },
  {
    path: "/access-control",
    element: (
      <Main>
        <AccessControl />
      </Main>
    ),
  },
  {
    path: "/login",
    element: (
      <Auth>
        <Login />
      </Auth>
    ),
  },
  {
    path: "/signup",
    element: (
      <Auth>
        <Signup />
      </Auth>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Auth>
        <ForgotPassword />
      </Auth>
    ),
  },
  {
    path: "/forgot-password/check-email",
    element: (
      <Auth>
        <CheckEmail />
      </Auth>
    ),
  },
  {
    path: "/create-new-password",
    element: (
      <Auth>
        <CreateNewPasssword />
      </Auth>
    ),
  },
  {
    path: "/organisations",
    element: (
      <Organisation>
        <Organisations />
      </Organisation>
    ),
  },
  {
    path: "/signup/verify-email",
    element: (
      <Auth>
        <VerifyEmail />
      </Auth>
    ),
  },
];
