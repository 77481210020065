type Properties = {};

const CheckEmail = (properties: Properties) => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="h-3/4 w-1/2 rounded-3xl bg-gradient-login p-6 backdrop-blur-xl">
        <div
          style={{
            backgroundImage: "url(/animations/check-email.svg)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          className="relative flex h-full items-center justify-center"
        >
          <div></div>
          <div className="flex- flex flex-col items-center justify-center gap-4 rounded-xl bg-black bg-opacity-20 p-4 backdrop-blur-xl">
            <img src="/icons/check-email-2.png" className="h-32 w-32" />

            <h1 className="text-6xl font-bold">Check your email</h1>
            <p className="text-xs font-light text-slate-200">
              We have sent a password recovery instruction to you email.
              password.
            </p>
            {/* <div className="flex flex-col items-center gap-4 w-full">
							<Button className="bg-[#4e4e4e] rounded-[16px] w-full md:w-[60%] ">
								Open Email
							</Button>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
