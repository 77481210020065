import type { DeltaType } from "@/common/constants/enums";
import { cn } from "@/lib/utils/cn";

import DeltaBadge from "./ui/delta-badge";
import Sparkline from "./ui/sparkline";

interface OveviewCardProperties {
  title: string;
  metric: string | number;
  deltaValue: number;
  deltaType: DeltaType | "default";
  dayTime?: string;
  data: number[];
  onClick?: () => void;
  className?: string;
}

export const OverviewCard: React.FC<OveviewCardProperties> = ({
  title,
  data,
  metric,
  deltaValue,
  deltaType,
  dayTime,
  onClick,
  className,
}) => {
  const width = 200;
  const height = 50;
  const smoothness = 0.1;
  const lineThickness = 4;
  return (
    <div
      onClick={onClick}
      className={cn(
        "relative w-full gap-7 overflow-hidden rounded-xl bg-gradient-linear backdrop-blur-xl 2xl:w-max",
        className,
      )}
    >
      <div className="flex h-full flex-col items-center p-6 xl:flex-row">
        <div className="flex h-full flex-col justify-between gap-3 py-8">
          <div>
            <p className="font-extralight text-[#D1D1D1]">{title}</p>
          </div>
          <div className={cn("visible relative xl:invisible xl:h-0")}>
            <div className="w-32">
              <Sparkline
                data={data}
                width={width}
                height={height}
                smoothness={smoothness}
                lineThickness={lineThickness}
              />
            </div>
          </div>
          <div className="text-2xl font-bold text-white">{metric}</div>
          <div className="flex items-center gap-2">
            <DeltaBadge type={deltaType} value={deltaValue} />
            <span className="whitespace-nowrap capitalize text-[##D1D1D1]">
              from {dayTime}
            </span>
          </div>
        </div>
        <div className="invisible relative h-0 xl:visible xl:h-auto">
          <div className="w-32">
            <Sparkline
              data={data}
              width={width}
              height={height}
              smoothness={smoothness}
              lineThickness={lineThickness}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
