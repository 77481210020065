import { cn } from "@/lib/utils/cn";
import { BatteryLow } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { FaBolt } from "react-icons/fa";

export const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: {
  items: {
    chargerId: string;
    utilization: number;
  }[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}) => {
  const containerReference = useRef<HTMLDivElement>(null);
  const scrollerReference = useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerReference.current && scrollerReference.current) {
      const scrollerContent = [...scrollerReference.current.children];

      for (const item of scrollerContent) {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerReference.current) {
          scrollerReference.current.append(duplicatedItem);
        }
      }

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerReference.current) {
      if (direction === "left") {
        containerReference.current.style.setProperty(
          "--animation-direction",
          "forwards",
        );
      } else {
        containerReference.current.style.setProperty(
          "--animation-direction",
          "reverse",
        );
      }
    }
  };
  const getSpeed = () => {
    if (containerReference.current) {
      if (speed === "fast") {
        containerReference.current.style.setProperty(
          "--animation-duration",
          "20s",
        );
      } else if (speed === "normal") {
        containerReference.current.style.setProperty(
          "--animation-duration",
          "40s",
        );
      } else {
        containerReference.current.style.setProperty(
          "--animation-duration",
          "80s",
        );
      }
    }
  };
  return (
    <div
      ref={containerReference}
      className={cn(
        "scroller relative z-20 max-w-7xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className,
      )}
    >
      <ul
        ref={scrollerReference}
        className={cn(
          "flex w-max min-w-full shrink-0 flex-nowrap gap-4 py-4",
          start && "animate-scroll",
          pauseOnHover && "hover:[animation-play-state:paused]",
        )}
      >
        {items.map((item, index) => (
          <li
            className="relative w-[350px] max-w-full flex-shrink-0 rounded-2xl border border-b-0 border-slate-700 px-8 py-6 md:w-[350px]"
            style={{
              background:
                "linear-gradient(180deg, rgb(30 41 59) ,  rgb(15 23 42 )",
            }}
            key={item.chargerId}
          >
            <div className="flex items-center gap-3">
              {item.utilization > 20 ? (
                <FaBolt
                  size={45}
                  className="ml-2 inline-block animate-pulse text-yellow-300"
                />
              ) : (
                <BatteryLow
                  size={45}
                  className="ml-2 inline-block animate-pulse text-red-500"
                />
              )}
              <div>
                <span className="relative z-20 text-sm font-normal leading-[1.6] text-gray-100">
                  Charger ID - {item.chargerId}
                </span>
                <div className="relative z-20 mt-6 flex flex-row items-center">
                  <span className="flex flex-col gap-1">
                    <span className="text-base font-bold leading-[1.6] text-gray-400">
                      {item.utilization}%
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
