import { AmountRow } from "@/components/amount-row";
import { Checkbox } from "@/components/ui/checkbox";
import type { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import { extractFirstId } from "../../../../../lib/helpers/extract-first-id";
import type { TariffPlan } from "../../data/schema";
import { Actions } from "./actions";
import { ColumnHeader } from "./column-header";

export const columns: ColumnDef<TariffPlan>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="truncate text-xs font-medium">
            {row.getValue("name")}
          </span>
        </div>
      );
    },
  },
  // {
  // 	accessorKey: 'dateCreated',
  // 	header: ({ column }) => (
  // 		<ColumnHeader column={column} title="Date Created" />
  // 	),
  // 	cell: ({ row }) => (
  // 		<div className="w-[200px]">{formatDate(row.getValue('dateCreated'))}</div>
  // 	),
  // 	filterFn: (row, id, value) => {
  // 		return value.includes(row.getValue(id));
  // 	},
  // },
  {
    accessorKey: "hourly_rate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Hourly rate" />
    ),
    cell: ({ row }) => <AmountRow amount={row.getValue("hourly_rate")} />,
    // filterFn: (row, hourly_rate, value) => {
    //   return value.includes(row.getValue(hourly_rate));
    // },
  },
  {
    accessorKey: "kwh_rate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="kWh rate" />
    ),
    cell: ({ row }) => <AmountRow amount={row.getValue("kwh_rate")} />,
    filterFn: (row, kwh_rate, value) => {
      return value.includes(row.getValue(kwh_rate));
    },
  },
  {
    accessorKey: "fixed_price",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Fixed rate" />
    ),
    cell: ({ row }) => <AmountRow amount={row.getValue("fixed_price")} />,
    filterFn: (row, fixed_price, value) => {
      return value.includes(row.getValue(fixed_price));
    },
  },

  {
    accessorKey: "currency",
    header: ({ column }) => <ColumnHeader column={column} title="Currency" />,
    cell: ({ row }) => (
      <div className="text-xs">{row.getValue("currency")}</div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "minimum_threshold_price",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Min. Threshold Price" />
    ),
    cell: ({ row }) => (
      <AmountRow amount={row.getValue("minimum_threshold_price")} />
    ),
    enableSorting: true,
    enableHiding: false,
  },

  // {
  // 	id: 'applyTo',
  // 	cell: ({ row }) => <ApplyTo tariffId={row.getValue('id')} />,
  // },
  {
    id: "actions",
    cell: ({ row }) => <Actions row={row} />,
  },
];
