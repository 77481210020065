import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useState } from "react";
import type { TransactionsType } from "../../types";
import ViewMoreTransactionDetails from "./details-modal/view-more-transaction-details";

interface Props<TData> {
  row: Row<TData>;
}

export function Actions<TData>({ row }: Props<TData>) {
  const [viewMoreData, setViewMoreData] = useState<TransactionsType | null>(
    null
  );
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem
            onClick={() => setViewMoreData(row.original as TransactionsType)}
          >
            View more
          </DropdownMenuItem>
          <DropdownMenuItem>Print</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {viewMoreData && (
        <ViewMoreTransactionDetails
          id={viewMoreData?.id}
          onClose={() => setViewMoreData(null)}
        />
      )}
    </>
  );
}
