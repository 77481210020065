import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useDeleteCardHolderMutation } from "@/redux/features/access-control/access-control.api";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { toast } from "sonner";
import { CreateCardHolders as EditCardHolders } from "./create-card-holders";
import { ViewMoreCardHolder } from "./details/view-more-card-holder";
import type { CardHoldersType } from "./schema";

interface Props<TData> {
  row: Row<TData>;
}

export function Actions({ row }: Props<CardHoldersType>) {
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState<CardHoldersType | null>(null);
  const [viewMoreData, setViewMoreData] = useState<CardHoldersType | null>(
    null
  );
  const [deleteCardHolder] = useDeleteCardHolderMutation();
  const handleDelete = useCallback(async () => {
    await deleteCardHolder(row.original.id)
      .unwrap()
      .then(() => {
        toast.success("Card Holder deleted successfully.");
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(
          `Failed to delete card holder: ${error?.data?.error_message}`
        );
      });
  }, [deleteCardHolder, row?.original?.id]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          <DropdownMenuItem
            onClick={() => setViewMoreData(row.original)}
            className="flex items-center justify-between gap-2"
          >
            View more
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setEditData(row.original)}
            className="flex items-center justify-between gap-2"
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setIsOpen(true)}
            className="flex items-center justify-between gap-2 text-red-500"
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-sm p-2 bg-background border border-[#525252]">
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete this
              Card Holder and remove all it's corresponding cards from our
              servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </DialogClose>
            <Button variant="destructive" onClick={() => handleDelete()}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {editData && (
        <EditCardHolders data={editData} onClose={() => setEditData(null)} />
      )}
      {viewMoreData && (
        <ViewMoreCardHolder
          data={viewMoreData}
          onClose={() => setViewMoreData(null)}
        />
      )}
    </>
  );
}
