export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_LOADING = "GET_COUNTRIES_LOADING";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_LOADING = "GET_STATES_LOADING";
export const GET_STATES_FAIL = "GET_STATES_FAIL";

export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_LOADING = "GET_CITIES_LOADING";
export const GET_CITIES_FAIL = "GET_CITIES_FAIL";

interface GlobalState {
  loading: boolean;
  data: any;
  error: string | null;
}

interface GlobalAction {
  type: string;
  payload?: any;
}

export const globalReducer = (
  state: GlobalState,
  action: GlobalAction,
): GlobalState => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_COUNTRIES_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    }
    case GET_COUNTRIES_FAIL: {
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    }

    case GET_STATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_STATES_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    }
    case GET_STATES_FAIL: {
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    }

    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_CITIES_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    }
    case GET_CITIES_FAIL: {
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
