import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { PaymentSettlmentCycleTypeResult } from "@/redux/features/revenue/revenue.slice";
import type { ColumnDef } from "@tanstack/react-table";

import { formatDateShort } from "../../../../../lib/helpers/dates";

export const columns: ColumnDef<PaymentSettlmentCycleTypeResult>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Cycle Id" />
    ),
    cell: ({ row }) => <div className="uppercase">{row.getValue("id")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "retaining_period_start_date",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Retaining Period Start Date"
      />
    ),
    cell: ({ row }) => (
      <div className="">
        {formatDateShort(row.getValue("retaining_period_start_date"))}
      </div>
    ),
  },
  {
    accessorKey: "retaining_period_end_date",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Retaining Period End Date"
      />
    ),
    cell: ({ row }) => (
      <div className="">
        {formatDateShort(row.getValue("retaining_period_end_date"))}
      </div>
    ),
  },
  {
    accessorKey: "disbursement_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Disbursement Date" />
    ),
    cell: ({ row }) => (
      <div className="">
        {formatDateShort(row.getValue("disbursement_date"))}
      </div>
    ),
  },
];
