"use client";

import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { MapPin, Zap, Info, Hash, ChevronDown, ChevronUp } from "lucide-react";
import { Building } from "iconsax-react";
import type { ChargerInfo } from "@/types/charger-network";

export function InfoWindowCard({ charger }: { charger: ChargerInfo }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "operative":
        return "bg-green-500";
      case "inoperative":
        return "bg-red-500";
      case "partially operative":
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  const cardVariants = {
    collapsed: { height: "auto" },
    expanded: { height: "auto" },
  };

  const contentVariants = {
    collapsed: { opacity: 0, height: 0 },
    expanded: { opacity: 1, height: "auto" },
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="w-80 shadow-lg overflow-hidden bg-transparent text-white border-none">
        <motion.div
          variants={cardVariants}
          initial="collapsed"
          animate={isExpanded ? "expanded" : "collapsed"}
          transition={{ duration: 0.3 }}
        >
          <CardHeader className="pb-2">
            <div className="flex justify-between items-start">
              <CardTitle className="text-lg font-bold">
                {charger.name}
              </CardTitle>
              <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Badge
                  className={`${getStatusColor(charger.status)} text-white`}
                >
                  {charger.status}
                </Badge>
              </motion.div>
            </div>
          </CardHeader>
          <CardContent className="pt-2">
            <motion.div
              className="space-y-2"
              initial={{ opacity: 1 }}
              animate={{ opacity: isExpanded ? 0.6 : 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex items-center text-sm">
                <MapPin className="w-4 h-4 mr-2 text-gray-500" />
                <span>{charger.address}</span>
              </div>
              <div className="flex items-center text-sm">
                <Zap className="w-4 h-4 mr-2 text-gray-500" />
                <span>Charger Group ID: {charger.charger_group_id}</span>
              </div>
            </motion.div>
            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  variants={contentVariants}
                  initial="collapsed"
                  animate="expanded"
                  exit="collapsed"
                  transition={{ duration: 0.3 }}
                  className="space-y-2 mt-2"
                >
                  <div className="flex items-center text-sm">
                    <Info className="w-4 h-4 mr-2 text-gray-500" />
                    <span>{charger.description}</span>
                  </div>
                  <div className="flex items-center text-sm">
                    <Building className="w-4 h-4 mr-2 text-gray-500" />
                    <span>
                      Organization ID: {charger.organization.slice(0, 8)}...
                    </span>
                  </div>
                  <div className="flex items-center text-sm">
                    <Hash className="w-4 h-4 mr-2 text-gray-500" />
                    <span>ID: {charger.id.slice(0, 8)}...</span>
                  </div>
                  <div className="mt-2 pt-2 border-t border-gray-200">
                    <p className="text-xs text-gray-500">
                      Coordinates: {charger.latitude}, {charger.longitude}
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              className="mt-4 flex justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <Button
                variant="secondary"
                size="sm"
                onClick={() => setIsExpanded(!isExpanded)}
                className="w-full z-10"
              >
                {isExpanded ? (
                  <>
                    <ChevronUp className="w-4 h-4 mr-2" />
                    Show Less
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-4 h-4 mr-2" />
                    Show More
                  </>
                )}
              </Button>
            </motion.div>
          </CardContent>
        </motion.div>
      </Card>
    </motion.div>
  );
}
