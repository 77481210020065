import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Search } from "lucide-react";

export function FilterSection() {
  return (
    <div className="my-4 flex gap-2">
      <div className="relative flex flex-1 items-center">
        <Search
          color="#898989"
          size={16}
          className="absolute left-2 top-1/2 -translate-y-1/2 transform"
        />
        <Input placeholder="Filter..." className="py-1 pl-8 text-sm" />
      </div>
      <Select>
        <SelectTrigger className="flex-[.2]">
          <SelectValue placeholder="All team roles" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="light">Member </SelectItem>
          <SelectItem value="dark">Owner</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
