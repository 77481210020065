interface DataProperties {
  id: number;
  companyName: string;
  companyLogo: string;
  devices: number;
  modules: number;
  users: number;
}

interface Properties {
  data: DataProperties;
}
export const OrganisationsCard: React.FC<Properties> = ({ data }) => {
  return (
    <div className="cursor-pointer overflow-hidden rounded-lg bg-blue-800 duration-200 hover:scale-105">
      <div
        className="mt-1 rounded-lg bg-slate-400 p-4 shadow-2xl"
        style={{ backdropFilter: "blur(20px)" }}
      >
        <div className="flex gap-1">
          <img src={data.companyLogo} alt="" />
          <h3 className="text-xl text-white">{data.companyName}</h3>
        </div>
        <div className="my-4 h-[.2px] w-full bg-[#ccc]" />
        <div className="flex justify-between gap-2">
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-lg font-semibold">Devices</div>
            <div className="">{data.devices}</div>
          </div>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-lg font-semibold">Modules</div>
            <div className="">{data.modules}</div>
          </div>
          <div className="flex flex-col items-center justify-center text-white">
            <div className="text-lg font-semibold">Users</div>
            <div className="">{data.users}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
