import { DataTableColumnHeader } from "@/components/ui/data-table/data-table-column-header";
import type { ColumnDef } from "@tanstack/react-table";
import type { SessionHistoryType } from "./schema";

export const columns: ColumnDef<SessionHistoryType>[] = [
  {
    accessorKey: "charging_session_id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Session ID" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("charging_session_id")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "electricityUsage",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Electricity Usage" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("electricityUsage")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "chargerId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Charger ID" />
    ),
    cell: ({ row }) => (
      <div className="capitalize whitespace-nowrap text-[10px]">
        {row.getValue("chargerId")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "location",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Location " />
    ),
    cell: ({ row }) => (
      <div className="capitalize text-[10px] whitespace-nowrap truncate">
        {row.getValue("location")}
      </div>
    ),
    enableSorting: false,
    enableHiding: false,
  },
];
