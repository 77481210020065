import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { X } from "lucide-react";
import { columns } from "./column";
import { CardExpirationsDataTable } from "./data-table";
import type { CardExpirationstype } from "./schema";

type Properties = {
  data: CardExpirationstype[];
  isModalOpen: boolean;
  onClick?: (() => undefined) | undefined;
};

export const CardExpirations = ({ data, isModalOpen, onClick }: Properties) => {
  return (
    <>
      <Dialog modal open={isModalOpen}>
        <DialogContent className="max-h-xl scrollbar-thin scrollbar-track-gray-600 max-h-[70%] max-w-[70%] overflow-hidden overflow-y-scroll border-0 bg-primary p-4 text-white shadow-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <h2 className="my-5">Role Expirations This Month</h2>{" "}
              <Button onClick={onClick} variant="secondary" type="button">
                <X size={16} color="black" />
              </Button>
            </DialogTitle>
            <DialogDescription className="flex gap-4 lg:flex-col xl:flex-col">
              <div className="container rounded-lg border border-slate-400 bg-black p-6 text-white">
                <h2 className="text-2xl font-bold">
                  Cards Expiring this month.
                </h2>

                <CardExpirationsDataTable data={data} columns={columns} />
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
