import chargerGroup from "@/assets/icons/charger-groups.png";
import idleIcon from "@/assets/icons/charger-idle-icon.png";
import offlineIcon from "@/assets/icons/charger-offline-icon.png";
import onlineIcon from "@/assets/icons/charger-online-icon.png";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AnimatePresence, motion } from "framer-motion";
import {
  Activity,
  BatteryCharging,
  ChevronDown,
  ChevronUp,
  Clock,
  Power,
} from "lucide-react";
import { useEffect, useState } from "react";

const legendItems = [
  { image: onlineIcon, label: "Operative", color: "text-green-500" },
  { image: offlineIcon, label: "Inoperative", color: "text-red-500" },
  { image: idleIcon, label: "Partially Operative", color: "text-yellow-500" },
  { image: chargerGroup, label: "Charger Group", color: "text-blue-500" },
];

export default function Legend() {
  const [isOpen, setIsOpen] = useState(true);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * legendItems.length);
      setActiveItem(legendItems[randomIndex].label);
      setTimeout(() => setActiveItem(null), 1000);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="absolute bottom-4 left-4 bg-background/80 backdrop-blur-sm rounded-lg shadow-lg z-10 overflow-hidden"
    >
      <div className="p-2 flex justify-between items-center">
        <h3 className="text-lg font-semibold ml-2">Legend</h3>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? "Close legend" : "Open legend"}
        >
          {isOpen ? (
            <ChevronDown className="h-4 w-4" />
          ) : (
            <ChevronUp className="h-4 w-4" />
          )}
        </Button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ul className="space-y-2 p-4">
              {legendItems.map(({ image, label, color }) => (
                <motion.li
                  key={label}
                  className={`flex items-center ${
                    activeItem === label ? "font-bold" : ""
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center cursor-pointer">
                        <img src={image} className={`w-6 h-6 mr-2 ${color}`} />
                        <span>{label}</span>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Click for more info about {label}</p>
                    </TooltipContent>
                  </Tooltip>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
