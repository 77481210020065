import { TableRow } from "@/components/ui/table";
import type React from "react";

type Props = {
  children: React.ReactNode;
};

export const Details = ({ children }: Props) => {
  return <TableRow>{children}</TableRow>;
};
