import { api } from "@/redux/api";
import type {
  APIResponse,
  PaginatedRequest,
  PaginatedResponse,
} from "@/types/global";

interface ChargerGroup {
  id: string;
  name: string;
  description: string | null;
  latitude: string;
  longitude: string;
  address: string;
  organization: string;
  created_by: string;
  tarrif_plan: string | null;
  location_description: string | null;
}
interface GetChargerGroupResponse {
  count: 10;
  next: null;
  previous: null;
  results: ChargerGroup[];
}

interface GetChargerGroupRequest {
  orgId: string;
  page: number;
}
interface AddChargerGroupResponse {
  status: string;
  status_code: number;
  message: string;
  data: {
    charger_group_id: string;
    name: string;
    organization: string;
    created_by: string;
    id: string;
    description: string;
    latitude: string;
    longitude: string;
    address: string;
    state: string;
    city: string;
    zip_code: string;
    country: string;
    tarrif_plan: string;
  };
}

interface ChargePointBase {
  name: string;
  charge_point_id: string;
  charger_make: string;
  charger_model: string;
  charger_type: string;
  max_voltage: number;
  max_amperage: number;
  max_charging_power: number;
  warranty_expiry_date: string;
  serial_number: string;
}

interface CreateChargePointRequest extends ChargePointBase {
  charger_group: string;
}
interface ChargePointResponse extends ChargePointBase {
  id: string;
  charger_status: string;
  installation_date: string | null;
  last_booted_at: string | null;

  charger_group: {
    address: string;
    charger_group_id: string;
    created_by: string;
    description: null;
    id: string;
    latitude: string;
    longitude: string;
    name: string;
    organization: string;
    tarrif_plan: string | null;
  };
}

interface AddChargerGroupRequest {
  name: string;
  latitude: number;
  longitude: number;
  address: string;
  description?: string | null;
  location_description?: string | null;
}

interface EditChargerGroupRequest extends AddChargerGroupRequest {
  id: string;
}

export const chargerNetworkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getChargePoints: builder.query<
      APIResponse<PaginatedResponse<ChargePointResponse>>,
      PaginatedRequest & { orgId: string }
    >({
      query: ({ orgId, page = 1 }) =>
        `core_backend/chargepoints/${orgId}?page=${page}`,
      providesTags: ["ChargePoints"],
    }),
    addChargePoint: builder.mutation({
      query: (values) => ({
        url: "core_backend/new-chargepoint/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["ChargePoints"],
    }),
    updateChargePoint: builder.mutation({
      query: ({ id, ...values }) => ({
        url: `core_backend/chargepoint/edit/${id}/`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["ChargePoints"],
    }),
    deleteChargePoint: builder.mutation({
      query: (id) => ({
        url: `core_backend/chargepoint/delete/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ChargePoints"],
    }),
    addChargerGroupToTariff: builder.mutation({
      query: ({ charger_group_id, tariff_plan_id }) => ({
        url: `core_backend/charger-groups/${charger_group_id}/`,
        method: "PATCH",
        body: {
          tarrif_plan: tariff_plan_id,
        },
      }),
      invalidatesTags: ["ChargeGroups"],
    }),
    getChargeGroups: builder.query<
      GetChargerGroupResponse,
      GetChargerGroupRequest
    >({
      query: ({ orgId, page = 1 }) =>
        `core_backend/charger-groups/?organization=${orgId}&page=${page}`,
      providesTags: ["ChargeGroups"],
    }),
    addChargeGroup: builder.mutation<
      AddChargerGroupResponse,
      AddChargerGroupRequest
    >({
      query: (values) => ({
        url: "core_backend/charger-groups/",
        method: "POST",
        body: values,
      }),

      invalidatesTags: ["ChargeGroups"],
    }),

    editChargeGroup: builder.mutation<
      AddChargerGroupResponse,
      EditChargerGroupRequest
    >({
      query: (values) => ({
        url: "core_backend/charger-groups/",
        method: "POST",
        body: values,
      }),

      invalidatesTags: ["ChargeGroups"],
    }),

    deleteChargerGroup: builder.mutation({
      query: (id) => ({
        url: `core_backend/charger-groups/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ChargeGroups"],
    }),
    getChargersState: builder.query({
      query: ({ org_id, group_id }) =>
        `core_backend/utilization-breakdown/${org_id}${
          group_id ? `?charger_group_id=${group_id}` : ""
        }`,
    }),
    getChargersUtilizationRate: builder.query({
      query: (values) =>
        `core_backend/daily-charger-utilization/${values.org_id}${
          values.group_id ? `?charger_group=${values.group_id}` : ""
        }`,
    }),
    getChargeGroupGlobal: builder.query({
      query: (arguments_: { orgId: string; page: number }) =>
        `core_backend/charger-groups/?organization=${arguments_.orgId}&page=${arguments_.page}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        if (!newItems.previous) {
          currentCache.results = newItems.results;
          currentCache.next = newItems.next;
          currentCache.previous = newItems.previous;
          return;
        }
        currentCache.results.push(...newItems.results);
        currentCache.next = newItems.next;
        currentCache.previous = newItems.previous;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["ChargeGroups"],
    }),
    getChargepointConnectors: builder.query({
      query: (arguments_: {
        orgId: string;
        chargepointId: string;
        page: number;
      }) =>
        `core_backend/chargepoint-connectors/${arguments_.orgId}?page=${arguments_.page}&charge_point=${arguments_.chargepointId}`,
    }),

    // for qr code generation
    getSingleChargePointQRCode: builder.mutation({
      query: (chargePointId) => ({
        url: "drive/chargers/qr-code/",
        method: "POST",
        body: { charge_point_ids: [chargePointId] },
        headers: { "Content-Type": "application/json" },
        responseHandler: async (response) => {
          //convert response to blob
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          return url;
        },
      }),
    }),
    // this function is not currently being used as we don't have a feature to mass export qr codes
    getMultipleChargePointsQRCode: builder.mutation({
      query: (chargePointIds) => ({
        url: "drive/chargers/qr-code/",
        method: "POST",
        body: { charge_point_ids: chargePointIds },
        headers: { "Content-Type": "application/json" },
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);

          return url;

          // exporting as zip, copy and paste in component where it's being used

          //   const link = document.createElement("a");
          //   link.href = url;
          //   link.setAttribute("download", "GRIDFLOW-<CHARGEPOINT_ID>-CHARGEPOINT.zip");
          //   document.body.appendChild(link);
          //   link.click();
          //   link.remove();
        },
      }),
    }),
  }),
});

export const {
  useGetChargePointsQuery,
  useGetChargeGroupsQuery,
  useAddChargeGroupMutation,
  useAddChargePointMutation,
  useGetChargersStateQuery,
  useGetChargersUtilizationRateQuery,
  useGetChargeGroupGlobalQuery,
  useAddChargerGroupToTariffMutation,
  useGetChargepointConnectorsQuery,
  useDeleteChargePointMutation,
  useUpdateChargePointMutation,
  useEditChargeGroupMutation,
  useDeleteChargerGroupMutation,

  // for qr code generation
  useGetSingleChargePointQRCodeMutation,
  useGetMultipleChargePointsQRCodeMutation,
} = chargerNetworkApi;
