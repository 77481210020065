import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils/cn";
import { CreateChargerGroup } from "@/pages/(dashboard)/ChargerNetwork/components/group-table/create-charger-group";
import { useGetChargeGroupsQuery } from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { ChartCircle, CloseCircle } from "iconsax-react";
import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import { ArrowUpRight, UserPlus } from "react-feather";

interface ChargerGroupType {
  id: string;
  name: string;
}

export function ChargerGroupMultiSelect() {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);
  const [groupDataResult, setGroupDataResult] = useState<ChargerGroupType[]>(
    []
  );
  const [selectedGroups, setSelectedGroups] = useState<ChargerGroupType[]>([]);
  const { data, isFetching } = useGetChargeGroupsQuery({
    orgId: orgId,
    page: page,
  });

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data?.results) {
      setGroupDataResult((prevGroups) => [
        ...prevGroups,
        ...data.results.filter(
          (newGroup) => !prevGroups.some((group) => group.id === newGroup.id)
        ),
      ]);
    }
  }, [data]);

  const filteredGroups = groupDataResult?.filter((group: ChargerGroupType) =>
    group.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const loadMoreData = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const toggleGroupSelection = (
    group: ChargerGroupType,
    fieldOnChange: (value: { [key: string]: string }) => void
  ) => {
    setSelectedGroups((prevSelected: ChargerGroupType[]) => {
      let updatedGroups: ChargerGroupType[];
      if (prevSelected.includes(group)) {
        updatedGroups = prevSelected.filter((prev) => prev.id !== group.id);
      } else {
        updatedGroups = [...prevSelected, group];
      }

      const result = updatedGroups.reduce(
        (acc: { [key: string]: string }, group) => {
          acc[group.name] = group.id;
          return acc;
        },
        {}
      );

      fieldOnChange(result);

      return updatedGroups;
    });
  };

  return (
    <FormField
      name="access_to_charger_groups"
      render={({ field }) => {
        useEffect(() => {
          if (field.value) {
            const defaultSelectedGroup = Object.entries(field.value).map(
              ([name, id]) => ({ id: id as string, name })
            );
            setSelectedGroups((prev) => {
              const newGroups = defaultSelectedGroup.filter(
                (newGroup) =>
                  !prev.some((charger) => charger.id === newGroup.id)
              );
              return [...prev, ...newGroups];
            });
          }
        }, [field.value]);

        return (
          <FormItem>
            <FormLabel className="whitespace-nowrap">
              Access to Charger Groups
            </FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <FormControl>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-end bg-gray-800 border-gray-700 text-gray-100"
                    disabled={isFetching}
                  >
                    {selectedGroups.length > 0 ? (
                      <TooltipProvider delayDuration={300}>
                        <div className="flex flex-1 overflow-x-hidden flex-wrap items-center gap-1.5">
                          {selectedGroups.slice(0, 2).map((value) => (
                            <Tooltip key={value.id}>
                              <TooltipTrigger className="inline-flex">
                                <div className="py-1 px-2 rounded-lg bg-[#000000] flex items-center gap-1 text-xs">
                                  <span>
                                    {value.name.length > 6
                                      ? `${value.name.slice(0, 6)}...`
                                      : value.name}
                                  </span>
                                  <Button
                                    type="button"
                                    variant="ghost"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleGroupSelection(
                                        value,
                                        field.onChange
                                      );
                                    }}
                                    className={cn(
                                      "py-1 px-0 h-full hover:bg-transparent rounded-full "
                                    )}
                                  >
                                    <CloseCircle size="16" variant="Bulk" />
                                  </Button>
                                </div>
                              </TooltipTrigger>
                              <TooltipContent
                                side="bottom"
                                align="start"
                                className="z-[51]"
                              >
                                {value.name}
                              </TooltipContent>
                            </Tooltip>
                          ))}

                          {selectedGroups.length > 2 && (
                            <div className="py-1 px-2 rounded-lg bg-[#000000] flex items-center gap-1 text-xs">
                              <span>{`+${
                                selectedGroups.length - 2
                              } more`}</span>
                            </div>
                          )}
                        </div>
                      </TooltipProvider>
                    ) : null}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
              </FormControl>
              <PopoverContent className="w-[450px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search charger group..."
                    value={searchQuery}
                    onInput={(e) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <div className="relative p-2 flex gap-2">
                    <CreateChargerGroup />

                    <Button
                      type="button"
                      variant="outline"
                      className=" w-full bg-gray-800 whitespace-nowrap text-gray-200 gap-1 border-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      View Groups
                      <ArrowUpRight className="mr-2 h-4 w-4" />
                    </Button>
                  </div>

                  <CommandList>
                    {isFetching ? (
                      <div className="flex justify-center py-2">
                        <ChartCircle
                          className="animate-spin"
                          size={20}
                          color="#FF8A65"
                        />
                      </div>
                    ) : filteredGroups?.length > 0 ? (
                      <>
                        <ScrollArea className="h-[300px] overflow-auto">
                          <CommandGroup className="text-white h-[300px] ">
                            {filteredGroups.map((group: ChargerGroupType) => (
                              <CommandItem
                                key={group.id}
                                value={group.id}
                                onSelect={() => {
                                  toggleGroupSelection(group, field.onChange);
                                }}
                              >
                                <Check
                                  className={
                                    selectedGroups.includes(group)
                                      ? "mr-2 h-4 w-4 opacity-100"
                                      : "mr-2 h-4 w-4 opacity-0"
                                  }
                                />
                                {group.name}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </ScrollArea>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <CommandEmpty>No Charger group found.</CommandEmpty>
                        {data?.next && !isFetching && (
                          <Button
                            type="button"
                            className="w-full py-2 text-center text-xs text-gray-500 hover:text-gray-300"
                            onClick={loadMoreData}
                          >
                            Load more
                          </Button>
                        )}
                      </>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
