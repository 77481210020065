import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { getImageSizeFromDataLink } from "@/lib/helpers/get-image-size";
import { getImageType } from "@/lib/helpers/get-image-type";
import { useEditOrganisationMutation } from "@/redux/features/org/org.api";
import { setCurrentOrg } from "@/redux/features/org/org.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowUpRight, CameraIcon, Copy } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { type DropzoneOptions, useDropzone } from "react-dropzone";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

import { DeleteOrganisation } from "./delete-organisation";

const formSchema = z.object({
  org_name: z
    .string({ required_error: "Name cannot be empty" })
    .max(50, { message: "Name is too long" }),
  profile_picture: z.any().optional(),
  profile_picture_preview: z
    .any()
    .refine(
      (files) => {
        const fileSize = getImageSizeFromDataLink(files);
        return fileSize <= 5;
      },
      { message: `Max image size is 5MB.` },
    )
    .refine((files) => {
      const allowedTypes = ["jpeg", "jpg", "png", "webp"];
      const extractedType = getImageType(files);
      return allowedTypes.includes(extractedType);
    }, "Only .jpg, .jpeg, .png and .webp formats are supported.")
    .optional(),
});

export const GeneralSettings = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const { currentOrg } = useAppSelector((state) => state.org);

  const dispatch = useAppDispatch();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { ...currentOrg },
  });

  const [profile_picture, set_profile_picture] = useState<string | undefined>(
    "",
  );

  const [editOrganisation, { isLoading }] = useEditOrganisationMutation();

  const onDrop = useCallback<NonNullable<DropzoneOptions["onDrop"]>>(
    (acceptedFiles) => {
      for (const file of acceptedFiles) {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          if (event.target && event.target.result) {
            const imageAsBase64 = event.target.result.toString();
            const image = new Image();
            image.src = imageAsBase64;
            image.addEventListener("load", () => {
              const canvas = document.createElement("canvas");
              canvas.width = 128;
              canvas.height = 128;
              const context = canvas.getContext("2d", { alpha: false });
              if (context) {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                const resizedImageAsBase64 = canvas.toDataURL("image/webp");
                set_profile_picture(resizedImageAsBase64);
                form.setValue("profile_picture", acceptedFiles[0]);
                form.setValue("profile_picture_preview", resizedImageAsBase64);
              }
            });
          }
        });
        reader.readAsDataURL(file);
      }
    },
    [form],
  );

  const { isDirty } = useFormState({ control: form.control });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": ["jpeg", "jpg"],
      "image/png": ["png"],
      "image/webp": ["webp"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (currentOrg) {
      set_profile_picture(currentOrg.org_profile_picture);
    }
  }, [currentOrg]);

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!isDirty) {
      toast.error("Please edit the your profile before saving.");
      return;
    }

    const form_data = new FormData();
    form_data.append("org_name", values.org_name);
    if (values.profile_picture)
      form_data.append("org_profile_picture", values.profile_picture);

    editOrganisation({ orgId, values: form_data })
      .unwrap()
      .then((response) => {
        toast.success(response.message);
        form.setValue("org_name", response.data.org_name);
        set_profile_picture(response.data.org_profile_picture);
        dispatch(setCurrentOrg(response.data));
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className="space-y-4 text-white"
        >
          <div className="my-10 space-y-4">
            <div className="flex max-w-2xl items-center space-x-4">
              <FormField
                control={form.control}
                name="profile_picture"
                render={({ field }) => (
                  <FormItem className="">
                    <FormControl>
                      <div>
                        <div
                          {...getRootProps()}
                          className="transition-background relative h-32 w-32 cursor-pointer overflow-hidden rounded-full bg-zinc-800 duration-150 hover:bg-accent"
                        >
                          <input {...getInputProps()} />
                          <div className="absolute inset-0 flex h-32 w-32 items-center justify-center">
                            <CameraIcon
                              className={`h-6 w-6 text-muted-foreground ${
                                profile_picture && "hidden"
                              }`}
                            />
                          </div>
                          {profile_picture ? (
                            <Avatar className="relative flex h-32 w-32 shrink-0 overflow-hidden rounded-full">
                              <AvatarImage src={profile_picture} />
                              <AvatarFallback className="text-6xl uppercase">
                                {currentOrg?.org_name?.charAt(0)}
                              </AvatarFallback>
                            </Avatar>
                          ) : null}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />

              <div className="my-4 flex flex-1 flex-col gap-6">
                <FormField
                  control={form.control}
                  name="org_name"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <div className="flex">
                          <Input
                            placeholder="Organisation Name"
                            className="rounded rounded-r-none focus:ring-0"
                            // defaultValue={currentOrg?.org_name!}
                            {...field}
                          />
                          <Button
                            disabled={isLoading || !isDirty}
                            variant="secondary"
                            className="w-[30%] rounded-l-none border border-l-0 bg-secondary text-black"
                          >
                            Save
                          </Button>
                        </div>
                      </FormControl>
                      <FormDescription>
                        Please use 32 characters at maximum
                      </FormDescription>
                      <FormMessage className="absolute -bottom-3 text-[10px]" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <hr />
            <div className="my-4 flex flex-col gap-6">
              <Label className="flex-1 space-y-2">
                <p className="my-4">Organisation ID</p>
                <div className="flex w-max items-center justify-between gap-3 overflow-hidden rounded-sm border bg-black/10">
                  <p className="px-4 py-2">{currentOrg?.org_id}</p>

                  <Copy
                    onClick={() => {
                      currentOrg?.org_id &&
                        navigator.clipboard.writeText(currentOrg?.org_id);
                      toast.success(`Copied org Id ${currentOrg?.org_id}`);
                    }}
                    className="block h-8 w-8 cursor-pointer bg-white/10 py-2 duration-150 hover:bg-white/20"
                  />
                </div>
                <span className="block text-xs text-gray-400">
                  Used when interacting with the Our API.
                </span>
              </Label>
            </div>
          </div>{" "}
        </form>
      </Form>

      <div className="space-y-1 rounded-md border border-slate-400 bg-primary">
        <div className="space-y-1 p-4">
          <h2 className="text-lg">Transfer Chargers</h2>
          <p className="flex items-center gap-2 text-sm text-gray-400">
            Move all your current chargers to a new account
          </p>
        </div>
        <div className="flex items-center justify-between border-t-[.2px] border-slate-500 p-4">
          <span className="flex items-center gap-1">
            <span className="flex items-center gap-1 text-blue-600">
              Learn more <ArrowUpRight size={16} />
            </span>{" "}
            about this process
          </span>

          <Button variant="secondary" className="w-max">
            Transfer
          </Button>
        </div>
      </div>

      <DeleteOrganisation />
    </div>
  );
};
