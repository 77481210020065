import { formatDate } from "@/lib/helpers/dates";
import { faker } from "@faker-js/faker";
import { v4 as uuidv4 } from "uuid";
import type { CardHoldersType, RecentActivityType } from "./schema";
import { FaCar, FaCode, FaUser, FaUserTie } from "react-icons/fa";

export const driverRoles = [
  {
    value: "sales manager",
    label: "Sales Manager",
    icon: FaUserTie,
  },
  {
    value: "developer",
    label: "Developer",
    icon: FaCode,
  },
  {
    value: "driver",
    label: "Driver",
    icon: FaCar,
  },
  {
    value: "guest",
    label: "Guest",
    icon: FaUser,
  },
];

export function generateFakeRecentActivity(
  count: number
): RecentActivityType[] {
  const recentActivities: RecentActivityType[] = [];

  for (let index = 0; index < count; index++) {
    const recentActivity = {
      id: uuidv4(),
      chargerId: `GRDFLO-${faker.number.int({ min: 10, max: 100 })}`,
      location: faker.location.streetAddress({ useFullAddress: true }),
      chargingReceived: faker.number.int({ min: 1, max: 100 }),
      energyDelivered: faker.number.int({ min: 1, max: 200 }),
      equivalentInKm: faker.number.int({ min: 1, max: 500 }),
      chargingTime: faker.number.int({ min: 1, max: 60 }),
      amount: faker.number.float({ min: 10, max: 100, precision: 0.001 }),
      paymentMethod: faker.helpers.arrayElement([
        "Credit Card",
        "PayPal",
        "Apple Pay",
      ]),
      date: faker.date.recent().toISOString(),
    };

    recentActivities.push(recentActivity);
  }
  return recentActivities;
}

export const generateCardHolders = (count: number): CardHoldersType[] => {
  const cardHolders = [];

  for (let index = 0; index < count; index++) {
    const cardHolder = {
      id: `DRVR-${faker.number.int({ min: 10, max: 100 })}`,
      name: faker.person.fullName(),
      designation: faker.helpers.arrayElement(driverRoles).value,
      totalElectricityUsage: faker.number.int({ min: 50, max: 100 }),
      totalChargingSessions: faker.number.int({ min: 50, max: 100 }),
      expirationDate: formatDate(faker.date.recent().toString()),
      recentActivities: generateFakeRecentActivity(10),
      totalRFIDCards: faker.number.int({ min: 50, max: 100 }),
      email: faker.internet.email(),
      phoneNumber: faker.phone.number(),
    };
    cardHolders.push(cardHolder);
  }
  return cardHolders;
};
