import { Check, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CreateRestrictionLimit } from "@/pages/(dashboard)/AccessControl/components/card-holders-and-access-profile-tab/access-profile-table/restriction-limits-table/create-restriction-limit";
import { useGetRestrictionLimitsQuery } from "@/redux/features/access-control/access-control.api";
import { ChartCircle } from "iconsax-react";
import { ArrowUpRight, UserPlus } from "react-feather";

interface RestrictionLimitType {
  id: string;
  name: string;
}

export function RestrictionLimitSelect() {
  const [page, setPage] = useState(1);
  const [restrictionLimitDataResult, setRestrictionDataResult] = useState<
    RestrictionLimitType[]
  >([]);
  const { data, isFetching } = useGetRestrictionLimitsQuery(page);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (data?.results) {
      setRestrictionDataResult((prevLimit) => [
        ...prevLimit,
        ...data.results.filter(
          (newLimit) => !prevLimit.some((limit) => limit.id === newLimit.id)
        ),
      ]);
    }
  }, [data]);

  const filteredLimits = restrictionLimitDataResult?.filter(
    (limit: RestrictionLimitType) =>
      limit.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const loadMoreData = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <FormField
      name="restriction_limit_id"
      render={({ field }) => {
        useEffect(() => {
          if (field.value) {
            setValue(field.value);
          }
        }, [field.value]);

        return (
          <FormItem>
            <FormLabel className=" whitespace-nowrap">
              Restriction Limit
            </FormLabel>

            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-end bg-gray-800 border-gray-700 text-gray-100"
                    disabled={isFetching}
                  >
                    {value
                      ? restrictionLimitDataResult.find(
                          (limit) => limit.id === value
                        )?.name
                      : null}
                    <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[450px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search restriction limit..."
                    value={searchQuery}
                    onInput={(e) =>
                      setSearchQuery((e.target as HTMLInputElement).value)
                    }
                  />
                  <div className="relative p-2 flex gap-2 ">
                    <CreateRestrictionLimit />

                    <Button
                      type="button"
                      variant="outline"
                      className=" w-full bg-gray-800 whitespace-nowrap text-gray-200 gap-1 border-gray-700 hover:bg-gray-700 hover:text-white"
                    >
                      View Limits
                      <ArrowUpRight className="mr-2 h-4 w-4" />
                    </Button>
                  </div>
                  <CommandList>
                    {isFetching ? (
                      <div className="flex justify-center py-2">
                        <ChartCircle
                          className="animate-spin"
                          size={20}
                          color="#FF8A65"
                        />
                      </div>
                    ) : filteredLimits?.length > 0 ? (
                      <>
                        <CommandGroup className="text-white">
                          {filteredLimits.map((limit: RestrictionLimitType) => (
                            <CommandItem
                              key={limit.id}
                              value={limit.id}
                              onSelect={(currentValue) => {
                                setValue(
                                  currentValue === value ? "" : currentValue
                                );
                                setOpen(false);
                                field.onChange(currentValue);
                              }}
                            >
                              <Check
                                className={
                                  value === limit.id
                                    ? "mr-2 h-4 w-4 opacity-100"
                                    : "mr-2 h-4 w-4 opacity-0"
                                }
                              />
                              {limit.name}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                        {data?.next && !isFetching && (
                          <div className="relative p-2 ">
                            <Button
                              type="button"
                              variant="outline"
                              className="w-full bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                              onClick={loadMoreData}
                            >
                              Load more
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <CommandEmpty>No restriction Limit found.</CommandEmpty>
                        {data?.next && !isFetching && (
                          <div className="relative p-2 ">
                            <Button
                              type="button"
                              variant="outline"
                              className="w-full bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
                              onClick={loadMoreData}
                            >
                              Load more
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
