import { Separator } from "@/components/ui/separator";
import {
  BsCardChecklist,
  BsFillClockFill,
  BsGeoAlt,
  BsGraphUp,
  BsLightning,
  BsPerson,
  BsPlug,
} from "react-icons/bs";

interface Properties {
  data: {
    charger_id: string;
    connector_id: number;
    duration: string;
    electricity_usage: number;
    initiated_by: string;
    session_id: string;
    transaction_status: string;
    transaction_timeline: string;
  };
}

export const SessionDetails = ({ data }: Properties) => {
  return (
    <div className="h-full transform space-y-4 rounded-lg bg-black/50 p-6 text-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between">
        <h2 className="flex items-center text-xl font-bold">
          <BsFillClockFill className="mr-4 text-yellow-500" />
          Charging Session Details
        </h2>
        {/* <Button className="bg-blue-500 hover:bg-blue-600">
					<BsDownload className="mr-2" />
					Download Session Report
				</Button> */}
      </div>
      <Separator className="h-[1px] bg-gray-500" />
      <div className="flex">
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-bold">Session ID:</span>{" "}
              <span className="text-xs uppercase text-zinc-400">
                {data.session_id}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPerson className="mr-4 text-green-500" />
            <div>
              <span className="text-sm font-semibold">Initiated by:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.initiated_by}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">Charger ID:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.charger_id}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">Connector Number:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.connector_id}
              </span>
            </div>
          </li>
        </ul>
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <BsLightning className="mr-4 text-yellow-500" />
            <div>
              <span className="text-sm font-semibold">Electricity Usage:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.electricity_usage} kW/h
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsCardChecklist className="mr-4 text-purple-500" />
            <div>
              <span className="text-sm font-semibold">Transaction Status:</span>{" "}
              <span
                className={`rounded-full px-3 py-[1px] text-xs capitalize ${
                  data.transaction_status === "failed"
                    ? "bg-red-500/30 text-red-500/80"
                    : data.transaction_status === "completed"
                      ? "bg-green-500/30 text-green-500/80"
                      : "bg-yellow-500/30 text-yellow-500/80"
                }`}
              >
                {data.transaction_status}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsGeoAlt className="mr-4 text-indigo-500" />
            <div>
              <span className="text-sm font-semibold">
                Transaction Timeline:
              </span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.transaction_timeline}
              </span>{" "}
            </div>
          </li>
          <li className="flex items-start">
            <BsGraphUp className="mr-4 text-pink-500" />
            <div>
              <span className="text-sm font-semibold">Duration:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.duration}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

// Charger Details Section:
//  Include Charger ID,
//  Group Name, Charger Name,
//  Protocol, Vendor/Model,
//  Firmware Version, and
//  Address and button (Show additional details - redirects to charger page)
