import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForgotPasswordMutation } from "@/redux/features/auth/auth.api";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

type Properties = {};

const getCreateNewPasswordPath = (email: string) => {
  return "/create-new-password/";
};

const ForgotPasswordFormSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .max(100, { message: "Email address is too long" }),
  frontend_url: z.string().default(window.location.origin),
});

const ForgotPassword = (properties: Properties) => {
  const forgotPasswordDetailsForm = useForm<
    z.infer<typeof ForgotPasswordFormSchema>
  >({
    resolver: zodResolver(ForgotPasswordFormSchema),
  });

  const [forgotPassword, { isLoading, error, data }] =
    useForgotPasswordMutation();

  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = async (
    values: z.infer<typeof ForgotPasswordFormSchema>,
  ) => {
    const frontend_url =
      window.location.origin + getCreateNewPasswordPath(values.email);
    try {
      const response = await forgotPassword({ ...values, frontend_url })
        .unwrap()
        .then((data) => {
          if (data.response_status == "success") {
            forgotPasswordDetailsForm.reset();
            setIsOpen(true);
            toast.success(data.message);
          }
        });
    } catch (error: any) {
      if (error) {
        toast.error(error?.data.error_message);
      }
    }
  };

  return (
    <div className="flex h-full items-center justify-center">
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="flex w-full max-w-[600px] flex-col gap-4 space-y-6">
          <div className="space-y-2">
            <div className="my-3 flex flex-col items-center justify-center gap-3">
              <img src="/images/logo-text.png" height={50} width={150} />
            </div>
            <div className="flex items-center gap-2">
              <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
              <span className="mx-2 w-max whitespace-nowrap text-center text-sm font-light text-white">
                Forgot password
              </span>
              <div className="h-[.4px] w-full rounded-full bg-white opacity-60" />
            </div>
            <p className="mx-auto max-w-[350px] text-center text-xs font-light text-slate-200">
              Enter your registered email address below & we will send an email
              with instructions to reset your password.
            </p>
          </div>

          <Form {...forgotPasswordDetailsForm}>
            <form
              onSubmit={forgotPasswordDetailsForm.handleSubmit(handleSubmit)}
              className="flex flex-col items-center gap-4"
            >
              <FormField
                control={forgotPasswordDetailsForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="Email Address"
                        className=""
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="text-[10px]" />
                  </FormItem>
                )}
              />

              <Button
                disabled={isLoading}
                className="w-full bg-[#eab308] text-black hover:bg-[#eab308]/60 md:w-[60%]"
              >
                Continue
              </Button>
            </form>
          </Form>
        </div>
      </div>

      <Dialog modal open={isOpen} onOpenChange={() => setIsOpen(true)}>
        <DialogContent className="border-0 bg-black bg-opacity-40 shadow-sm sm:max-w-lg">
          <X
            className="absolute right-4 top-4 z-10 h-4 w-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-400"
            onClick={() => setIsOpen(false)}
          />
          <div className="flex- flex w-full flex-col items-center justify-center gap-4 rounded-xl bg-black bg-opacity-20 p-4 backdrop-blur-xl">
            <img src="/icons/check-email-2.png" className="h-32 w-32" />

            <p className="w-full text-center text-xs font-light text-slate-200">
              We have sent a password recovery instruction to your email.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgotPassword;
