import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { useEditTariffPlansMutation } from "@/redux/features/tariff/tariff.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

import type { TariffPlanType } from "../../types";

interface Property {
  children: React.ReactNode;
  data: TariffPlanType;
}

const FormSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Tariff plan name cannot be empty" })
    .min(3, { message: "Tariff plan is too short" })
    .max(50, { message: "Tariff plan is too long" }),
  description: z.coerce.string().optional(),
  hourly_rate: z.coerce.number().optional(),
  kwh_rate: z.coerce.number().optional(),
  fixed_price: z.coerce.number().optional(),
  minimum_threshold_price: z.coerce.number().optional(),
  is_fixed_cost: z.boolean().default(false),
  is_minimum_fixed_cost: z.boolean().default(false),
});

export const EditPricePlanModal: React.FC<Property> = ({ children, data }) => {
  const [editTariffPlansMutation, { isLoading: editTariffPlansLoading }] =
    useEditTariffPlansMutation();

  const { globalCurrency } = useAppSelector((state) => state.home);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: data.name,
      minimum_threshold_price: data.minimum_threshold_price,
      is_fixed_cost: data.is_fixed_cost,
      is_minimum_fixed_cost: data.is_minimum_fixed_cost,
      description: data.description,
      hourly_rate: data.hourly_rate,
      kwh_rate: data.kwh_rate,
      fixed_price: data.fixed_price,
    },
  });

  const is_fixed_cost = form.watch("is_fixed_cost") as boolean;
  const is_minimum_fixed_cost = form.watch("is_minimum_fixed_cost") as boolean;

  const { isDirty } = useFormState({ control: form.control });

  useEffect(() => {
    if (!is_minimum_fixed_cost) {
      form.resetField("minimum_threshold_price");
    }
  }, [is_minimum_fixed_cost]);

  const handleSubmit = async (values: z.infer<typeof FormSchema>) => {
    if (!isDirty) {
      toast.error("Please edit the tariff plan before saving.");
      return;
    }

    const editTariffPlansResult = {
      ...values,
      id: data.id,
      hourly_rate: values.hourly_rate!,
      fixed_price: values.fixed_price!,
      kwh_rate: values.kwh_rate!,
      minimum_threshold_price: values.minimum_threshold_price!,
    };
    editTariffPlansMutation(editTariffPlansResult)
      .unwrap()
      .then((response) => {
        form.reset();
        toast.success(response.message);
        setIsModalOpen(false);
      })
      .catch((error) => {
        toast.error(error.data.error_message);
      });
  };

  return (
    <Dialog modal open={isModalOpen} onOpenChange={() => setIsModalOpen(true)}>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <DialogContent className="max-w-lg border-0 p-6 text-white">
        <DialogTitle>Edit tariff plan</DialogTitle>
        <Form {...form}>
          <form
            className="space-y-6"
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <>
                  <FormItem className="relative">
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Name"
                        className=""
                        defaultValue={data.name}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="absolute -bottom-4 right-0 text-right text-[10px]" />
                  </FormItem>{" "}
                </>
              )}
            />
            <Tabs
              defaultValue={data.tarrif_plan_type}
              className="w-full space-y-6 bg-transparent"
            >
              <TabsList className="flex bg-slate-700">
                <TabsTrigger
                  value="Time_Based"
                  disabled={data.tarrif_plan_type != "Time_Based"}
                  className="w-full"
                >
                  Time based pricing
                </TabsTrigger>
                <TabsTrigger
                  value="Energy_Based"
                  disabled={data.tarrif_plan_type != "Energy_Based"}
                  className="w-full"
                >
                  Kwh based pricing
                </TabsTrigger>
                <TabsTrigger
                  value="Fixed"
                  disabled={data.tarrif_plan_type != "Fixed"}
                  className="w-full"
                >
                  Fixed cost
                </TabsTrigger>
              </TabsList>
              <TabsContent value="Time_Based" className="space-y-3">
                <FormField
                  control={form.control}
                  name="hourly_rate"
                  render={({ field }) => (
                    <>
                      <FormItem className="">
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-3 top-2">
                              {globalCurrency}
                            </span>
                            <Input
                              type="number"
                              placeholder="Time rate per hour"
                              className="pl-12 pr-10"
                              defaultValue={data.hourly_rate}
                              {...field}
                            />
                            <span className="absolute right-3 top-3 text-xs font-bold">
                              hr
                            </span>
                          </div>
                        </FormControl>
                      </FormItem>{" "}
                      <FormMessage className="text-right text-[10px]" />
                    </>
                  )}
                />
                <FormField
                  control={form.control}
                  name="minimum_threshold_price"
                  render={({ field }) => (
                    <>
                      <FormItem className="">
                        <FormLabel>Min. Transaction threshold</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-3 top-2">
                              {globalCurrency}
                            </span>
                            <Input
                              type="number"
                              pattern="[0-9]*"
                              className="pl-12 pr-10"
                              defaultValue={data.hourly_rate}
                              value={Number(field.value)}
                              onChange={field.onChange}
                            />
                          </div>
                        </FormControl>
                      </FormItem>
                      <FormMessage className="text-right text-[10px]" />
                    </>
                  )}
                />
              </TabsContent>

              <TabsContent value="Energy_Based" className="space-y-3">
                <FormField
                  control={form.control}
                  name="kwh_rate"
                  render={({ field }) => (
                    <>
                      <FormItem className="">
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-3 top-2">
                              {globalCurrency}
                            </span>
                            <Input
                              type="number"
                              placeholder="Energy rate per kWh"
                              className="pl-12 pr-10"
                              defaultValue={data.kwh_rate}
                              {...field}
                            />
                            <span className="absolute right-3 top-3 text-xs font-bold">
                              kWh
                            </span>
                          </div>
                        </FormControl>
                      </FormItem>{" "}
                      <FormMessage className="text-right text-[10px]" />
                    </>
                  )}
                />
                <FormField
                  control={form.control}
                  name="minimum_threshold_price"
                  render={({ field }) => (
                    <>
                      <FormItem className="">
                        <FormLabel>Min. Transaction threshold</FormLabel>
                        <FormControl>
                          <div className="relative">
                            <span className="absolute left-3 top-2">
                              {globalCurrency}
                            </span>
                            <Input
                              type="number"
                              pattern="[0-9]*"
                              className="pl-12 pr-10"
                              defaultValue={data.hourly_rate}
                              {...field}
                            />
                          </div>
                        </FormControl>
                        <FormMessage className="absolute text-right text-[10px]" />
                      </FormItem>
                    </>
                  )}
                />
              </TabsContent>
              <TabsContent value="Fixed" className="space-y-3">
                <div className="relative">
                  <FormField
                    control={form.control}
                    name="is_fixed_cost"
                    render={({ field }) => (
                      <FormItem className="absolute right-1 top-2 z-10">
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            defaultChecked={data.is_fixed_cost}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="fixed_price"
                    disabled={!is_fixed_cost}
                    render={({ field }) => (
                      <>
                        <FormItem className="">
                          <FormControl>
                            <div className="relative">
                              <span className="absolute left-3 top-2">
                                {globalCurrency}
                              </span>
                              <Input
                                type="number"
                                placeholder="Fixed cost amount"
                                className="pl-12 pr-10"
                                defaultValue={data.fixed_price}
                                {...field}
                              />
                            </div>
                          </FormControl>
                        </FormItem>
                        <FormMessage className="text-right text-[10px]" />
                      </>
                    )}
                  />
                </div>
                <div className="relative">
                  <FormField
                    control={form.control}
                    name="is_minimum_fixed_cost"
                    render={({ field }) => (
                      <FormItem className="absolute right-1 top-10 z-10">
                        <FormControl>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            defaultChecked={data.is_minimum_fixed_cost}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="minimum_threshold_price"
                    disabled={!is_minimum_fixed_cost}
                    render={({ field }) => (
                      <div>
                        <FormItem className="">
                          <FormLabel>Min. Transaction threshold</FormLabel>
                          <FormControl>
                            <div className="relative">
                              <span className="absolute left-3 top-2">
                                {globalCurrency}
                              </span>
                              <Input
                                type="number"
                                pattern="[0-9]*"
                                className="pl-12 pr-10"
                                defaultValue={data.hourly_rate}
                                disabled={!is_minimum_fixed_cost}
                                {...field}
                              />
                            </div>
                          </FormControl>{" "}
                        </FormItem>
                        <FormMessage className="text-right text-[10px]" />
                      </div>
                    )}
                  />
                </div>
              </TabsContent>
            </Tabs>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <>
                  <FormItem className="">
                    <FormControl>
                      <Textarea
                        placeholder="Description"
                        className=" "
                        defaultValue={data.description}
                        {...field}
                      />
                    </FormControl>
                  </FormItem>{" "}
                  <FormMessage className="text-right text-[10px]" />
                </>
              )}
            />

            <DialogFooter>
              <Button
                type="submit"
                className="bg-orange-900 hover:bg-orange-900/50 active:bg-orange-900/20"
                disabled={editTariffPlansLoading || !isDirty}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  form.reset();
                  setIsModalOpen(false);
                }}
                variant="outline"
                type="button"
                disabled={editTariffPlansLoading}
              >
                Cancel
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
