import { Separator } from "@/components/ui/separator";
import type { CardHolderResponse } from "@/redux/features/access-control/access-control.api";
import { BsPersonFill } from "react-icons/bs";

type Props = { data: CardHolderResponse };

export const DetailsCard = ({ data }: Props) => {
  return (
    <div className="flex-1 transform space-y-4 rounded-lg bg-black/50 p-6 text-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between gap-3">
        <h2 className="flex items-center font-semibold">
          <BsPersonFill className="mr-4 text-2xl text-yellow-500" />
          Card Holder Details
        </h2>
      </div>
      <Separator className="h-[1px] bg-gray-500" />
      <div>
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-bold">ID:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data?.id}
              </span>
            </div>
          </li>

          <li className="flex items-start">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-bold">Name:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data?.name}
              </span>
            </div>
          </li>

          <li className="flex items-start">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-bold">Phone Number:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data?.phone_number}
              </span>
            </div>
          </li>

          <li className="flex items-start">
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-bold">Email:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data?.email}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
