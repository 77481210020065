import { BellIcon, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Button } from "./ui/button";

export function OrganisationNavbar() {
  const navigate = useNavigate();
  return (
    <div className="border-b-[.3px] border-slate-500 p-4">
      <div className="container">
        <div className="flex items-center justify-between gap-4">
          <img
            src="/images/organisation-navbar-1.png"
            height={20}
            width={170}
          />{" "}
          <div className="flex flex-[.15] items-center justify-between">
            <BellIcon color="#ccc" />
            <Button
              variant="destructive"
              className="flex gap-3"
              onClick={() => {
                navigate("/login");
              }}
            >
              Logout <LogOut size={16} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
