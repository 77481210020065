import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  loading: boolean;
  data: any;
  error: string | null;
}
const initialState: InitialState = {
  loading: false,
  data: {},
  error: null,
};

const tariffSlice = createSlice({
  name: "tariff",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setTariffPlans: (state, action) => {
      state.data.tariffPlans = action.payload;
      state.loading = false;
    },
  },
});

export const { startLoading, setTariffPlans } = tariffSlice.actions;

export default tariffSlice.reducer;
