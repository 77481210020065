import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Cross2Icon } from "@radix-ui/react-icons";
import type { Table } from "@tanstack/react-table";
import { Filter } from "./filter";
import { accessLevel, rolesName } from "./data";

interface RolesToolbarProperties<TData> {
  table: Table<TData>;
}

export function RolesToolbar<TData>({ table }: RolesToolbarProperties<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Search by roles..."
          value={
            ((table.getColumn("name")?.getFilterValue() as string) || "") ?? ""
          }
          onChange={(event) => {
            table.getColumn("name")?.setFilterValue(event.target.value);
          }}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("roleName") && (
          <Filter
            column={table.getColumn("roleName")}
            title="Roles"
            options={rolesName}
          />
        )}
        {table.getColumn("accessLevel") && (
          <Filter
            column={table.getColumn("accessLevel")}
            title="Access Level"
            options={accessLevel}
          />
        )}

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
