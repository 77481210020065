import { chargerGroupsOptions } from "@/common/variables/charts";
import { BarChart } from "@/components/ui/bar-chart";
import { Skeleton } from "@/components/ui/skeleton";
import { startLoading } from "@/redux/features/auth/auth.slice";
import { useGetChargerGroupContributionsDailyQuery } from "@/redux/features/revenue/revenue.api";
import { setContributions } from "@/redux/features/revenue/revenue.slice";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useEffect } from "react";

const ChargerGroupContributionsCard = () => {
  const { orgId } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const {
    data: { contributions },
    loading,
  } = useAppSelector((state) => state.revenue);
  const { data, isFetching, isError } =
    useGetChargerGroupContributionsDailyQuery(orgId);

  useEffect(() => {
    if (isFetching) {
      dispatch(startLoading());
    } else if (data) {
      dispatch(
        setContributions({
          name: "Average Weekly Sessions",
          data: data?.data?.daily_revenue_contribution.map(
            (item: { total_revenue: number; charger_group_name: string }) => ({
              y: String(item.total_revenue),
              x: item.charger_group_name,
            }),
          ),
        }),
      );
    }
  }, [dispatch, data, isFetching]);

  return (
    <div className="relative flex h-full w-full flex-col items-center gap-7 rounded-xl bg-gradient-linear p-6 backdrop-blur-xl">
      {loading ? (
        <div className="flex w-full flex-col items-start gap-3">
          <div className="flex w-full items-center justify-between space-y-2 font-extralight text-[#D1D1D1]">
            <Skeleton className="h-5 w-[160px]" />
          </div>
          <div className="w-full">
            <Skeleton className="h-[360px] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        <>
          <div className="w-full font-extralight text-[#D1D1D1]">
            Charger Group Contributions
          </div>
          <div className="flex w-full flex-1 flex-col 2xl:flex-row">
            <div className="relative min-h-[300px] w-full flex-1 md:min-w-[300px]">
              {contributions && (
                <BarChart
                  chartData={[contributions]}
                  chartOptions={chargerGroupsOptions}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChargerGroupContributionsCard;
