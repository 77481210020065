import { Checkbox } from "@/components/ui/checkbox";
import { useGetInvitationsQuery } from "@/redux/features/org/org.api";
import { useAppSelector } from "@/redux/hooks";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Info } from "lucide-react";
import { useState } from "react";

import { PendingMemberCard } from "./pending-member-card";

export const PendingInvitations = () => {
  const [page, setPage] = useState(1);
  const { orgId } = useAppSelector((state) => state.auth);

  const invitesQuery = useGetInvitationsQuery(
    {
      orgId: orgId!,
      page: page,
    },
    { refetchOnMountOrArgChange: true },
  );
  return (
    <>
      {invitesQuery.isLoading && (
        <div className="flex h-64 items-center justify-center rounded-md border border-gray-600 bg-primary p-4">
          <div className="space-y-1">
            <h2 className="text-center font-bold">Loading...</h2>
          </div>
        </div>
      )}
      {invitesQuery.isSuccess &&
        (invitesQuery.data.data.results.length > 0 ? (
          <div className="overflow-hidden rounded-lg border-[1px] border-gray-600 text-white">
            <div className="flex items-center justify-between border-b-[1px] border-gray-600 p-4">
              <div className="flex items-center gap-3">
                <Checkbox className="border-white" />
                <p className="text-sm font-light text-gray-300">Select all</p>
              </div>
              <div>
                <DotsHorizontalIcon />
              </div>
            </div>
            <div className="max-h-[200px] overflow-y-scroll">
              {invitesQuery.data.data.results.map((item: any) => (
                <PendingMemberCard key={item.id} data={item} />
              ))}
            </div>
            <div className="mt-3 flex items-center gap-2 pb-3 pl-3 text-sm font-light italic text-gray-400">
              <Info size={16} />
              Invitations Expire afer 7 days of sending them out
            </div>
          </div>
        ) : (
          <div className="flex h-64 items-center justify-center rounded-md border border-gray-600 bg-primary p-4">
            <div className="space-y-1">
              <h2 className="text-center font-bold">
                No Pending Invitations Found
              </h2>
              <p className="text-center font-light text-gray-500">
                Use the form above to invite a Team Member.
              </p>
            </div>
          </div>
        ))}
      {invitesQuery.isError && (
        <div className="flex h-64 items-center justify-center rounded-md border border-gray-600 bg-primary p-4">
          <div className="space-y-1">
            <h2 className="text-center font-bold text-red-400">
              Error fetching invites
            </h2>
          </div>
        </div>
      )}
    </>
  );
};
