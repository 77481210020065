import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import { BsFillClockFill, BsLightning, BsPerson, BsPlug } from "react-icons/bs";

interface Properties {
  data: {
    charger_group_name: string;
    charger_id: string;
    charger_name: string;
    firmware_version: string;
    protocol: string;
    vendor_model: string;
  };
}

vendor_model: "evse";

export const ChargerDetails = ({ data }: Properties) => {
  return (
    <div className="h-full transform space-y-4 rounded-lg bg-black/50 p-6 text-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between gap-3">
        <h2 className="flex items-center font-semibold">
          <BsFillClockFill className="mr-4 text-yellow-500" />
          Charger Usage Details
        </h2>
        <Button className="rounded-b-none border-b bg-transparent" size="sm">
          Show additinal details <ArrowTopRightIcon className="ml-2" />
        </Button>
      </div>
      <Separator className="h-[1px] bg-gray-500" />
      <div className="">
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-bold">Charger ID:</span>{" "}
              <span className="text-xs uppercase text-zinc-400">
                {data.charger_id}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPerson className="mr-4 text-green-500" />
            <div>
              <span className="text-sm font-semibold">Group Name:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.charger_group_name}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">Protocol:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.protocol}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsLightning className="mr-4 text-yellow-500" />
            <div>
              <span className="text-sm font-semibold">Vendor/Model:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.vendor_model} kW/h
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsLightning className="mr-4 text-yellow-500" />
            <div>
              <span className="text-sm font-semibold">Firmware version:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.firmware_version} kW/h
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
