// Todo: This compoennt needs serious work to be done
// Considerations:
// 1. **Critical:** Replace the simulated data fetching with a real API call for accurate metrics.
// 2. **Important:** Break down the component into smaller reusable components for better maintainability.
// 3. **Enhancement:** Improve error handling by distinguishing between network errors and other unexpected errors.

import { DeltaType } from "@/common/constants/enums";
import { Button } from "@/components/ui/button";
import DeltaBadge from "@/components/ui/delta-badge";
import { faker } from "@faker-js/faker";
import { MoveUpRight } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

interface Properties {
  title: string;
  metric: number;
  onClick?: (() => undefined) | undefined;
  hasModal?: boolean;
}

export const DetailsCard: React.FC<Properties> = ({
  title,
  metric,
  onClick,
  hasModal,
}) => {
  const [totalDrivers, setTotalDrivers] = useState<number | null>(metric);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTotalDrivers = useCallback(async () => {
    setLoading(true);

    try {
      const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));
      await delay(1000);
      setTotalDrivers(metric);
      setError(null);
    } catch {
      setTotalDrivers(null);
      setError("Error fetching total drivers");
    } finally {
      setLoading(false);
    }
  }, [metric]);

  useEffect(() => {
    fetchTotalDrivers();
  }, [fetchTotalDrivers]);

  return (
    <Button
      className={`relative flex w-full flex-1 flex-col gap-3 rounded-lg bg-gradient-linear p-4 shadow-2xl backdrop-blur-xl ${
        hasModal && "cursor-pointer duration-150 hover:scale-y-105"
      }`}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <h2 className="font-extralight text-[#D1D1D1]">{title}</h2>
        {hasModal && <MoveUpRight />}
      </div>
      {loading ? (
        <div className="flex items-center text-2xl text-white">Loading...</div>
      ) : error ? (
        <p className="mb-8 text-red-500">{error}</p>
      ) : (
        <>
          <p className="text-2xl font-bold text-white">{totalDrivers}</p>
        </>
      )}
      <DeltaBadge
        type={faker.helpers.arrayElement([
          DeltaType.ModerateIncrease,
          DeltaType.Increase,
          DeltaType.Decrease,
        ])}
        value={
          totalDrivers !== null ? totalDrivers - Math.floor(Math.random()) : 0
        }
      />
    </Button>
  );
};
