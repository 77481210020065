import { Navbar } from "@/components/navbar";
import { useAppSelector } from "@/redux/hooks";

import { SalesChart } from "../Home/components/sales-chart";
import { TotalRevenueCard } from "../Home/components/total-revenue-card";
import ChargerGroupContributionsCard from "./components/charger-group-contributions-card";
import SettlementCycle from "./components/settlment-cycle";
import TransactionsTable from "./components/transaction-table/transactions-table";

function Revenue() {
  const { totalRevenue, loading } = useAppSelector((state) => state.home);

  return (
    <div className="space-y-5">
      <Navbar title="Revenue Insight" />

      <div className=" relative mx-auto flex flex-col gap-9">
        <div className="flex flex-col gap-5 xl:flex-row">
          <div className="flex flex-col space-y-4">
            {totalRevenue && (
              <div className="flex h-max flex-col gap-5 xl:flex-row">
                <TotalRevenueCard data={totalRevenue} loading={loading} />
              </div>
            )}
            <div className="flex h-full flex-col gap-5 xl:flex-row">
              <SettlementCycle />
            </div>
          </div>
          <div className="flex-1">
            <ChargerGroupContributionsCard />
          </div>
        </div>
        <div>
          <SalesChart />
        </div>
        <div>
          <TransactionsTable />
        </div>
      </div>
    </div>
  );
}

export default Revenue;
