import { AddressAutoComplete } from "@/components/address-auto-complete";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Textarea } from "@/components/ui/textarea";

import {
  useAddChargeGroupMutation,
  useEditChargeGroupMutation,
} from "@/redux/features/charger-network/charger-network.api";
import { useAppSelector } from "@/redux/hooks";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useCallback, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import type { Group } from "./schema";

const FormSchema = z.object({
  name: z
    .string({ required_error: "Group name cannot be empty" })
    .min(3, { message: "Group name is too short" })
    .max(50, { message: "Group name is too long" }),
  description: z
    .string()
    .min(3, { message: "Description is too short" })
    .max(50, { message: "Description is too long" })
    .optional()
    .nullable(),
  location_description: z
    .string()
    .min(3, { message: "Location Description is too short" })
    .max(50, { message: "Location Description is too long" })
    .optional()
    .nullable(),
  latitude: z.number(),
  longitude: z.number(),
  address: z
    .string()
    .max(250, { message: "Address length is too long." })
    .min(2, { message: "Please enter address." }),
});

export const CreateChargerGroup = ({
  data,
  onClose,
}: {
  data?: Group;
  onClose?: (() => void) | undefined;
}) => {
  const { orgId } = useAppSelector((state) => state.auth);

  const [createChargeGroupMutation, { isLoading: createChargeGroupLoading }] =
    useAddChargeGroupMutation();
  const [editChargeGroupMutation, { isLoading: editChargeGroupLoading }] =
    useEditChargeGroupMutation();

  const [isOpen, setIsOpen] = useState(!!data);

  const defaultValues = data
    ? {
        name: data?.name ?? "",
        description: data?.description ?? "",
        location_description: data?.location_description ?? "",
        latitude: Number(data?.latitude ?? 0),
        longitude: Number(data?.longitude ?? 0),
        address: data?.address ?? "",
      }
    : {
        name: "",
        description: "",
        location_description: "",
        latitude: 0,
        longitude: 0,
        address: "",
      };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: defaultValues,
  });
  const { isDirty } = useFormState({ control: form.control });

  const handleSubmit = useCallback(
    async (values: z.infer<typeof FormSchema>) => {
      const result = { ...values, organization: orgId };
      if (data) {
        if (isDirty) {
          await editChargeGroupMutation({ ...result, id: data?.id })
            .unwrap()
            .then((response) => {
              form.reset();
              toast.success(response.message);
              setIsOpen(false);
            })
            .catch((error) => {
              toast.error(error?.data?.error_message);
            });
        } else {
          toast.error("Please edit charger group before saving.");
          return;
        }
      } else {
        await createChargeGroupMutation(result)
          .unwrap()
          .then((response) => {
            toast.success(response.message);
            setIsOpen(false);
          })
          .catch((error) => {
            toast.error(error.data.error_message);
          });
      }
    },
    [
      createChargeGroupMutation,
      editChargeGroupMutation,
      orgId,
      data,
      isDirty,
      form,
    ]
  );

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(open) => {
        form.reset();
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <SheetTrigger asChild>
        <Button
          variant="outline"
          onClick={() => {
            form.reset();
            setIsOpen(!isOpen);
          }}
          className="bg-gray-800 whitespace-nowrap text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white "
        >
          <Plus className="mr-2 h-4 w-4 shrink-0" />
          Create New Charger Group
        </Button>
      </SheetTrigger>
      <SheetContent className="max-h[50vh] overflow-scroll">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4"
          >
            <SheetHeader>
              <SheetTitle> Add Charger groups</SheetTitle>
              <SheetDescription>
                Add charger groups for your charge points here.
              </SheetDescription>
            </SheetHeader>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="bg-gray-800 border-gray-700 text-gray-100"
                        placeholder="Name"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                  <FormMessage className=" text-[10px]" />
                </>
              )}
            />

            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Address</FormLabel>
                  <FormControl>
                    <div>
                      <AddressAutoComplete
                        onSelectAddress={(address, latitude, longitude) => {
                          form.setValue("address", address);
                          form.setValue("latitude", latitude as number);
                          form.setValue("longitude", longitude as number);
                        }}
                        defaultValue=""
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage className="text-[10px]" />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Description"
                        className="bg-gray-800 border-gray-700 text-gray-100 resize-none"
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                  </FormItem>
                  <FormMessage className="text-right text-[10px]" />
                </>
              )}
            />

            <FormField
              control={form.control}
              name="location_description"
              render={({ field }) => (
                <>
                  <FormItem>
                    <FormLabel>Location Description</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Add a description to describe the location."
                        className="bg-gray-800 border-gray-700 text-gray-100 resize-none"
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                  </FormItem>{" "}
                  <FormMessage className="text-right text-[10px]" />
                </>
              )}
            />

            <SheetFooter>
              <SheetClose asChild>
                <Button
                  onClick={() => form.reset()}
                  variant="outline"
                  type="button"
                >
                  Cancel
                </Button>
              </SheetClose>
              <Button
                disabled={createChargeGroupLoading || editChargeGroupLoading}
                variant="secondary"
                type="submit"
              >
                Create
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
};
