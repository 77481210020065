import { Separator } from "@/components/ui/separator";
import { BsFillClockFill, BsPerson, BsPlug } from "react-icons/bs";

interface Properties {
  data: {
    contact_information: string;
    driver_id: string;
    driver_name: string;
    vehicle_description: string;
  };
}

export const DriverDetails = ({ data }: Properties) => {
  return (
    <div className="h-full transform space-y-4 rounded-lg bg-black/50 p-6 text-white shadow-lg transition duration-300 ease-in-out">
      <div className="flex items-center justify-between">
        <h2 className="flex items-center text-lg font-semibold">
          <BsFillClockFill className="mr-4 text-yellow-500" />
          Vehicle Driver Details
        </h2>
      </div>
      <Separator className="h-[1px] bg-gray-500" />
      <div className="">
        <ul className="flex-1 space-y-3">
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-bold">Driver Name:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.driver_name}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPerson className="mr-4 text-green-500" />
            <div>
              <span className="text-sm font-semibold">Driver ID:</span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.driver_id}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">
                Contact Information:
              </span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.contact_information}
              </span>
            </div>
          </li>
          <li className="flex items-start">
            <BsPlug className="mr-4 text-blue-500" />
            <div>
              <span className="text-sm font-semibold">
                Vehicle Description:
              </span>{" "}
              <span className="text-xs capitalize text-zinc-400">
                {data.vehicle_description}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

// Driver Details Section:
// Display information based on driver type.
// For public users, display anonymised information such as Driver ID.
// For fleet members, provide more detailed information like Driver ID,
// Name, Contact Information, and Vehicle Details.
