import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import type { AccessProfileResponse } from "@/redux/features/access-control/access-control.api";
import { Building, Clock, Eye, FileText, Group, User, Zap } from "lucide-react";
import { useState } from "react";

export const ViewMoreAccessProfile = ({
  data,
  onClose,
}: {
  data: AccessProfileResponse;
  onClose?: (() => void) | undefined;
}) => {
  const [isOpen, setIsOpen] = useState(!!data);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    });
  };

  const renderCardContent = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    items: { label: string; value?: string; icon?: any }[]
  ) =>
    items.map(({ label, value, icon: Icon }) => (
      <div className="grid grid-cols-3" key={label}>
        <Badge
          variant="outline"
          className=" justify-center whitespace-nowrap px-2 col-span-1 w-max"
        >
          {label}
        </Badge>
        <span className="text-sm flex items-center gap-2 col-span-2">
          {Icon && <Icon className="w-4 h-4 text-muted-foreground" />}
          {value}
        </span>
      </div>
    ));

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open && onClose) {
          onClose();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700 hover:text-white"
        >
          <Eye className="mr-2 h-4 w-4" />
          View More
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px] p-4 h-max">
        <DialogHeader>
          <DialogTitle className="text-2xl">{data.name}</DialogTitle>
          <DialogDescription>
            Detailed information for access profile
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="general" className="w-full ">
          <TabsList className="grid w-full grid-cols-3 bg-slate-700">
            <TabsTrigger value="general">General</TabsTrigger>
            <TabsTrigger value="access">Access</TabsTrigger>
            <TabsTrigger value="chargers">Chargers</TabsTrigger>
          </TabsList>
          <ScrollArea className="w-full mt-4 pr-4">
            <TabsContent value="general">
              <Card className="px-0 bg-background border-0 text-white">
                <CardHeader className="px-0">
                  <CardTitle>General Information</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-4 px-0">
                  {renderCardContent([
                    { label: "ID", value: data.id },
                    {
                      label: "Created",
                      value: formatDate(data.created_at),
                      icon: Clock,
                    },
                    {
                      label: "Updated",
                      value: formatDate(data.updated_at),
                      icon: Clock,
                    },
                    { label: "Created By", value: data.created_by, icon: User },
                    {
                      label: "Description",
                      value: data.description as string,
                      icon: FileText,
                    },
                  ])}
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="access">
              <Card className="px-0 bg-background border-0 text-white">
                <CardHeader className="px-0">
                  <CardTitle>Access Information</CardTitle>
                </CardHeader>
                <CardContent className="grid gap-4 px-0">
                  {renderCardContent([
                    {
                      label: "Organization",
                      value: data.access_to_organization,
                      icon: Building,
                    },
                    {
                      label: "Restriction Limit",
                      value: data.restriction_limit_id,
                      icon: Zap,
                    },
                    {
                      label: "Organization ID",
                      value: data.organization_id,
                      icon: Building,
                    },
                  ])}
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="chargers">
              <Card className="px-0 bg-background border-0 text-white">
                <CardHeader>
                  <CardTitle>Charger Access</CardTitle>
                </CardHeader>
                <CardContent>
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="charger-groups">
                      <AccordionTrigger>
                        <div className="flex items-center gap-2">
                          <Group className="w-4 h-4" />
                          Access to Charger Groups
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-2">
                          {Object.entries(data.access_to_charger_groups).map(
                            ([key, value]) => (
                              <div
                                key={key}
                                className="flex items-center justify-between text-sm"
                              >
                                <span>{key}</span>
                                <Badge variant="secondary">{value}</Badge>
                              </div>
                            )
                          )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="individual-chargers">
                      <AccordionTrigger>
                        <div className="flex items-center gap-2">
                          <Zap className="w-4 h-4" />
                          Access to Individual Chargers
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="grid gap-2">
                          {Object.entries(data.access_to_chargers).map(
                            ([key, value]) => (
                              <div
                                key={key}
                                className="flex items-center justify-between text-sm"
                              >
                                <span>{key}</span>
                                <Badge variant="secondary">{value}</Badge>
                              </div>
                            )
                          )}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </CardContent>
              </Card>
            </TabsContent>
          </ScrollArea>
        </Tabs>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="button"
              variant="outline"
              className="w-full bg-gray-800 hover:bg-gray-700 text-gray-200"
            >
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
