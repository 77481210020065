interface CookieOptions {
  expires?: number; // Expires in days
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
}

const CookieService = {
  setCookie(name: string, value: string, options?: CookieOptions): void {
    let cookieString = `${name}=${value}`;

    if (options) {
      if (options.expires) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + options.expires);
        cookieString += `;expires=${expirationDate.toUTCString()}`;
      }

      if (options.path) {
        cookieString += `;path=${options.path}`;
      }

      if (options.domain) {
        cookieString += `;domain=${options.domain}`;
      }

      if (options.secure) {
        cookieString += ";secure";
      }

      if (options.httpOnly) {
        cookieString += ";HttpOnly";
      }
    }

    document.cookie = cookieString;
  },

  getCookie(name: string): string | null {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(`${name}=`));

    return cookie ? cookie.split("=")[1] : null;
  },

  removeCookie(name: string, options?: CookieOptions): void {
    const cookieOptions: CookieOptions = { expires: -10, ...options };
    this.setCookie(name, "", cookieOptions);
  },
};

export default CookieService;
