import { menuOrgLinks } from "@/common/links/admin";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils/cn";
import { Settings2 } from "lucide-react";
import { NavLink, useLocation } from "react-router-dom";

interface SidebarProperties extends React.HTMLAttributes<HTMLDivElement> {
  dataState: string;
  setDataState: React.Dispatch<React.SetStateAction<string>>;
}

export const Sidebar: React.FC<SidebarProperties> = ({
  className,
  dataState,
  setDataState,
}) => {


  const location = useLocation();

  return (
    <>
      <aside
        data-state={dataState}
        className="fixed inset-y-0 left-0 z-10 hidden flex-col border-r border-[#323232] bg-[#111219] bg-background transition-all data-[state=close]:w-14 data-[state=open]:w-[260px] data-[state=close]:animate-sidebar-close data-[state=open]:animate-sidebar-open sm:flex"
      >
        <div className="px-2 py-4">
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                data-state={dataState}
                className="mt-auto flex items-center rounded-full transition-all hover:text-foreground data-[state=open]:ml-4 data-[state=close]:h-10 data-[state=close]:w-10 data-[state=close]:justify-center data-[state=open]:gap-3 data-[state=close]:bg-white data-[state=open]:px-4 data-[state=open]:py-2"
                aria-label="Pocket Lawyers"
                onClick={() =>
                  setDataState(() => (dataState === "open" ? "close" : "open"))
                }
              >
                <img
                  data-state={dataState}
                  className="data-[state=open]:h-[44px] data-[state=open]:w-[44px]"
                  src="/images/logo.png"
                  alt="logo"
                  height={28}
                  width={22}
                />

                <span
                  data-state={dataState}
                  className="whitespace-nowrap text-3xl font-light data-[state=close]:invisible data-[state=close]:!w-0"
                >
                  GridFlow
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent
              data-state={dataState}
              side="right"
              sideOffset={5}
              className="data-[state=open]:hidden"
            >
              <span className="font-medium text-[#FED127]">Grid</span>
              <span className="font-medium text-[#FD9A27]">Flow</span>
            </TooltipContent>
          </Tooltip>
        </div>
        <nav className="flex h-full flex-col gap-4 px-2 py-4">
          {menuOrgLinks.map(({ name, path, icon: Icon }, index) => (
            <Tooltip key={index}>
              <TooltipTrigger asChild>
                <NavLink
                  to={path}
                  data-state={dataState}
                  className={cn(
                    "flex items-center text-[#898989] transition-all hover:bg-gradient-side-bar hover:text-foreground data-[state=open]:ml-4 data-[state=close]:h-9 data-[state=close]:w-9 data-[state=close]:justify-center data-[state=open]:gap-3 data-[state=close]:rounded-lg data-[state=open]:rounded-sm data-[state=open]:px-6 data-[state=open]:py-4 data-[state=close]:md:h-8 data-[state=close]:md:w-8 [&>span]:hover:text-black [&>svg]:hover:text-black",
                    location.pathname === path
                      ? "bg-gradient-to-r from-yellow-500 via-yellow-400 to-orange-500"
                      : "hover:bg-black/50 hover:text-white/60",
                  )}
                >
                  <Icon size="20" className="h-5 w-5" />
                  <span
                    data-state={dataState}
                    className="whitespace-nowrap text-sm font-medium data-[state=close]:invisible data-[state=close]:!w-0"
                  >
                    {name}
                  </span>
                </NavLink>
              </TooltipTrigger>
              <TooltipContent
                data-state={dataState}
                side="right"
                sideOffset={5}
                className="data-[state=open]:hidden"
              >
                {name}
              </TooltipContent>
            </Tooltip>
          ))}
          <Tooltip>
            <TooltipTrigger asChild>
              <NavLink
                to={`/settings`}
                data-state={dataState}
                className={cn(
                  "mt-auto flex items-center text-[#898989] transition-all hover:bg-gradient-side-bar hover:text-foreground data-[state=open]:ml-4 data-[state=close]:h-9 data-[state=close]:w-9 data-[state=close]:justify-center data-[state=open]:gap-3 data-[state=close]:rounded-lg data-[state=open]:rounded-sm data-[state=open]:px-6 data-[state=open]:py-4 data-[state=close]:md:h-8 data-[state=close]:md:w-8 [&>span]:hover:text-black [&>svg]:hover:text-black",
                  location.pathname == "/settings" &&
                    "bg-gradient-to-r from-yellow-500 via-yellow-400 to-orange-500",
                )}
              >
                <Settings2 size="20" />
                <span
                  data-state={dataState}
                  className="text-sm font-medium data-[state=close]:invisible data-[state=close]:!w-0"
                >
                  Settings
                </span>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent
              data-state={dataState}
              side="right"
              sideOffset={3}
              className="data-[state=open]:hidden"
            >
              Settings
            </TooltipContent>
          </Tooltip>
        </nav>
      </aside>
    </>
  );
};
